


























import { PreviewDescriptionConfig } from '@/models/resource-details/preview-config';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ResourceDescriptionPreview extends Vue {
  @Prop() public readonly config!: PreviewDescriptionConfig;
  @Prop() public readonly isActive?: boolean;

  public onClick(): void {
    this.$emit('click');
  }

  public get formattedDescription(): string {
    return this.config.text.replace(/\r\n|\r|\n/g, '<br>');
  }
}
