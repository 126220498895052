








































import { Component, Vue, Prop } from 'vue-property-decorator';
import { InvoicePosition } from '../../models/invoice-details-view';

@Component({})
export default class InvoicePositionsGrid extends Vue {
  @Prop() public readonly model?: InvoicePosition[];
  @Prop() public readonly currency?: string;
  @Prop() public readonly itemIndex?: number;
}
