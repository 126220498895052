import { AuthenticationService } from '@/services/authentication/authentication.service';
import { CachedResourcesService } from '@/services/cached-resources.service';
import { LocalStorageCacheService } from '@/services/local-storage-cache.service';
import { OnboardingStatusService } from '@/services/onboarding-status.service';
import { tap } from 'rxjs/operators';

export default function onboardingStatusGuard({
  next,
  router,
}: {
  next: any;
  router: any;
}) {
  const onboardingService = new OnboardingStatusService(
    new AuthenticationService(
      new CachedResourcesService(new LocalStorageCacheService())
    ),
    new LocalStorageCacheService()
  );

  onboardingService
    .getOnboardingStatus()
    .pipe(
      tap((status) => {
        if (status.isCompleted) {
          if (router.currentRoute.name.indexOf('step') > -1) {
            router.push({ path: '/' });
            return;
          }
          return next();
        }
        if (router.currentRoute.name.indexOf('step') > -1) {
          const currentIndex = router.currentRoute.name.substr(
            router.currentRoute.name.length - 1,
            1
          );

          if (
            currentIndex <= status.stepIndex ||
            currentIndex - status.stepIndex === 1
          ) {
            return next();
          } else {
            router.push(`/step${status.stepIndex}`);
            return;
          }
        }
        router.push(`/step${status.stepIndex}`);
        return;
      })
    )
    .subscribe();
}
