export enum PaymentProcessingNetwork {
  Amex = 'amex',
  CartesBancaires = 'cartes_bancaires',
  Diners = 'diners',
  Discover = 'discover',
  Jcb = 'jcb',
  MasterCard = 'mastercard',
  Visa = 'visa',
  Unionpay = 'unionpay',
}

export type PaymentProcessingNetworkType =
  | PaymentProcessingNetwork.Amex
  | PaymentProcessingNetwork.CartesBancaires
  | PaymentProcessingNetwork.Diners
  | PaymentProcessingNetwork.Discover
  | PaymentProcessingNetwork.Jcb
  | PaymentProcessingNetwork.MasterCard
  | PaymentProcessingNetwork.Visa
  | PaymentProcessingNetwork.Unionpay;
