































































import { Component, Vue } from 'vue-property-decorator';
import { tap } from 'rxjs/operators';
import { InvoicesService } from '@/services/invoices.service';
import { InvoiceDetailsView } from '@/models/invoice-details-view';
import { InjectArguments } from 'good-injector-vue';
import { Route } from 'vue-router';

@Component({
  components: {}
})
export default class InvoiceDetails extends Vue {
  public invoice: InvoiceDetailsView = {} as InvoiceDetailsView;
  public breadcrumbPaths = [{ name: 'Invoices and payments', path: '/dashboard/invoices' }];
  public backButtonPath = '';
  public isLoading = false;

  private currentPageNumber = '';
  private readonly $route!: Route;

  @InjectArguments()
  public mounted(invoicesService: InvoicesService): void {
    const invoiceId: number = parseInt(this.$route.params.id);
    if (this.$route.params.currentPageNumber) {
      this.currentPageNumber = this.$route.params.currentPageNumber;
    }
    this.isLoading = true;

    invoicesService
      .getInvoiceById(invoiceId)
      .pipe(
        tap(invoice => {
          this.invoice = invoice;
          this.breadcrumbPaths[0].path += `/${this.currentPageNumber}`;
          this.breadcrumbPaths.push({ name: `Invoice ${invoice.invoiceId}`, path: '' });
          this.backButtonPath = this.breadcrumbPaths[0].path;
          this.isLoading = false;
        })
      )
      .subscribe();
  }

  public onInvoiceDownload(): void {
    console.log('download invoice!');
  }
}
