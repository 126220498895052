








import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class LoginButton extends Vue {
  @Prop(String) public readonly label!: string;
  @Prop(String) public readonly id!: string;
  @Prop(Boolean) public readonly enabled?: boolean;

  public onClick() {
    if (this.enabled) {
      this.$emit('click');
    }
  }
}
