












import { Component, Vue, VModel } from 'vue-property-decorator';

import { CountryModel } from '@/models/country';
import { CountryService } from '@/services/country.service';

@Component({
  components: {}
})
export default class CountryPicker extends Vue {
  @VModel() public readonly model!: string;

  public countries: CountryModel[] = [];

  public mounted(): void {
    this.countries = CountryService.getCountries();
  }

  public onChange(event: any): void {
    this.$emit('input', event.target.value);
  }
}
