import { CountryModel } from '@/models/country';
import Countries from './country.service.data.json';

export class CountryService {
  static getCountries(): CountryModel[] {
    return Countries;
  }

  static getCountryByIsoCode(isoCode: string): string {
    const country = Countries.find((country) => country.isoCode === isoCode);
    return country ? country.label : isoCode;
  }

  static getCountryIsoCodeByCountryName(countryName: string): string | null {
    const country = Countries.find((country) => country.label === countryName);
    return country ? country.isoCode : null;
  }
}
