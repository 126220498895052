






































































import { Observable, Observer, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class ResourcePhotoUploadModal extends Vue {
  @Prop(Boolean) public readonly visible!: boolean;
  @Prop() public readonly isFileCleared$!: Subject<void>;

  public isFileUploaded = false;
  public isDraggingFile = false;
  public dropError = String();
  public fileSize?: string;
  public fileName?: string;

  private file?: File;

  public $refs!: {
    fileInput: HTMLInputElement;
    dropArea: HTMLDivElement;
  };

  public mounted(): void {
    this.prepareDropArea();
    this.watchIsFileCleared();
  }

  private watchIsFileCleared(): void {
    if (this.isFileCleared$) {
      this.isFileCleared$.subscribe(() => {
        this.isFileUploaded = false;
        delete this.file;
        delete this.fileSize;
        delete this.fileName;
      });
    }
  }

  private onFileChanged() {
    if (this.file) {
      const kbSize = this.file ? this.file.size / 1024 : 0;
      this.fileSize = kbSize.toFixed(2);
      this.fileName = this.file.name;
    }
  }

  public onFilesDropped($event: DragEvent): void {
    const dt = $event.dataTransfer;

    if (!!dt && this.validateFiles(dt.files)) {
      this.processFile(dt.files[0]);
    }
  }

  public onFileBrowseClicked(): void {
    (document.getElementById('fileInput') as HTMLInputElement).value = '';
    this.$refs.fileInput.click();
  }

  public onFileUploaded($event: any): void {
    if (!!$event.target.files && this.validateFiles($event.target.files)) {
      this.processFile($event.target.files[0]);
    }
  }

  public onCancel(): void {
    this.$emit('cancel');
  }

  public emitAndClose(): void {
    this.getBase64()
      .pipe(
        tap((base64Image) => {
          this.$emit('uploaded', base64Image);
        })
      )
      .subscribe();
  }

  public get acceptTypes(): string {
    return process.env.VUE_APP_RESOURCE_IMAGE_ALLOWED_MIME_TYPES;
  }

  private processFile(file: File): void {
    this.file = file;
    this.onFileChanged();
    this.isFileUploaded = true;
  }

  public validateFiles(files: File[] | FileList): boolean {
    if (!files || files.length === 0) {
      this.dispatchUploadError('No files found!');
      return false;
    }

    if (files.length > 1) {
      this.dispatchUploadError('Only one image can be uploaded!');
      return false;
    }

    const file = files[0];
    const allowedExtensions = process.env.VUE_APP_RESOURCE_IMAGE_ALLOWED_EXTENSIONS;
    const sizeLimit = process.env.VUE_APP_RESOURCE_IMAGE_SIZE_LIMIT;
    const regex = new RegExp('\.(' + allowedExtensions.replaceAll(',', '|') + ')$');
    if (!file.name.toLowerCase().match(regex)) {
      this.dispatchUploadError(`Wrong image type - allowed types: ${allowedExtensions}`);
      return false;
    }

    if (file.size > sizeLimit) {
      this.dispatchUploadError(`File is too large. Max file size: ${(sizeLimit / (1024 * 1024))} mb.`);
      return false;
    }

    return true;
  }

  private getBase64(): Observable<string> {
    if (!this.file) {
      return of(String());
    }

    const reader = new FileReader();

    const result = new Observable((observer: Observer<any>) => {
      reader.onloadend = () => {
        observer.next(reader.result);
        observer.complete();
      };
    });

    reader.readAsDataURL(this.file);

    return result;
  }

  private prepareDropArea(): void {
    const allEvents = ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'];
    const preventFcn = (e: any) => { e.preventDefault(); e.stopPropagation(); };

    allEvents.forEach(eventName => this.$refs.dropArea.addEventListener(eventName, preventFcn));
    ['dragover', 'dragenter'].forEach(eventName => this.$refs.dropArea.addEventListener(eventName, () => this.isDraggingFile = true));
    ['dragleave', 'dragend', 'drop'].forEach(eventName => this.$refs.dropArea.addEventListener(eventName, () => this.isDraggingFile = false));
  }

  private dispatchUploadError(message: string): void {
    setTimeout(() => this.dropError = String(), 2000);
    this.dropError = message;
  }
}
