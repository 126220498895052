import { SimpleModeFeature } from '@/settings/feature-switch.settings';
import { FeatureSwitchSettings } from '@/settings/feature-switch.settings';

export default function simpleModeGuard({
  next,
  router,
}: {
  next: any;
  router: any;
}) {
  const featureSwitchSettings = new FeatureSwitchSettings();

  if (!featureSwitchSettings.isFeatureEnabled(SimpleModeFeature)) {
    router.push({ path: '/' });
  }

  return next();
}
