






















import { Component, Vue } from 'vue-property-decorator';
import { InjectArguments } from 'good-injector-vue';
import { finalize, take } from 'rxjs/operators';

import { SubscriptionService } from '@/services/subscription.service';
import { GetSubscriptionDto } from '@/models/get-subscription-dto';
import { ContactType } from '@/models/contact-type.model';

@Component({
  components: {},
})
export default class OnboardingAlreadyStarted extends Vue {
  private subscriptionService!: SubscriptionService;

  public primaryUserEmail = '';
  public isLoading = true;

  @InjectArguments()
  public mounted(subscriptionService: SubscriptionService): void {
    this.subscriptionService = subscriptionService;
    this.getPrimaryUserEmail();
  }

  private getPrimaryUserEmail() {
    this.subscriptionService
      .getSubscription()
      .pipe(
        take(1),
        finalize(() => this.isLoading = false)
      )
      .subscribe((subscription: GetSubscriptionDto | null) => {
        if (subscription) {
          const contacts = subscription.contacts;
          const primaryContactUser = contacts.find(contact => contact.contactType === ContactType.Primary)
          if (primaryContactUser) {
            this.primaryUserEmail = primaryContactUser.email;
            // In case of lack of primary user, theoretically this should not be the case, but there is supposedly spaghetti on the backend here
          } else if (contacts.length > 0) {
            this.primaryUserEmail = contacts[0].email;
          }
        }
      });
  }
}
