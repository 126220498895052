









































































import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseGridComponent from './BaseGridComponent';
import { InvoiceGridView } from '@/models/invoice-grid-view';
import { SearchService } from '@/services/search.service';
import { InjectArguments } from 'good-injector-vue';
import { SubscriptionResourcesService } from '@/services/subscription-resources.service';
import { CachedResourcesService } from '@/services/cached-resources.service';
import VueRouter from 'vue-router';

@Component({})
export default class InvoicesDetailsGrid extends BaseGridComponent {
  @Prop() public readonly model?: InvoiceGridView[];
  @Prop() public readonly currentPage!: string;

  private readonly $router!: VueRouter;

  @InjectArguments()
  public mounted(searchService: SearchService, subscriptionResourcesService: SubscriptionResourcesService, cachedResourcesService: CachedResourcesService): void {
    this.onMounted(searchService, subscriptionResourcesService, cachedResourcesService);
    this.onDataRefresh();
  }

  @Watch('model', { deep: false })
  onModelChanged(): void {
    this.onDataRefresh();
  }

  public downloadInvoice(e: any): void {
    e.stopPropagation();
    e.preventDefault();
  }

  public showInvoiceDetails(e: any, item: InvoiceGridView): void {
    e.stopPropagation();
    e.preventDefault();
    this.$router.push({ name: 'invoiceDetails', params: { id: item.id.toString(), currentPageNumber: this.currentPageNumber.toString() } });
  }

  private onDataRefresh(): void {
    if (!this.model) {
      return;
    }

    this.setDataSource(this.model);
    this.loadPage(parseInt(this.currentPage));
  }
}
