export enum ResourceImageType {
  Original = 'original',
  Mobile = 'mobile',
  Teams = 'teams',
}

export const AlterResourceImageTypes: ResourceImageType[] = [
  ResourceImageType.Mobile,
  ResourceImageType.Teams,
];
