import { Component, Vue } from 'vue-property-decorator';
import { AlterResourcesRequest } from '@/models/alter-resources-request';
import { SubscriptionPlan } from '@/models/subscription/subscription-plan';

@Component({})
export default class BaseAlterResourcesConfirmationModal extends Vue {
  public costChange = 0;
  public totalCost = 0;
  public isCalculated = false;

  public calculateCosts(
    model: AlterResourcesRequest,
    plan: SubscriptionPlan
  ): void {
    if (
      !plan ||
      !model ||
      !model.activateResourcesIds ||
      !model.deactivateResourcesIds
    ) {
      return;
    }

    const addedRooms = model.activateResourcesIds.length;
    const removedRooms = model.deactivateResourcesIds.length;
    const chargedRooms = addedRooms - removedRooms;
    const pricePerResource: number =
      model.resourceType === 'room'
        ? plan.pricePerRoom
        : (plan.pricePerDesk as number);

    this.costChange = chargedRooms * pricePerResource;
    this.totalCost =
      model.activeResourcesCount * pricePerResource + this.costChange;
    this.isCalculated = true;
  }
}
