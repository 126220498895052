import { GoogleUser } from '@/models/google-user';
import { Token } from '@/models/token';
import { User } from '@/models/user';
import router from '@/router';
import { AuthenticationClient } from '../authentication-client';

export class GoogleAuthenticationClient implements AuthenticationClient {
  private static _googleUser = 'google.user';
  private static _googleAccessToken = 'google.accesstoken';

  public async login(): Promise<void> {
    const that = this;
    const auth = this.getAuthInstance();
    if (auth !== null) {
      await auth.signIn().then(
        function (googleUser: any) {
          const user = googleUser.getBasicProfile();
          const authResponse = googleUser.getAuthResponse();

          that.setGoogleUser(
            new GoogleUser(
              user.getGivenName(),
              user.getFamilyName(),
              user.getEmail(),
              authResponse.expires_at
            )
          );
          that.setAccessToken(
            new Token(authResponse.access_token, authResponse.expires_at)
          );

          router.push('/');
          console.log('User signed in.');
        },
        function (error: any) {
          console.error(error);
          console.log('User failed to sign in.');
        }
      );
    }
  }

  public async acquireToken(): Promise<boolean> {
    const that = this;
    const user = this.getGoogleUser();
    const auth = this.getAuthInstance();

    if (user !== null && auth !== null) {
      await auth
        .signIn()
        .reloadAuthResponse()
        .then(
          function (authResponse: any) {
            that.setAccessToken(
              new Token(authResponse.access_token, authResponse.expires_at)
            );
            console.log('Acquiring token successful.');
            return true;
          },
          function (error: any) {
            console.error(error);
            console.log('Acquiring token failed.');
            return false;
          }
        );
    }
    return false;
  }

  public isLoggedIn(): boolean {
    const auth = this.getAuthInstance();
    if (auth != null) {
      return auth.isSignedIn.get();
    }
    return false;
  }

  public getUser(): User | null {
    const user = this.getGoogleUser();
    if (user !== null) {
      if (!user.isExpired()) {
        return {
          name: user.name,
          surname: user.surname,
          email: user.email,
        };
      }
      this.removeGoogleUser();
      this.removeAccessToken();
    }
    return null;
  }

  public getAuthToken(): string {
    const token = this.getAccessToken();

    if (token !== null) {
      if (!token.isExpired()) {
        return token.accessToken;
      }
      this.removeGoogleUser();
      this.removeAccessToken();
    }
    return String();
  }

  public logout(): void {
    const that = this;
    this.getAuthInstance()
      .signOut()
      .then(
        function () {
          that.removeGoogleUser();
          that.removeAccessToken();
          console.log('User sign out.');
        },
        function (error: any) {
          console.error(error);
          console.log('User failed to sign out.');
        }
      );
  }

  private getAuthInstance(): any | null {
    if (window.gapi && window.gapi.auth2) {
      return window.gapi.auth2.getAuthInstance();
    }

    return null;
  }

  private setGoogleUser(googleUser: GoogleUser) {
    localStorage.setItem(
      GoogleAuthenticationClient._googleUser,
      JSON.stringify(googleUser)
    );
  }

  private getGoogleUser(): GoogleUser | null {
    const currentUser = localStorage.getItem(
      GoogleAuthenticationClient._googleUser
    );
    if (currentUser) {
      const parsedUser = JSON.parse(currentUser);
      return new GoogleUser(
        parsedUser.name,
        parsedUser.surname,
        parsedUser.email,
        parsedUser.expiresAt
      );
    }
    return null;
  }

  private removeGoogleUser() {
    localStorage.removeItem(GoogleAuthenticationClient._googleUser);
  }

  private setAccessToken(accessToken: Token) {
    localStorage.setItem(
      GoogleAuthenticationClient._googleAccessToken,
      JSON.stringify(accessToken)
    );
  }

  private getAccessToken(): Token | null {
    const token = localStorage.getItem(
      GoogleAuthenticationClient._googleAccessToken
    );
    if (token) {
      const parsedToken = JSON.parse(token);
      return new Token(parsedToken.accessToken, parsedToken.expiresAt);
    }
    return null;
  }

  private removeAccessToken() {
    localStorage.removeItem(GoogleAuthenticationClient._googleAccessToken);
  }
}
