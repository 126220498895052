


















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { SubscriptionPlan } from '@/models/subscription/subscription-plan';
import { SubscriptionService } from '@/services/subscription.service';
import { tap } from 'rxjs/operators';
import { InjectArguments } from 'good-injector-vue';
import { SubscriptionPlanMapper } from '@/mappers/subscription-plan.mapper';
import { SubscriptionModel } from '@/models/subscription/subscription-model';

@Component({})
export default class SubscriptionPlansModal extends Vue {
  @Prop(Boolean) public readonly visible!: boolean;

  private subscriptionService!: SubscriptionService;

  public isPlansLoading = false;
  public isSelectedPlanLoading = false;
  public isConfirmationModalVisible = false;
  public plans: SubscriptionPlan[] = [];
  public selectedPlan: SubscriptionPlan = {} as SubscriptionPlan;
  public originalPlan: SubscriptionPlan = {} as SubscriptionPlan;
  public selectedPlanId = String();
  public originalPlanId = String();

  @InjectArguments()
  public mounted(subscriptionService: SubscriptionService): void {
    this.subscriptionService = subscriptionService;
    this.isPlansLoading = this.isSelectedPlanLoading = true;

    this.subscriptionService
      .getSubscriptionPlans()
      .pipe(
        tap(plans => {
          this.isPlansLoading = false;
          this.plans = SubscriptionPlanMapper.getSubscriptionPlans(plans);
        })
      )
      .subscribe();

    this.subscriptionService
      .getSubscriptionPlan()
      .pipe(
        tap(plan => {
          this.isSelectedPlanLoading = false;
          this.originalPlanId = this.selectedPlanId = (plan as SubscriptionModel).planId;
        })
      )
      .subscribe();
  }

  public onCancel(): void {
    this.$emit('cancel');
  }

  public onSave(): void {
    this.originalPlan = this.plans.find(
      plan => plan.id === this.originalPlanId
    ) as SubscriptionPlan;
    this.selectedPlan = this.plans.find(
      plan => plan.id === this.selectedPlanId
    ) as SubscriptionPlan;
    // todo: implement cost change calculation & pass to modal
    this.isConfirmationModalVisible = true;
  }

  public onConfirmationModalConfirm(): void {
    this.isConfirmationModalVisible = false;
    this.subscriptionService.saveSubscriptionPlan(this.selectedPlanId).subscribe(() => {
      this.$emit('accept');
    });
  }

  public onConfirmationModalCancel(): void {
    this.selectedPlanId = this.originalPlanId;
    this.isConfirmationModalVisible = false;
  }

  public onPlanSelected(plan: SubscriptionPlan): void {
    this.selectedPlanId = plan.id;
  }
}
