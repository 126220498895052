import { GuidGenerationService } from '@/services/guid-generation.service';

export enum ToastSeverity {
  Success = 0,
  Error = 1,
}

export class ToastMessage {
  public text = String();
  public severity: ToastSeverity = ToastSeverity.Success;
  public id = String();
  public duration?: ToastDuration;

  constructor(
    message: string,
    severity: ToastSeverity = ToastSeverity.Success,
    duration = ToastDuration.SHORT
  ) {
    this.text = message;
    this.severity = severity;
    this.id = GuidGenerationService.nextGuid();
    this.duration = duration;
  }
}

export enum ToastDuration {
  SHORT = 3000,
  MEDIUM = 5000,
  LONG = 7000,
}
