






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class OverlayLoader extends Vue {
  @Prop(String) public readonly overlayColor!: string;
}
