



















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class DeleteMemberConfirmationModal extends Vue {
  @Prop(Boolean) public readonly visible!: boolean;
  @Prop(String) public readonly title!: string;
  @Prop(String) public readonly confirmButtonLabel!: string;

  public onConfirm(): void {
    this.$emit('confirm');
  }

  public onCancel(): void {
    this.$emit('cancel');
  }
}
