






















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Subject } from 'rxjs';
import { cloneDeep, isEqual } from 'lodash';

import { ResourceImagePreviewConfigBuilder } from '@/services/resource-details/resource-image-preview-config-builder';
import { CropperData, CropperImageData } from '@/models/resource-details/cropper-data';
import { CropperCoordinates } from '@/models/resource-details/cropper-change';
import { AlterResourceImageTypes, ResourceImageType } from '@/models/resource-details/resource-image-type';
import { ResourceImagesDto } from '@/models/resource-details/resource-images-dto';
import { CroppedImage } from '@/models/resource-details/cropped-image';
import { PreviewConfig } from '@/models/resource-details/preview-config';
import { UploadedResourcePhoto } from '@/models/resource-photo.model';
import { ResourcePhotoSaveData } from '@/models/resource-photo-save-data.model';
import { ResourceType } from '@/models/resource-type';

@Component({
  components: {},
})
export default class ResourcePhoto extends Vue {
  @Prop() public readonly model?: ResourceImagesDto;
  @Prop() public readonly isNoPreviewMode?: boolean;
  @Prop() public readonly isNoChangePhotoThumbnailMode?: boolean;
  @Prop() public readonly customImageCoords?: CropperCoordinates;
  @Prop() public readonly customPreviewType?: ResourceImageType;
  @Prop() public readonly shouldForceEditMode?: boolean;
  @Prop() public readonly customImage?: string;
  @Prop() public readonly forceEveryEmitOfCropperChange!: boolean;
  @Prop() public readonly resourceType!: ResourceType;
  @Prop(Boolean) public readonly isDefaultImageActive?: boolean;
  @Prop(Boolean) public readonly initialIsDefaultImageActive?: boolean;

  public data!: CropperData;
  public isLoaded = false;
  public isDisplayingDefaultImage!: boolean | undefined;
  public currentPreviewType!: ResourceImageType;
  public currentOriginalImage = String();
  public currentImageCoords: CropperCoordinates = {} as CropperCoordinates;
  public previewsConfigs!: PreviewConfig[];
  public changePhoto$ = new Subject<void | boolean>();
  public isLoadingPreview = false;

  public mounted(): void {
    this.isDisplayingDefaultImage = this.isDefaultImageActive;
    this.loadData();
  }

  @Watch('model', { deep: true })
  public modelUpdated(): void {
    this.loadData();
  }

  @Watch('customImage', { deep: true })
  public currentOriginalImageUpdated(): void {
    if (this.customImage && !isEqual(this.customImage, this.currentOriginalImage)) {
      this.currentOriginalImage = this.customImage;
      this.$forceUpdate();
    }
  }

  @Watch('customImageCoords', { deep: true })
  public customImageCoordsUpdated(): void {
    if (this.customImageCoords && Object.keys(this.customImageCoords).length > 0) {
      const currentImage = this.getCurrentCropperImageData();
      if (currentImage) {
        this.currentImageCoords = cloneDeep(this.customImageCoords);
      }
    }
  }

  @Watch('customPreviewType', { deep: true })
  public customPreviewTypeUpdated(): void {
    if (this.customPreviewType) {
      this.currentPreviewType = this.customPreviewType;
      this.$forceUpdate();
    }
  }
  
  public get resourceTypeLabel() {
    return { [ResourceType.Room]: 'ROOM', [ResourceType.Desk]: 'DESK' }[this.resourceType];
  }

  public onCropperIsReady() {
    this.$emit('cropperIsReady', true);
  }

  public onPreviewClick(type: ResourceImageType) {
    this.setPreviewType(type);
    this.$forceUpdate();
  }

  public onImageSelected(data: UploadedResourcePhoto): void {
    const { image, isDefault, isNew } = data;

    this.isLoadingPreview = true;
    this.isDisplayingDefaultImage = isDefault;
    this.$emit('imageSelected', data);
    this.$emit('defaultImageSelected', isDefault);

    const temporaryViewType = (this.customPreviewType || this.currentPreviewType) === ResourceImageType.Teams ? ResourceImageType.Mobile : ResourceImageType.Teams;
    const currentViewType = cloneDeep(this.currentPreviewType);

    if (!isDefault && isNew) {
      this.data.setCustomImage(image);
    }

    this.setCurrentImage();

    // FIXME : These setTimeouts are dirty hack to reload previews on image change (especially when default photo preview is selected)
    setTimeout(() => {
      this.setPreviewType(temporaryViewType);
      this.setPreviewsConfigs();

      setTimeout(() => {
        this.setPreviewType(currentViewType);
        this.setPreviewsConfigs();
        this.isLoadingPreview = false;
      }, 500);
    },  this.customPreviewType
      ? 0
      : data.isNew
        ? 500
        : 0);
  }

  public onChangeImageClicked(): void {
    this.changePhoto$.next();
  }

  public onCancelImageClicked(): void {
    this.isLoadingPreview = true;
    this.changePhoto$.next(true);
    this.$emit('defaultImageSelected', this.initialIsDefaultImageActive);

    setTimeout(() => {
      this.setPreviewType(AlterResourceImageTypes[0]);
    }, 50);

    setTimeout(() => {
      this.loadData();
    }, 100);
  }

  public onSaveImageClicked(): void {
    const saveImageData: ResourcePhotoSaveData = {
      customImageData: this.data.customImage,
      setAsDefaultPhoto: false,
      setAsActive: !this.isDisplayingDefaultImage
    }
    this.$emit('saveImage', saveImageData);
  }

  public onImageCropped(model: CroppedImage): void {
    this.$emit('imageCrop', model);

    if (this.isDisplayingDefaultImage) {
      return;
    }

    this.data.setCustomCroppedImage(model, this.isLoadingPreview);
    this.setPreviewsConfigs();

    if (this.isLoadingPreview) {
      const alterIndex = AlterResourceImageTypes.indexOf(this.currentPreviewType);

      if (alterIndex > 0) {
        this.currentPreviewType = AlterResourceImageTypes[alterIndex - 1];
      }
    }
  }

  private loadData(): void {
    if (!!this.model) {
      this.isLoaded = false;
      this.data = new CropperData(this.model);
      const hasCrops = this.data.validateCrops();
      this.isDisplayingDefaultImage = this.isDefaultImageActive;
      this.isLoadingPreview = !this.isDisplayingDefaultImage && !hasCrops;

      // if preview needs to be prepared to reduce transitions count we're starting from last from AlterResourceImageTypes, then iterate to first one
      const viewType = this.customPreviewType
        ? this.customPreviewType
        : (!this.isLoadingPreview ? AlterResourceImageTypes[0] : AlterResourceImageTypes[AlterResourceImageTypes.length - 1]);
      this.setCurrentImage();
      this.setPreviewType(viewType);
      this.setPreviewsConfigs();

      this.isLoaded = true;
    }
  }

  private setCurrentImage(): void {
    const currentImage = this.getCurrentCropperImageData();

    if (!!currentImage) {
      this.currentOriginalImage = this.customImage || currentImage.originalImage;
    }
  }

  private setPreviewType(viewType: ResourceImageType): void {
    const currentImage = this.getCurrentCropperImageData();

    if (currentImage) {
      this.currentImageCoords = currentImage.coordinatesSets[viewType];
    }

    this.currentPreviewType = viewType;
  }

  private setPreviewsConfigs(): void {
    const currentImage = this.getCurrentCropperImageData();
    this.previewsConfigs = ResourceImagePreviewConfigBuilder.buildCropperConfig(currentImage);
    this.$forceUpdate();
  }

  private getCurrentCropperImageData(): CropperImageData | undefined {
    if (this.model) {
      return this.isDisplayingDefaultImage ? this.data.defaultImage : this.data.customImage;
    }
    return undefined;
  }
}
