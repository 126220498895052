import { AuthenticationService } from '@/services/authentication/authentication.service';
import { CachedResourcesService } from '@/services/cached-resources.service';
import { LocalStorageCacheService } from '@/services/local-storage-cache.service';

export default function authMiddleware({
  next,
  router,
}: {
  next: any;
  router: any;
}) {
  const authenticationService = new AuthenticationService(
    new CachedResourcesService(new LocalStorageCacheService())
  );

  if (!authenticationService.sessionClient.isLoggedIn()) {
    router.push({ path: '/auth' });
    return;
  }

  return next();
}
