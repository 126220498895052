import { SubscriptionContactDto } from '@/models/subscription/subscription-contact-dto';
import { GetSubscriptionDto } from '@/models/get-subscription-dto';
import { GetSubscriptionContactDto } from '@/models/get-subscription-dto';
import { ContactType } from '@/models/contact-type.model';
import { TeamMember } from '@/models/team-member';

export class TeamMemberMapper {
  public static getTeamMembers(data: GetSubscriptionDto | null): TeamMember[] {
    if (!data || !data.contacts || data.contacts.length === 0) {
      return [];
    }
    return data.contacts.map((member) =>
      TeamMemberMapper.getTeamMember(member)
    );
  }

  public static getTeamMember(contact: GetSubscriptionContactDto): TeamMember {
    return {
      name: contact.name,
      surname: contact.surname,
      email: contact.email,
      phoneNumber:
        contact.contactType === ContactType.Primary && contact.phone === null
          ? ''
          : contact.phone,
      isInvoicesRecipient: contact.sendInvoices,
      isPrimaryContact: contact.contactType === ContactType.Primary,
      isValid: true,
    };
  }

  public static getContacts(
    teamMembers: TeamMember[]
  ): SubscriptionContactDto[] {
    return (teamMembers || []).map((member) => ({
      name: member.name as string,
      surname: member.surname as string,
      email: member.email as string,
      phone: member.phoneNumber as string,
      sendInvoices: member.isInvoicesRecipient === true,
      contactType: member.isPrimaryContact ? ContactType.Primary : ContactType.Secondary,
    }));
  }
}
