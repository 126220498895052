import { Breadcrumb } from '@/models/breadcrumb';
import { RoutesSettings } from '@/router.constants';

export const DefaultBreadcrumbs: Breadcrumb[] = [
  { name: 'Settings', path: `/dashboard/${RoutesSettings.Root}/` },
];

export const BreadcrumbsRoutesNamesMapping: Record<string, string> = {
  [RoutesSettings.Invoice]: 'Invoice additional data',
  [RoutesSettings.AdministrationConsent]: 'Admin consent',
  [RoutesSettings.DefaultImageAndDescription]: 'Default image & description',
};
