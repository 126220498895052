import { SupportsInjection } from 'good-injector-vue';

@SupportsInjection
export class SearchService {
  public search(dataSource: any[], fields: string[], searchText?: string) {
    const results: any[] = [];
    if (!searchText || dataSource === []) {
      return dataSource;
    }

    for (let i = 0; i < dataSource.length; i++) {
      for (let j = 0; j < fields.length; j++) {
        if (fields[j] in dataSource[i]) {
          const val = dataSource[i][fields[j]] as string;
          if (
            val !== null &&
            val !== undefined &&
            searchText !== null &&
            searchText !== undefined &&
            val.toLocaleLowerCase().startsWith(searchText.toLocaleLowerCase())
          ) {
            results.push(dataSource[i]);
            break;
          }
        }
      }
    }

    return results;
  }
}
