






































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class SetAsDefaultPhotoConfirmationModal extends Vue {
  @Prop(Boolean) public readonly visible!: boolean;

  public onClose(): void {
    this.$emit('close');
  }

  public onConfirm(): void {
    this.$emit('confirm');
  }

  public onCancel(): void {
    this.$emit('cancel');
  }
}
