




















































































import { Component } from 'vue-property-decorator';
import { BaseWizardStepComponent } from './BaseWizardStepComponent';
import { InjectArguments } from 'good-injector-vue';
import { OnboardingStatusService } from '@/services/onboarding-status.service';
import { AuthenticationService } from '@/services/authentication/authentication.service';
import { AuthenticationClientType } from '@/services/authentication/authentication-client-type';

@Component({
  components: {},
})
export default class Step1 extends BaseWizardStepComponent {
  private authenticationService!: AuthenticationService;

  public adminConsent = false;
  public termsConsent = false;

  public stepIndex = 1;

  @InjectArguments()
  public mounted(
    onboardingStatusService: OnboardingStatusService,
    authenticationService: AuthenticationService,
  ): void {
    this.onMounted(onboardingStatusService);

    this.authenticationService = authenticationService;
  }

  public get isConsentGranted(): boolean {
    return this.adminConsent && this.termsConsent;
  }

  public loginMicrosoft(): void {
    this.authenticationService.login(AuthenticationClientType.Microsoft);
  }

  public loginGoogle(): void {
    window.location.href = process.env.VUE_APP_GOOGLE_SIGN_IN_LINK;
  }

  public get termsAndConditionsLink(): string {
    return process.env.VUE_APP_TERMS_AND_CONDITIONS_LINK;
  }

  public get privacyPolicyLink(): string {
    return process.env.VUE_APP_PRIVACY_POLICY_LINK;
  }
}
