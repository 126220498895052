










import { PaymentMethod } from '@/models/payment-method';
import { Component, Vue, Prop, VModel } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class PaymentMethodSelector extends Vue {
    @VModel() payMethod!: PaymentMethod;

    @Prop(Boolean) public readonly isWide!: boolean;

    public paymentMethods = [
      { value: PaymentMethod.CreditCard, label: 'Credit Card (Auto Renewal)' },
      { value: PaymentMethod.ManualBankTransfer, label: 'Manual Bank Transfer' }
    ];

    public onChange(event: any): void {
        this.$emit('input', event.target.value);
    }
}

