export class SubscriptionForm {
  public organizationName = String();
  public industry: string | null = null;
  public tenantId = String();
  public street = String();
  public postcode = String();
  public city = String();
  public countryIsoCode = String();
  public vatId = String();
  public addressId?: string;
}
