import { ValuesValidator } from './values.validator';

export class EmailValidator {
  public static validateEmail(value: string | null | undefined): boolean {
    if (!ValuesValidator.validateString(value)) {
      return false;
    }

    return EmailValidator.isEmailValid(value as string);
  }

  private static isEmailValid(email: string): boolean {
    const expression =
      /^[^\s][a-z0-9.!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?[^\s]$/;
    return expression.test(email);
  }
}
