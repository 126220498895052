
















import { Component, Vue } from 'vue-property-decorator';
import VueRouter from 'vue-router';

@Component({
  components: {},
})
export default class Unauthorized extends Vue {
  private readonly $router!: VueRouter;

  public redirectToAuthPage() {
    this.$router.push({ path: '/auth' });
  }
}
