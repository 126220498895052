import { tap } from 'rxjs/operators';
import { Vue } from 'vue-property-decorator';
import VueRouter from 'vue-router';

import { OnboardingStatusService } from '@/services/onboarding-status.service';

export abstract class BaseWizardStepComponent extends Vue {
  private readonly $router!: VueRouter;
  private onboardingStatusService!: OnboardingStatusService;

  public abstract stepIndex: number;

  onMounted(onboardingStatusService: OnboardingStatusService): void {
    this.onboardingStatusService = onboardingStatusService;
  }

  public goToNextStep(): void {
    this.onboardingStatusService
      .markStepCompleted(this.stepIndex)
      .pipe(
        tap(() => {
          if (this.stepIndex < 6) {
            this.$router.push({ path: `/step${this.stepIndex + 1}` });
          } else {
            this.$router.push({ path: '/dashboard' });
          }
        })
      )
      .subscribe();
  }
}
