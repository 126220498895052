



import { InjectArguments } from 'good-injector-vue';
import { Component, Vue } from 'vue-property-decorator';
import VueRouter from 'vue-router';

import { ICachedStripeCheckoutContext } from '@/models/stripe/cached-stripe-checkout-context.model';
import { StripeCheckoutContext } from '@/models/stripe/stripe-checkout-context.model';
import { CachedStripeCheckoutContextService } from '@/services/cached-stripe-checkout-context.service';
import { StripePaymentsService } from '@/services/stripe-payments.service';

@Component({
  components: {},
})
export default class StripeCheckoutSuccess extends Vue {
  private cachedStripeCheckoutContextService!: CachedStripeCheckoutContextService;
  private stripePaymentsService!: StripePaymentsService;
  private readonly $router!: VueRouter;

  @InjectArguments()
  public mounted(
    cachedStripeCheckoutContextService: CachedStripeCheckoutContextService,
    stripePaymentsService: StripePaymentsService,
  ): void {
    this.cachedStripeCheckoutContextService = cachedStripeCheckoutContextService;
    this.stripePaymentsService = stripePaymentsService;
    this.redirectToPreviousStripeCheckoutContext(this.cachedStripeCheckoutContextService.getCachedStripeCheckoutContext());
  }

  private redirectToPreviousStripeCheckoutContext(context: ICachedStripeCheckoutContext) {
    this.$router.push(
        context.name === StripeCheckoutContext.Subscription
            ? { name: `${context.name}WithEditPlan`, params: { editPlan: 'true' } }
            : { name: context.name }
    );
  }
}
