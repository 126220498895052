
































import { Component, VModel, Vue } from 'vue-property-decorator';
import { InjectArguments } from 'good-injector-vue';
import { take } from 'rxjs/operators';

import {
  ISubscriptionDeactivationReasonSelector
} from '@/models/subscription/subscription-deactivation-reason-selector.model';
import { SubscriptionService } from '@/services/subscription.service';
import { ISubscriptionResignationReason } from '@/models/subscription/subscription-resignation-reason.model';

@Component({
  components: {}
})
export default class SubscriptionDeactivationReasonSelector extends Vue {
  @VModel() propsData!: ISubscriptionDeactivationReasonSelector;

  public readonly deactivationReasonTextareaMaxLength = 255;
  public resignationReasonsOptions: string[] | null = null;
  public loaderSpinnerData = { text: 'Loading resignation reasons...' };

  private subscriptionService!: SubscriptionService;

  @InjectArguments()
  public mounted(subscriptionService: SubscriptionService): void {
    this.subscriptionService = subscriptionService;
    this.getResignationReasonsOptions();
  }

  public onChange(event: { target: { value: string } } ) {
    this.propsData.subscriptionDeactivationReason = event.target.value ? JSON.parse(event.target.value) : null;
    this.propsData.deactivationReasonAdditionalInfo = '';
  };

  public getResignationReasonLabel(resignationReason: string): string {
    return resignationReason ? (JSON.parse(resignationReason) as ISubscriptionResignationReason).name : '';
  }

  public isResignationReasonSelected(resignationReason: string): boolean {
    const parsedIndustry = JSON.parse(resignationReason) as ISubscriptionResignationReason;
    return this.propsData.subscriptionDeactivationReason ? parsedIndustry.id === this.propsData.subscriptionDeactivationReason.id : false;
  }

  private getResignationReasonsOptions(): void {
    this.subscriptionService.getResignationReasons()
      .pipe(
        take(1)
      )
      .subscribe((resignationReasons) => {
        this.resignationReasonsOptions = resignationReasons.map((resignationReason) => JSON.stringify(resignationReason));
      });
  }
}
