















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { ToastMessage, ToastSeverity } from '@/models/toast-message';

@Component({
  components: {}
})
export default class Toast extends Vue {
    @Prop() public messages!: Subject<ToastMessage>;

    public toastMessages: ToastMessage[] = [];
    public Severity: typeof ToastSeverity = ToastSeverity;

    public mounted(): void {
        if (this.messages) {
            this.messages.pipe(
                filter(message => !!message && message.text.length > 0),
                tap(message => this.enqueueNextToastMessage(message))
            ).subscribe();
        }
    }

    private enqueueNextToastMessage(message: ToastMessage) {        
        this.toastMessages.push(message);
        
        setTimeout(() => {
            this.toastMessages = this.toastMessages.splice(0, this.toastMessages.length - 1);
        }, message.duration);
    }

}
