



































import { Component, VModel, Vue, Watch } from 'vue-property-decorator';

import { ISubscriptionForm } from '@/models/subscription-form.model';
import {
  NotAVatPayerLabel,
  OrganizationDetailsFormIsVatDefinedOptions
} from '@/constants/OrganizationDetailsForm.constants';

@Component({})
export default class OrganizationDetailsFormVatId extends Vue {
  @VModel() subscriptionForm!: ISubscriptionForm;

  private lastVatId!: string;
  private lastCountryIsoCode!: string;
  private initialVatId!: string;

  public isVatIdDefinedOptions = OrganizationDetailsFormIsVatDefinedOptions
  // source of max length of VAT ID: https://www.quaderno.io/blog/what-is-a-vat-number
  public readonly maxVatIdLength = 16;

  @Watch('subscriptionForm', { deep: true })
  public changeVatId() {
    if (this.subscriptionForm.countryIsoCode) {
      const { vatId, countryIsoCode } = this.subscriptionForm;

      if (vatId && countryIsoCode && (vatId !== this.lastVatId || countryIsoCode !== this.lastCountryIsoCode)) {
        if (!this.initialVatId ) {
          this.initialVatId = vatId;
        }
        if (vatId === NotAVatPayerLabel) {
          this.subscriptionForm.vatId = ''
        }
      }
      [this.lastVatId, this.lastCountryIsoCode] = [vatId, countryIsoCode];
    }
  }

  public onVatIdIsDefinedOptionChange(event: { target: { value: 'true' | 'false' } }) {
    this.subscriptionForm.isVatIdDefined = event.target.value === 'true';
    this.subscriptionForm.vatId = this.subscriptionForm.isVatIdDefined ? (this.initialVatId || '') : NotAVatPayerLabel;
  }

  public getCurrentCountryExampleVatId() {
    return this.subscriptionForm.isVatIdDefined
      ? this.subscriptionForm.countryIsoCode
        ? `${this.subscriptionForm.countryIsoCode.toUpperCase()}0123456789`
        : 'GB0123456789'
      : NotAVatPayerLabel;
  }

  public isVatIdEmpty(): boolean {
    return !!(this.subscriptionForm.isVatIdDefined && !this.subscriptionForm.vatId.trim());
  }
}
