export class Token {
  constructor(accessToken: string, expiresAt: number) {
    this.accessToken = accessToken;
    this.expiresAt = expiresAt;
  }

  public accessToken: string;
  public expiresAt: number;

  public isExpired(): boolean {
    return new Date().getTime() > this.expiresAt;
  }
}
