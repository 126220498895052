import { ResourceType } from '@/models/resource-type';
import { User } from '@/models/user';
import { SupportsInjection } from 'good-injector-vue';
import { CachedResourcesService } from '../cached-resources.service';
import { AuthenticationClient } from './authentication-client';
import { AuthenticationClientType } from './authentication-client-type';
import { GoogleAuthenticationClient } from './clients/google-authentication-client';
import { MicrosoftAuthenticationClient } from './clients/microsoft-authentication-client';
import { UnauthorizedAuthenticationClient } from './clients/unauthorized-authentication-client';

@SupportsInjection
export class AuthenticationService {
  public constructor(cachedResourcesService: CachedResourcesService) {
    this.cachedResourcesService = cachedResourcesService;
  }
  private cachedResourcesService!: CachedResourcesService;
  private sessionKey = 'BOOKADO.ADMINPORTAL.CLIENT_TYPE';

  public login(clientType: AuthenticationClientType): void {
    this.openAuthenticationSession(clientType);
    this.sessionClient.login();
  }

  public logout(): void {
    for (const item in ResourceType) {
      if (isNaN(Number(item))) {
        this.cachedResourcesService.removeCachedResources(
          item.toLocaleLowerCase()
        );
      }
    }

    const client = this.sessionClient;
    this.closeAuthenticationSession();
    client.logout();
  }

  public get sessionUser(): User | null {
    const client = this.sessionClient;
    return client.getUser();
  }

  public get sessionClient(): AuthenticationClient {
    this.clientType;

    switch (this.clientType) {
      case AuthenticationClientType.Microsoft:
        return new MicrosoftAuthenticationClient();
      case AuthenticationClientType.Google:
        return new GoogleAuthenticationClient();
      default:
        return new UnauthorizedAuthenticationClient();
    }
  }

  public get clientType(): AuthenticationClientType {
    const clientTypeValue: string | null = sessionStorage.getItem(
      this.sessionKey
    );
    let clientType: AuthenticationClientType =
      AuthenticationClientType.Unauthorized;

    if (!!clientTypeValue && clientTypeValue.length > 0) {
      clientType = +clientTypeValue;
    }

    return clientType;
  }

  private openAuthenticationSession(
    clientType: AuthenticationClientType
  ): void {
    this.closeAuthenticationSession();
    sessionStorage.setItem(this.sessionKey, `${clientType}`);
  }

  private closeAuthenticationSession(): void {
    sessionStorage.removeItem(this.sessionKey);
  }
}
