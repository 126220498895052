import { ISubscriptionChangeStatusModalLabels } from '@/models/subscription/subscription-change-status-modal-labels.model';

export const SubscriptionChangeStatusModalLabels: {
  activate: ISubscriptionChangeStatusModalLabels;
  deactivate: ISubscriptionChangeStatusModalLabels;
} = {
  activate: {
    beforeConfirm: {
      heading: 'Confirm Paid Service Activation',
      primaryText:
        'You are going to activate Paid Service subscription. We will provide the invoice at the end of each billing period.',
      secondaryText: 'Please confirm to proceed.',
    },
    afterConfirm: {
      heading: 'Paid Service Activated',
      primaryText:
        'You have activated Paid Service subscription. We will provide the invoice at the end of each billing period.',
      secondaryText: 'Thank you for being with us.',
    },
  },
  deactivate: {
    beforeConfirm: {
      heading: 'Confirm Subscription Deactivation',
      primaryText:
        'You are going to deactivate Paid Service subscription. The last invoice will be provided at the end of billing period.',
      secondaryText: 'Please confirm to proceed.',
    },
    afterConfirm: {
      heading: 'Subscription Deactivated',
      primaryText:
        'You have deactivated Paid Service subscription. The last invoice will be provided at the end of billing period.',
      secondaryText: 'Thank you for being with us.',
    },
  },
};
