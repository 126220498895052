import debounce from 'lodash/debounce';
import moment from 'moment';
import Vue from 'vue';
import { Route } from 'vue-router';

export const wrappedDebounce = (method: any, timeout: number): any => {
  if (process.env.NODE_ENV === 'test') {
    return method;
  }

  return debounce(method, timeout);
};

export const undefinedSafety = (
  object: any,
  path: string,
  defaultValue: any
): any => {
  if (object === null || object === undefined) {
    return defaultValue;
  }
  const result: any = path
    ? path.split('.').reduce((value, key) => {
        return value && value[key];
      }, object)
    : object;

  return result === undefined ? defaultValue : result;
};

export const capitalizeFirstLetter = (word: string) => {
  return word
    ? word.charAt(0).toUpperCase() +
        word.toLowerCase().slice(1).replace(/_/g, ' ')
    : '';
};

export const isDatePast = (date: Date): boolean => {
  const endMoment = moment(date);
  return endMoment.diff(moment(), 'hours') > 0;
};

Vue.mixin({
  methods: {
    formatDate(time) {
      const dateObj = new Date(time);
      const month = dateObj.toLocaleString('en-us', { month: 'long' });
      return (
        dateObj.getUTCDate() + ' ' + month + ' ' + dateObj.getUTCFullYear()
      );
    },
    formatTime(time) {
      const dateObj = new Date(time);
      return (
        ('0' + dateObj.getHours()).slice(-2) +
        ':' +
        ('0' + dateObj.getMinutes()).slice(-2) +
        ':' +
        ('0' + dateObj.getSeconds()).slice(-2)
      );
    },
    capitalizeFirstLetter(word) {
      return capitalizeFirstLetter(word);
    },
    undefinedSafety(object: any, path: string, defaultValue: any): any {
      return undefinedSafety(object, path, defaultValue);
    },
    emptyCheck(value: any, defaultValue: any): any {
      return value ? value : defaultValue;
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => {
        return (
          (this as unknown as { $route: Route }).$route.path.indexOf(path) === 0
        ); // current path starts with this path string
      });
    },
  },
});

// https://vuejs.org/v2/guide/custom-directive.html#Directive-Hook-Arguments
Vue.directive('focus', {
  inserted(el, binding) {
    if (binding.value) {
      el.focus();
    }
  },
});
