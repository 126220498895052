







import { Component, VModel, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class LoaderSpinner extends Vue {
  @VModel() propsData!: { text: string };
}
