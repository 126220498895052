import { Resource } from '@/models/resource';
import { SupportsInjection } from 'good-injector-vue';
import { LocalStorageCacheService } from './local-storage-cache.service';

@SupportsInjection
export class CachedResourcesService {
  private cachedResourcesKey = 'RESOURCES_';
  public constructor(
    private localStorageCacheService: LocalStorageCacheService
  ) {}

  public getCachedResources(resourceType: string): Resource[] {
    return this.localStorageCacheService.getItem(
      this.getCachedResourcesKey(resourceType)
    );
  }

  public setCachedResources(resourceType: string, resources: Resource[]): void {
    resources.forEach((resource) => {
      resource.isSelected = false;
    });

    return this.localStorageCacheService.setItem(
      this.getCachedResourcesKey(resourceType),
      resources
    );
  }

  public removeCachedResources(resourceType: string): void {
    return this.localStorageCacheService.removeItem(
      this.getCachedResourcesKey(resourceType)
    );
  }

  private getCachedResourcesKey(resourceType: string): string {
    return `${this.cachedResourcesKey}${resourceType}`;
  }
}
