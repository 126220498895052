




import { Component,Prop,Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class CharactersLimitCounter extends Vue {
    @Prop(String) public currentModel!: string;
    @Prop(Number) public maxNumberOfCharacters!: string;
}
