import moment from 'moment';

export class LocalStorageCacheService {
  public getItem(key: string): any | null {
    let item = localStorage.getItem(key) as any;
    if (item) {
      item = JSON.parse(item) as any;
      if (moment(new Date(item.expires)).isBefore(Date.now())) {
        localStorage.removeItem(key);
        return null;
      }
      return item;
    }
    return null;
  }

  public setItem(key: string, item: any, shouldAddExpiresKey = true): void {
    if (shouldAddExpiresKey) {
      (item as any).expires = moment(Date.now())
          .add(process.env.VUE_APP_LOCAL_STORAGE_CACHE_MAX_AGE_IN_MIN, 'minutes')
          .toDate();
    }
    localStorage.setItem(key, JSON.stringify(item));
  }

  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}
