import { GetSubscriptionDto } from '@/models/get-subscription-dto';
import { CountryService } from '@/services/country.service';
import { SubscriptionForm } from '@/classes/subscription-form.class';
import { ISubscriptionForm } from '@/models/subscription-form.model';

export class SubscriptionMapper {
  public static getSubscriptionFormValues(
    subscription: GetSubscriptionDto | null
  ): ISubscriptionForm {
    const subscriptionForm = new SubscriptionForm();

    if (subscription) {
      subscriptionForm.organizationName = subscription.name;
      subscriptionForm.industry = subscription.industry
        ? subscription.industry.name
        : '-';
      subscriptionForm.vatId = subscription.vatId;
      subscriptionForm.tenantId = subscription.tenantId;

      if (!!subscription.addresses && subscription.addresses.length > 0) {
        const address = subscription.addresses[0];
        subscriptionForm.street = address.street;
        subscriptionForm.city = address.city;
        subscriptionForm.addressId = address.id;
        subscriptionForm.countryIsoCode = CountryService.getCountryByIsoCode(
          address.country
        );
        subscriptionForm.postcode = address.postalCode;
      }
    }

    return subscriptionForm as ISubscriptionForm;
  }
}
