



































































import { Component, Vue, Prop, VModel, Watch } from 'vue-property-decorator';
import { InjectArguments } from 'good-injector-vue';
import { Subject, of, forkJoin, Observable, EMPTY } from 'rxjs';
import {
  tap,
  filter,
  switchMap,
  take, catchError,
} from 'rxjs/operators';
import { AxiosError } from 'axios';

import { SubscriptionDto, SubscriptionAddressDto } from '@/models/subscription/subscription-dto';
import { IOrganizationFormValidity } from '@/models/organization-form-validity.model';
import { ISubscriptionForm } from '@/models/subscription-form.model';
import { GetSubscriptionDto } from '@/models/get-subscription-dto';
import { IIndustry } from '@/models/industry.model';
import { AuthenticationService } from '@/services/authentication/authentication.service';
import { SubscriptionService } from '@/services/subscription.service';
import { NotAVatPayerLabel } from '@/constants/OrganizationDetailsForm.constants';
import { SubscriptionForm } from '@/classes/subscription-form.class';
import { ToastMessage, ToastSeverity } from '@/models/toast-message';

@Component({
  components: {}
})
export default class OrganizationDetailsForm extends Vue {
  @VModel() organizationFormValidity!: IOrganizationFormValidity;

  @Prop(Subject) public readonly onSubmit$!: Subject<boolean>;
  @Prop(Subject) public readonly showEditModalChange$!: Subject<boolean>;
  @Prop(Boolean) public readonly isOnboarding!: boolean;
  @Prop() public readonly subscriptionData!: GetSubscriptionDto;
  @Prop() public readonly onValidationError!: () => void;

  private authenticationService!: AuthenticationService;
  private subscriptionService!: SubscriptionService;

  public errors: { [key: string]: boolean } = {};
  public subscriptionForm: ISubscriptionForm = new SubscriptionForm() as ISubscriptionForm;
  public industryOptions: string[] | null = null;
  public wasSubmitted = false;
  public toastMessage$: Subject<ToastMessage> = new Subject<ToastMessage>();

  @InjectArguments()
  public mounted(
    authenticationService: AuthenticationService,
    subscriptionService: SubscriptionService
  ): void {
    this.authenticationService = authenticationService;
    this.subscriptionService = subscriptionService;
    this.$emit('isLoading', true);
    this.updateSubscriptionDetails().subscribe();
    this.onSubmit$.pipe(tap(() => this.checkForm())).subscribe(() => this.wasSubmitted = true);
    if (this.showEditModalChange$) {
      this.showEditModalChange$
        .pipe(
          filter(Boolean),
          switchMap(() => forkJoin([this.updateSubscriptionDetails(), this.getIndustryOptions()]))
        )
        .subscribe();
    }
    if (this.isOnboarding) {
      this.getIndustryOptions().subscribe();
    }
  }

  @Watch('subscriptionForm', { deep: true })
  updateSubscriptionForm() {
    this.organizationFormValidity = {
      isEmpty: !!(this.subscriptionForm.isVatIdDefined && !this.subscriptionForm.vatId)
    };
    if (this.wasSubmitted) {
      this.checkForm(true);
    }
  }

  public onIndustryOptionChange(event: { target: { value: string | null } }) {
    this.subscriptionForm.industry = event.target.value ? JSON.parse(event.target.value) : null;
  }

  public getIndustryLabel(industry: string): string {
    return industry ? (JSON.parse(industry) as IIndustry).name : '';
  }

  public isIndustrySelected(industry: string): boolean {
    const parsedIndustry = JSON.parse(industry) as IIndustry;
    return this.subscriptionForm.industry ? parsedIndustry.id === this.subscriptionForm.industry.id : false;
  }

  private updateSubscriptionDetails(): Observable<GetSubscriptionDto | null> {
    this.$emit('isLoading', true);

    return (this.subscriptionData ? of(this.subscriptionData) : this.subscriptionService.getSubscription())
      .pipe(
        tap((subscription) => {
          this.$emit('isLoading', false);

          if (subscription) {
            const { name, tenantId, vatId, addresses, industry } = subscription;
            const { city, street, postalCode, country, id } = addresses[0];
            this.subscriptionForm = {
              ...this.subscriptionForm,
              organizationName: name,
              industry,
              tenantId,
              vatId,
              city,
              street,
              postcode: postalCode,
              countryIsoCode: country,
              addressId: id,
              isVatIdDefined: !!vatId && vatId !== NotAVatPayerLabel
            };
          } else {
            const sessionUser = this.authenticationService.sessionUser;
            if (sessionUser && sessionUser.tenantId) {
              this.subscriptionForm.tenantId = sessionUser.tenantId;
            }
            this.subscriptionForm = {
              ...this.subscriptionForm,
              isVatIdDefined: false
            };
          }
        })
      );
  }

  private checkForm(checkWithoutSubmit = false): void {
    const properties = Object.keys(this.subscriptionForm);
    const errors: { [key: string]: boolean } = {};

    properties
      // 'isVatIdDefined' shouldn't be validated, it's just for showing / hiding VAT ID input; 'vatId' shouldn't be validated, when no VAT payer option is selected
      .filter((property) => property !== 'isVatIdDefined' && this.subscriptionForm.isVatIdDefined ? property !== 'vatId' : null)
      .forEach(propertyName => {
        const value = this.subscriptionForm[propertyName];
        if (!value) {
          errors[propertyName] = true;
        }
      });

    this.errors = errors;

    if (Object.values(this.errors).length === 0) {
      if (!checkWithoutSubmit) {
        this.saveForm();
      }
    } else if (this.onValidationError) {
      this.onValidationError();
    }
  }

  private saveForm(): void {
    const address: SubscriptionAddressDto = {
      city: this.subscriptionForm.city,
      street: this.subscriptionForm.street,
      postalCode: this.subscriptionForm.postcode,
      country: this.subscriptionForm.countryIsoCode
    };

    const data: SubscriptionDto = {
      name: this.subscriptionForm.organizationName,
      domain: this.subscriptionForm.tenantId,
      vatId: this.subscriptionForm.vatId,
      industry: this.subscriptionForm.industry,
      address
    };

    this.subscriptionService.saveSubscription(data)
      .pipe(
        take(1),
        catchError((error: AxiosError) => {
          console.error(error);

          let errorMessage = 'An error occurred, couldn\'t save';
          if (error.response && error.response.status === 400) {
            errorMessage = 'Couldn\'t save, some inputs are empty, but they are required';
          }
          this.toastMessage$.next(
              new ToastMessage(errorMessage, ToastSeverity.Error)
          );

          this.$emit('submitted', false);
          this.$emit('isLoading', false);

          return EMPTY;
        }),
      )
      .subscribe(() => {
        this.$emit('submitted', true);
      });
  }

  private getIndustryOptions(): Observable<IIndustry[]> {
    return (this.industryOptions ? of(this.industryOptions.map(industryOption => JSON.parse(industryOption)) as IIndustry[]) : this.subscriptionService.getIndustryOptions())
      .pipe(
        take(1),
        tap((industryOptions) => {
          this.industryOptions = industryOptions.map((industryOption) => JSON.stringify(industryOption));
        })
      );
  }
}
