


























































import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Component, Vue } from 'vue-property-decorator';
import { InjectArguments } from 'good-injector-vue';

import { ToastMessage, ToastSeverity } from '@/models/toast-message';
import { SettingsService } from '@/services/settings.service';
import { EmailValidator } from '@/validators/email.validator';

@Component({
  components: {}
})
export default class SettingsInvoice extends Vue {
  public emailAddresses = '';
  public notes = '';
  public settingService!: SettingsService;
  public areEmailAddressesInvalid = false;
  public emailAddressToastMessagesSubject: Subject<ToastMessage> = new Subject<ToastMessage>();
  public notesToastMessagesSubject: Subject<ToastMessage> = new Subject<ToastMessage>();
  private maxNumberOfCharactersInEmailAddresses = 255;
  private maxNumberOfCharactersInNotes = 500;

  @InjectArguments()
  public mounted(settingService: SettingsService): void {
    this.settingService = settingService;

    this.settingService.getNotes()
        .pipe(
            tap(notes => {
              this.notes = notes.text;
            })
        )
        .subscribe();

    this.settingService.getEmailAddresses()
        .pipe(
            tap(emailAddresses => {
              this.emailAddresses = emailAddresses ? emailAddresses.map(function(ea) {
                return ea.email;
              }).join(';') : '';
            })
        )
        .subscribe();
  }

  public onSaveEmailAddressesClicked(): void {
    let areEmailAddressesValid = true;
    let emailAddresses = this.emailAddresses.split(';');

    for (let i = 0; i < emailAddresses.length; i++) {
      emailAddresses[i] = emailAddresses[i].trim();
    }

    for (let i = 0; i < emailAddresses.length; i++) {
      if (!EmailValidator.validateEmail(emailAddresses[i])) {
        areEmailAddressesValid = false;
        break;
      }
    }

    this.areEmailAddressesInvalid = !areEmailAddressesValid;
    if (!this.areEmailAddressesInvalid) {
      this.settingService.updateEmailAddresses(emailAddresses.map(function(ea) {
        return { email: ea };
      })).pipe(
          tap((result) => {
            if (result.isSuccess) {
              this.emailAddressToastMessagesSubject.next(new ToastMessage('Email addresses successfully updated.'));
            } else {
              this.emailAddressToastMessagesSubject.next(
                  new ToastMessage(
                      'Couldn\'t save email addresses. Please try again later.',
                      ToastSeverity.Error
                  )
              );
            }
          })).subscribe();
    }
  }

  public onSaveNotesClicked(): void {
    this.settingService.updateNotes({ text: this.notes }).pipe(
        tap((result) => {
          if (result.isSuccess) {
            this.notesToastMessagesSubject.next(new ToastMessage('Notes successfully updated.'));
          } else {
            this.notesToastMessagesSubject.next(
                new ToastMessage(
                    'Couldn\'t save notes. Please try again later.',
                    ToastSeverity.Error
                )
            );
          }
        })).subscribe();
  }
}
