




import { Component, Vue } from 'vue-property-decorator';
import VueRouter from 'vue-router';

@Component({
  components: {},
})
export default class Unauthenticated extends Vue {
  private readonly $router!: VueRouter;

  public redirectToAuthPage() {
    if (process.env.VUE_APP_EXTERNAL_AUTH === 'true') {
      window.location = process.env.VUE_APP_AUTH_PROVIDER;
    } else {
      this.$router.push({ path: '/' });
    }
  }
}
