















import { PreviewConfig } from '@/models/resource-details/preview-config';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ResourcePhotoPreview extends Vue {
  @Prop() public readonly config!: PreviewConfig;
  @Prop() public readonly isActive!: boolean;

  public onClick(): void {
    this.$emit('click');
  }
}
