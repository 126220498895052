













import { Component, Vue, Prop } from 'vue-property-decorator';
import { User } from '../../models/user';

@Component({
  components: {},
})
export default class UserCard extends Vue {
  @Prop() public user!: User | null;
  @Prop() showPhoneNumber?: boolean;
  @Prop() detailsWidth?: number | undefined;

  public get initials(): string {
    if (!this.user || !this.user.name) {
      return String();
    }

    return this.user.name
      .split(' ')
      .map((p) => (p[0] || String()).toUpperCase())
      .join('');
  }
}
