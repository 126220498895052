




import { Component, Vue, Prop } from 'vue-property-decorator';
import { ConsentStatus } from '@/models/consent-status';

@Component({
  components: {}
})
export default class ConsentStatusComponent extends Vue {
  @Prop(Number) public readonly status!: ConsentStatus;

  private statusDetails = [
    {
      key: ConsentStatus.NotStarted,
      label: 'Not started',
      class: 'notStarted'
    },
    { key: ConsentStatus.Completed, label: 'Completed', class: 'completed' },
    { key: ConsentStatus.Error, label: 'Error', class: 'error' }
  ];

  public get label() {
    const item = this.statusDetails.find(l => l.key === this.status);
    return item ? item.label : '';
  }

  public get statusClass() {
    const item = this.statusDetails.find(l => l.key === this.status);
    return item ? item.class : '';
  }
}
