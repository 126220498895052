import { Observable } from 'rxjs';

import { ApiClientService } from './api-client.service';
import { AuthenticationService } from './authentication/authentication.service';

export class OrganizationSettingsService extends ApiClientService {
  public constructor(authenticationService: AuthenticationService) {
    super(authenticationService, `${process.env.VUE_APP_BOOKADO_API_URI}/`);
  }

  public getSettingsByKey(key: string): Observable<any> {
    return this.get(`settings/${key}`);
  }

  public setSettingsByKey(key: string, value: any): Observable<any> {
    return this.post(`settings/${key}`, value);
  }
}
