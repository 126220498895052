import axios, { AxiosInstance, AxiosResponse, ResponseType } from 'axios';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import router from '../router';
import { AuthenticationClientType } from './authentication/authentication-client-type';
import { AuthenticationService } from './authentication/authentication.service';

export class ApiClientService {
  private readonly clientInstance: AxiosInstance;

  constructor(
    protected authenticationService: AuthenticationService,
    baseUrl: string
  ) {
    this.clientInstance = axios.create({
      baseURL: baseUrl,
    });

    this.clientInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 403) {
          router.push('/onboarding-already-started');
          // if there will be any other cases in the future than stopped primary user's onboarding /unauthorized can be used
        }
        return Promise.reject(error);
      }
    );
  }

  public post<T>(
    url: string,
    data: any,
    contentType = String(),
    authorizationToken: string | null = null
  ): Observable<T> {
    return from(
      this.clientInstance.post<T>(
        url,
        data,
        this.getClientOptions(contentType, authorizationToken)
      )
    ).pipe(map((response: AxiosResponse<T>) => response.data));
  }

  public put<T>(
    url: string,
    data: any,
    contentType = String(),
    authorizationToken: string | null = null
  ): Observable<T> {
    return from(
      this.clientInstance.put<T>(
        url,
        data,
        this.getClientOptions(contentType, authorizationToken)
      )
    ).pipe(map((response: AxiosResponse<T>) => response.data));
  }

  public delete<T>(url: string): Observable<T> {
    return from(
      this.clientInstance.delete<T>(url, this.getClientOptions())
    ).pipe(map((response: AxiosResponse<T>) => response.data));
  }

  public patch<T>(url: string, data: any): Observable<T> {
    return from(
      this.clientInstance.patch<T>(url, data, this.getClientOptions())
    ).pipe(map((response: AxiosResponse<T>) => response.data));
  }

  public get<T>(
    url: string,
    authorizationToken: string | null = null
  ): Observable<T> {
    return from(
      this.clientInstance.get<T>(
        url,
        this.getClientOptions('', authorizationToken)
      )
    ).pipe(map((response: AxiosResponse<T>) => response.data));
  }

  public getImage(url: string): Observable<string> {
    const responseType: ResponseType = 'arraybuffer';

    return from(
      this.clientInstance.get<ArrayBuffer>(url, { responseType })
    ).pipe(
      map((response: AxiosResponse<ArrayBuffer>) => {
        const image = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        );
        return `data:${response.headers[
          'content-type'
        ].toLowerCase()};base64,${image}`;
      })
    );
  }

  public get client(): AxiosInstance {
    return this.clientInstance;
  }

  public getClientOptions(
    contentType = String(),
    authorizationToken: string | null = null
  ) {
    const headers = {
      Authorization: `Bearer ${
        authorizationToken === null
          ? this.authenticationService.sessionClient.getAuthToken()
          : authorizationToken
      }`,
      'X-CALENDAR-PROVIDER': this.getCalendarProvider(),
      CLIENT_VERSION: 'adminportal,0',
    };

    if (!!contentType && contentType.length > 0) {
      (headers as any)['Content-Type'] = contentType;
    }

    return { headers };
  }

  private getCalendarProvider(): string {
    switch (this.authenticationService.clientType) {
      case AuthenticationClientType.Microsoft:
        return 'microsoft';
      case AuthenticationClientType.Google:
        return 'google';
      default:
        return String();
    }
  }
}
