import { Component, Vue } from 'vue-property-decorator';
import { Subject, Observable } from 'rxjs';
import { SearchService } from '@/services/search.service';
import { SubscriptionResourcesService } from '@/services/subscription-resources.service';
import { CachedResourcesService } from '@/services/cached-resources.service';

@Component({})
export default class BaseGridComponent extends Vue {
  public currentPageNumber = 1;
  public pageData: any[] = [];
  public currentPageSize = 0;
  public totalItemsCount = 0;
  public searchText = String();
  public subscriptionResourcesService!: SubscriptionResourcesService;
  public cachedResourcesService!: CachedResourcesService;
  public pageSize = 0;

  private dataSource: any[] = [];
  private pageLoadedSubject = new Subject();
  private searchService!: SearchService;

  onMounted(
    searchService: SearchService,
    subscriptionResourcesService: SubscriptionResourcesService,
    cachedResourcesService: CachedResourcesService
  ): void {
    this.searchService = searchService;
    this.subscriptionResourcesService = subscriptionResourcesService;
    this.cachedResourcesService = cachedResourcesService;
    this.pageSize = parseInt(process.env.VUE_APP_GRID_PAGE_SIZE);
  }

  public setDataSource(data: any[]): void {
    this.dataSource = data;
    this.totalItemsCount = data.length;
  }

  public getDataSource(): any[] {
    return this.dataSource;
  }

  public setCurrentPageNumber(currentPageNumber: number) {
    this.currentPageNumber = currentPageNumber;
  }

  public loadPage(pageNumber: number, searchText?: string): void {
    this.searchText = searchText || String();
    const filteredDataSource = this.getFilteredDataSource(this.searchText);
    this.totalItemsCount = filteredDataSource.length;

    const itemsCount = filteredDataSource.length;
    const minIndex = (pageNumber - 1) * this.pageSize;
    const maxIndex = pageNumber * this.pageSize;

    if (pageNumber <= 0 || minIndex > itemsCount) {
      return;
    }

    const pageData: any[] = [];

    for (let i = minIndex; i < Math.min(itemsCount, maxIndex); i++) {
      pageData.push(filteredDataSource[i]);
    }

    this.pageData = pageData;
    this.currentPageNumber = pageNumber;
    this.currentPageSize = pageData.length;
    this.pageLoadedSubject.next();
  }

  public onPageLoaded(): Observable<any> {
    return this.pageLoadedSubject.asObservable();
  }

  private getFilteredDataSource(searchText?: string): any[] {
    const fields = ['name', 'location'];
    return this.searchService.search(this.dataSource, fields, searchText);
  }
}
