type DisabledFeature = {
  featureName: string;
  isDisabled: boolean;
};

export const FeatureSettingsPrefix = 'VUE_APP_FEATURE_DISABLE_';
export const OnboardingFeature = 'ONBOARDING';
export const DashboardFeature = 'DASHBOARD';
export const SimpleModeFeature = 'SIMPLE_MODE';
export const InvoicesFeature = 'INVOICES';
export const InvoiceDetailsFeature = 'INVOICE_DETAILS';
export const OrganizationFeature = 'ORGANIZATION';
export const SubscriptionFeature = 'SUBSCRIPTION';
export const ResourcesFeature = 'RESOURCES';
export const ResourcesCostCalculationFeature = 'RESOURCES_COST_CALCULATION';
export const TeamFeature = 'TEAM';

const allFeatures: string[] = [
  OnboardingFeature,
  DashboardFeature,
  SimpleModeFeature,
  InvoicesFeature,
  InvoiceDetailsFeature,
  OrganizationFeature,
  SubscriptionFeature,
  ResourcesFeature,
  ResourcesCostCalculationFeature,
  TeamFeature,
];

export class FeatureSwitchSettings {
  private features!: DisabledFeature[];

  constructor() {
    this.features = allFeatures.map((featureName) => {
      const key = `${FeatureSettingsPrefix}${featureName}`;
      const isDisabled = process.env[key] === 'true';
      return { featureName, isDisabled };
    });
  }

  public isFeatureEnabled(featureName: string) {
    const feature = this.features.find(
      (f) => f.featureName === featureName.toUpperCase()
    );
    return feature ? !feature.isDisabled : true;
  }
}
