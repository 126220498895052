import { Observable, of } from 'rxjs';
import { InvoiceDetailsView } from './../models/invoice-details-view';
import { InvoiceGridView } from './../models/invoice-grid-view';

export class InvoicesService {
  private invoicesCache: InvoiceDetailsView[] = [];

  public getInvoiceViews(): Observable<InvoiceGridView[]> {
    this.ensureInvoicesCache();
    return of(this.invoicesCache.map((i) => this.toGridView(i)));
  }

  public getInvoiceById(id: number): Observable<InvoiceDetailsView> {
    this.ensureInvoicesCache();
    return of(
      this.invoicesCache.find((i) => i.id === id) as InvoiceDetailsView
    );
  }

  private ensureInvoicesCache(): void {
    // if (this.invoicesCache.length === 0) {
    //     this.invoicesCache = InvoicesGenerator.generateInvoiceGridViews(75);
    // }
  }

  private toGridView(invoice: InvoiceDetailsView): InvoiceGridView {
    return {
      id: invoice.id,
      date: invoice.date,
      invoiceId: invoice.invoiceId,
      planName: invoice.planName,
      costPerMonth: invoice.totalPrice,
      currency: invoice.currency,
      isPaid: invoice.isPaid,
      paymentDate: invoice.paymentDate,
    };
  }
}
