











































import { Component, Vue } from 'vue-property-decorator';
import { InjectArguments } from 'good-injector-vue';
import { Subject } from 'rxjs';
import { map, tap, filter, finalize } from 'rxjs/operators';

import { GetSubscriptionDto } from '@/models/get-subscription-dto';
import { KeyValuePair } from '@/models/key-value-pair';
import { FormProperty } from '@/models/form-property';
import { SubscriptionService } from '@/services/subscription.service';
import { SubscriptionMapper } from '@/mappers/subscription.mapper';
import { NotAVatPayerLabel } from '@/constants/OrganizationDetailsForm.constants';

@Component({
  components: {}
})
export default class Organization extends Vue {
  private subscriptionService!: SubscriptionService;

  public properties: FormProperty[] = [];
  public isEditModalVisible = false;
  public isLoading = false;
  public showEditModalChange$ = new Subject<boolean>();
  public subscriptionData: GetSubscriptionDto | null = null;
  public isLoadingSubscriptionData = true;
  public labels: KeyValuePair[] = [
    { key: 'organizationName', value: 'ORGANIZATION LEGAL NAME' },
    { key: 'industry', value: 'INDUSTRY' },
    { key: 'tenantId', value: 'MICROSOFT TENANT ID' },
    { key: 'street', value: 'STREET' },
    { key: 'postcode', value: 'POSTAL CODE' },
    { key: 'city', value: 'CITY' },
    { key: 'countryIsoCode', value: 'COUNTRY' },
    { key: 'vatId', value: 'ORGANIZATION VAT ID', defaultValue: NotAVatPayerLabel }
  ];

  @InjectArguments()
  public mounted(subscriptionService: SubscriptionService): void {
    this.subscriptionService = subscriptionService;
    this.refreshModel();
  }

  public showEditModal(): void {
    this.showEditModalChange$.next(true);
    this.isEditModalVisible = true;
  }

  public onEditModalCancel(): void {
    this.isEditModalVisible = false;
  }

  public onEditModalAccept(): void {
    this.isEditModalVisible = false;
    this.refreshModel();
  }

  private refreshModel(): void {
    this.isLoading = true;
    this.subscriptionService
      .getSubscription()
      .pipe(
        tap(() => this.isLoadingSubscriptionData = true),
        filter((subscription) => subscription !== null),
        map((subscription) => {
          this.subscriptionData = subscription as GetSubscriptionDto ;
          const subscriptionFormValues = SubscriptionMapper.getSubscriptionFormValues(subscription);

          return Object.keys(subscriptionFormValues)
            .filter((key) => this.labels.find(label => label.key === key))
            .map((key) => {
              const labelObject = this.labels.find(label => label.key === key);
              const value = subscriptionFormValues[key] || labelObject && (labelObject.defaultValue ? labelObject.defaultValue : '');
              const label = labelObject ? labelObject.value : key;
              return { key, value, label } as FormProperty;
            });
        }),
        tap(properties => {
          this.properties = properties;
          this.isLoading = false;
        }),
        finalize(() => this.isLoadingSubscriptionData = false),
      )
      .subscribe();
  }
}
