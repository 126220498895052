







import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class Footer extends Vue {
  public get termsAndConditionsLink(): string {
    return process.env.VUE_APP_TERMS_AND_CONDITIONS_LINK;
  }

  public get privacyPolicyLink(): string {
    return process.env.VUE_APP_PRIVACY_POLICY_LINK;
  }
}
