import { SupportsInjection } from 'good-injector';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiResponse } from './../models/api-response';
import { ApiClientService } from './api-client.service';
import { AuthenticationService } from './authentication/authentication.service';

@SupportsInjection
export class EmailService extends ApiClientService {
  public constructor(authenticationService: AuthenticationService) {
    super(authenticationService, `${process.env.VUE_APP_BOOKADO_API_URI}/`);
  }

  public sendAdminConsentEmail(adminEmail: string): Observable<ApiResponse> {
    return this.post('/api/mail/adminConsent', { adminEmail }).pipe(
      map(() => ({ isSuccess: true, errorMessage: '' })),
      catchError((data) => {
        let errorMessage =
          "Email couldn't be sent. Please check email address and try again.";

        if (!!data && !!data.response && data.response.status === 400) {
          errorMessage = "Email couldn't be sent: invalid email address.";
        }

        return of({ isSuccess: false, errorMessage });
      })
    );
  }
}
