import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTimesCircle,
  faCheckCircle,
  faExclamationCircle,
  faChevronLeft,
  faChevronRight,
  faSync,
  faFileInvoice,
  faUserLock,
  faGear,
  faHome,
  faUsers,
  faBuilding,
  faListAlt,
  faCheckSquare,
  faInbox,
  faFile,
  faImage,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCheckCircle as farCheckCircle,
  faTimesCircle as farTimesCircle,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faTimesCircle,
  faCheckCircle,
  faExclamationCircle,
  faChevronLeft,
  faChevronRight,
  farCheckCircle,
  farTimesCircle,
  faSync,
  faFileInvoice,
  faUserLock,
  faGear,
  faHome,
  faUsers,
  faBuilding,
  faListAlt,
  faCheckSquare,
  faInbox,
  faFile,
  faImage
);

export default function useFontAwesome() {
  Vue.component('FontAwesomeIcon', FontAwesomeIcon);
}
