













import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class SearchInput extends Vue {
  public model = String();

  public onInput(event?: any): void {
    this.model = event && event.target ? event.target.value : String();
    this.$emit('input', this.model);
    this.$forceUpdate();
  }

  public onClear(): void {
    this.onInput();
  }
}
