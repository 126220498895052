






















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Cropper } from 'vue-advanced-cropper';
import { cloneDeep, isEqual } from 'lodash';

import { CropperChange, CropperCoordinates } from '@/models/resource-details/cropper-change';
import { ResourceImageType } from '@/models/resource-details/resource-image-type';
import { CroppedImage } from '@/models/resource-details/cropped-image';
import { ResourceImageAspectRatioProvider } from '@/services/resource-details/resource-image-aspect-ratio.provider';
import { StencilPropertiesBuilder } from '@/services/resource-details/stencil-properties-builder';
import { ResourceType } from '@/models/resource-type';

@Component({
  components: { Cropper }
})
export default class ResourcePhotoCropper extends Vue {
  @Prop() public readonly originalImage!: string;
  @Prop() public readonly coords!: CropperCoordinates;
  @Prop() public readonly currentPreviewType!: ResourceImageType;
  @Prop() public readonly showLoader!: boolean;
  @Prop() public readonly readonly!: boolean;
  @Prop() public readonly acceptsEmptyCoordinates!: boolean;
  @Prop() public readonly forceEveryEmitOfCropperChange!: boolean;
  @Prop() public readonly resourceType!: ResourceType;

  private isResettingCoords = false;
  private lastPreviewType?: ResourceImageType;
  private lastEmittedData?: CropperChange;

  public image = String();
  public isLoadingImage = false;
  public aspectRatio = 1.5;
  public $refs!: { cropper: any; };

  @Watch('originalImage', { deep: false })
  public onOriginalImageChanged(): void {
    this.updateAspectRatio();
    this.initializeCropper();
  }

  @Watch('coords', { deep: true })
  public onCoordsChanged(): void {
    if (this.coords) {
      if (this.lastPreviewType === this.currentPreviewType && !isEqual(this.$refs.cropper.getResult(this.coords), this.coords)) {
        this.setCurrentCoords();
        this.lastPreviewType = this.currentPreviewType;
      }
    } else if (this.acceptsEmptyCoordinates) {
      // it's responsible for resetting the image coordinates without preview after image change
      this.$refs.cropper.reset();
      this.image = '';
      this.initializeCropper();
      setTimeout(() => {
        this.$refs.cropper.setCoordinates({	width: 10, height: 10, left: 1, top: 1 });
        this.$refs.cropper.refresh();
      }, 200)
    }
  }

  @Watch('currentPreviewType')
  public onPreviewRequested(): void {
    this.updateAspectRatio();
    this.tryLoadCoords();
  }

  public mounted(): void {
    this.updateAspectRatio();
    this.initializeCropper();
    this.tryLoadCoords();
  }

  public get isLoaderVisible(): boolean {
    return this.showLoader || this.isLoadingImage;
  }

  public get stencilLines(): any {
    return StencilPropertiesBuilder.getStencilLinesConfig(this.readonly);
  }

  public get stencilHandlers(): any {
    return StencilPropertiesBuilder.getStencilHandlersConfig(this.readonly);
  }

  public onCropperIsReady() {
    this.$emit('cropperIsReady', true);
  }

  public cropperChange(data: CropperChange): any {
    if (this.isResettingCoords) {
      this.handleCropperChangeOnImageResetting(data);
      return;
    }

    if (!this.isResettingCoords && this.validateCoords(data)
      && (this.forceEveryEmitOfCropperChange || !isEqual(data, this.lastEmittedData))) {
      this.lastEmittedData = cloneDeep(data);
      this.emitModel(data);
    }

    if (this.isLoadingImage) {
      this.setCurrentCoords();
      this.isLoadingImage = false;
    }
  }

  private tryLoadCoords(): void {
    if (!this.isLoadingImage) {
      this.setCurrentCoords();
    }
  }

  private setCurrentCoords(): void {
    if (this.coords) {
      this.$refs.cropper.setCoordinates(this.coords);
    } else {
      this.isResettingCoords = true;
      this.$refs.cropper.reset();
    }
  }

  private initializeCropper(): void {
    this.isLoadingImage = true;
    this.image = this.originalImage;
  }

  private updateAspectRatio(): void {
    this.aspectRatio = ResourceImageAspectRatioProvider.getAspectRatio(this.currentPreviewType);
  }

  private handleCropperChangeOnImageResetting(data: CropperChange): void {
    if (this.validateCoords(data)) {
      return;
    }

    setTimeout(() => {
      const newData = this.$refs.cropper.getResult();
      this.isResettingCoords = false;
      this.cropperChange(newData);
    }, 200);
  }

  private emitModel(data: CropperChange): void {
    const model: CroppedImage = {
      type: this.currentPreviewType,
      image: data.canvas ? data.canvas.toDataURL('image/jpeg') : String(),
      coordinates: data.coordinates
    }

    if (!this.readonly) {
      this.$emit('imageCrop', model);
    }
  }

  private validateCoords(data: CropperChange): boolean {
    return data.coordinates.top >= 0 && data.coordinates.left >= 0 && data.coordinates.width > 0 && data.coordinates.height > 0;
  }
}
