import * as Features from '@/settings/feature-switch.settings';
import { BreadcrumbsRoutesNamesMapping } from '@/constants/settings.constants';
import DashboardMenuItem from '@/models/dashboard/menu-item';
import { RoutesSettings } from '@/router.constants';

export const DashboardMenuItems: DashboardMenuItem[] = [
  {
    isHidden: true,
    index: 0,
    label: 'Home',
    link: 'dashboard',
    iconClass: 'fa-home',
    submenuItems: [],
  },
  {
    index: 1,
    label: 'My Organization',
    iconClass: 'fa-users',
    submenuItems: [
      {
        index: 0,
        featureEnabledName: Features.OrganizationFeature,
        link: 'organization',
        label: 'Organization',
        iconClass: 'fa-building',
      },
      {
        index: 1,
        featureEnabledName: Features.SubscriptionFeature,
        link: 'subscription',
        label: 'Subscription',
        iconClass: 'fa-list-alt',
      },
      {
        index: 2,
        featureEnabledName: Features.ResourcesFeature,
        link: 'resources',
        label: 'Resources',
        iconClass: 'fa-check-square',
        exactActiveToCheck: '/dashboard/resource',
      },
      {
        index: 3,
        featureEnabledName: Features.TeamFeature,
        link: 'team',
        label: 'Team',
        iconClass: 'fa-users',
      },
    ],
  },
  {
    index: 2,
    label: 'Instructions',
    link: 'instructions',
    iconClass: 'fa-inbox',
    submenuItems: [],
  },
  {
    index: 3,
    label: 'Settings',
    iconClass: 'fa-gear',
    submenuItems: [
      {
        index: 0,
        link: RoutesSettings.Invoice,
        label: BreadcrumbsRoutesNamesMapping[RoutesSettings.Invoice],
        iconClass: 'fa-file-invoice',
      },
      {
        index: 1,
        link: RoutesSettings.AdministrationConsent,
        label:
          BreadcrumbsRoutesNamesMapping[RoutesSettings.AdministrationConsent],
        iconClass: 'fa-user-lock',
      },
      {
        index: 2,
        link: RoutesSettings.DefaultImageAndDescription,
        label:
          BreadcrumbsRoutesNamesMapping[
            RoutesSettings.DefaultImageAndDescription
          ],
        iconClass: 'fa-image',
      },
    ],
  },
  {
    index: 4,
    label: 'Invoices and payments',
    link: 'invoices',
    iconClass: 'fa-file',
    featureEnabledName: Features.InvoicesFeature,
    submenuItems: [],
  },
];
