export const DescriptionSidePaddingBreakpoints = {
  small: 200,
  medium: 300,
  big: 400,
};

export const DescriptionSidePaddingBreakpointsMapping = {
  [DescriptionSidePaddingBreakpoints.small]: 2,
  [DescriptionSidePaddingBreakpoints.medium]: 4,
  [DescriptionSidePaddingBreakpoints.big]: 8,
  default: 16,
};
