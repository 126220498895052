import { SupportsInjection } from 'good-injector';
import { tap } from 'rxjs/operators';
import VueRouter from 'vue-router';

import { OnboardingStatusService } from '@/services/onboarding-status.service';
import {
  FeatureSwitchSettings,
  SimpleModeFeature,
} from '@/settings/feature-switch.settings';
import { AuthenticationService } from './authentication/authentication.service';

@SupportsInjection
export class RouteResolveService {
  constructor(
    private authenticationService: AuthenticationService,
    private onboardingStatusService: OnboardingStatusService,
    private featureSwitchSettings: FeatureSwitchSettings
  ) {}

  public resolveView(router: VueRouter): void {
    const msUser = this.authenticationService.sessionUser;
    const isLoggedIn = msUser !== null && msUser !== undefined;
    const isSimpleMode =
      this.featureSwitchSettings.isFeatureEnabled(SimpleModeFeature);

    if (isSimpleMode) {
      this.resolveSimpleMode(isLoggedIn, router);
    } else {
      this.resolveOnboardingMode(isLoggedIn, router);
    }
  }

  private resolveSimpleMode(isLoggedIn: boolean, router: VueRouter): void {
    router
      .push({ path: isLoggedIn ? '/simple-dashboard' : '/simple-landing' })
      .then();
  }

  private resolveOnboardingMode(isLoggedIn: boolean, router: VueRouter): void {
    if (!isLoggedIn) {
      localStorage.removeItem(OnboardingStatusService.onboardingStatusKey);
      this.goToStep(router, 1);
      return;
    }

    this.onboardingStatusService
      .getOnboardingStatus()
      .pipe(
        tap((status) => {
          if (status.isCompleted) {
            router.push('/dashboard');
          } else {
            this.goToStep(router, status.stepIndex);
          }
        })
      )
      .subscribe();
  }

  private goToStep(router: VueRouter, stepIndex: number): void {
    router.push(`/step${stepIndex}`);
  }
}
