






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class InputTooltip extends Vue {
  @Prop(String) public readonly text!: string;
  @Prop(String) public readonly placement: string = 'bottom';
}
