import { ISubscriptionActivateModal } from '@/models/subscription/subscription-activate-modal.model';
import { ISubscriptionDeactivateModal } from '@/models/subscription/subscription-deactivate-modal.model';

export const ActivateSubscriptionModalViewModel: ISubscriptionActivateModal = {
  isVisible: false,
  isSubmitted: false,
  additionalElementData: {
    text: 'Activating subscription...',
  },
};

export const DeactivateSubscriptionModalViewModel: ISubscriptionDeactivateModal =
  {
    isVisible: false,
    isSubmitDisabled: false,
    isSubmitted: false,
    additionalElementData: {
      subscriptionDeactivationReason: null,
      deactivationReasonAdditionalInfo: '',
      text: 'Deactivating subscription...',
    },
  };
