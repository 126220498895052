



























import { Component, Vue } from 'vue-property-decorator';
import { User } from '@/models/user';
import { InjectArguments } from 'good-injector-vue';
import { AuthenticationService } from '@/services/authentication/authentication.service';

@Component({
  components: {}
})
export default class UserAccountWidget extends Vue {
  private authenticationService!: AuthenticationService;
  private isAccountOptionsExpanding = false;
  public isAccountOptionsExpanded = false;
  public user: User = {} as User;

  @InjectArguments()
  public mounted(authenticationService: AuthenticationService): void {
    this.authenticationService = authenticationService;
    this.user = this.authenticationService.sessionUser as User;
  }

  public toggleAccountOptions(): void {
    this.isAccountOptionsExpanding = true;
    this.isAccountOptionsExpanded = !this.isAccountOptionsExpanded;

    setTimeout(() => (this.isAccountOptionsExpanding = false), 200);
  }

  public onClickOutside(): void {
    if (this.isAccountOptionsExpanded && !this.isAccountOptionsExpanding) {
      this.isAccountOptionsExpanded = false;
    }
  }

  public logout(e: any): void {
    e.stopPropagation();
    e.preventDefault();
    this.toggleAccountOptions();
    this.authenticationService.logout();
  }
}
