export class GuidGenerationService {
  public static nextGuid(): string {
    let dateTime = new Date().getTime();
    const guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      (c) => {
        const randomNum = (dateTime + Math.random() * 16) % 16 | 0;
        dateTime = Math.floor(dateTime / 16);
        return (c === 'x' ? randomNum : (randomNum & 0x3) | 0x8).toString(16);
      }
    );
    return guid;
  }
}
