



























import { Component, Vue } from 'vue-property-decorator';
import { InjectArguments } from 'good-injector-vue';
import { tap } from 'rxjs/operators';

import { SubscriptionResourcesService } from '@/services/subscription-resources.service';
import { ResourceType } from '@/models/resource-type';

@Component({
  components: {}
})
export default class SimpleModeDashboard extends Vue {
  public isLoading = false;
  public isRecalculationModalVisible = false;
  public totalRooms = 0;

  private subscriptionResourcesService!: SubscriptionResourcesService;

  @InjectArguments()
  public mounted(subscriptionResourcesService: SubscriptionResourcesService): void {
    this.subscriptionResourcesService = subscriptionResourcesService;

    this.refreshRooms();
  }

  public refreshResources(): void {
    this.isRecalculationModalVisible = true;
    this.subscriptionResourcesService
      .recalculateSubscriptionResources()
      .pipe(
        tap(() => {
          this.isRecalculationModalVisible = false;
          this.refreshRooms();
        })
      )
      .subscribe();
  }

  private refreshRooms(): void {
    this.isLoading = true;

    this.subscriptionResourcesService
      .getSubscriptionResources(ResourceType.Room)
      .pipe(
        tap(rooms => {
          this.totalRooms = rooms.length;
          this.isLoading = false;
        })
      )
      .subscribe();
  }
}
