






















































































































import { ApiResponse } from '@/models/api-response';
import { ConsentStatus } from '@/models/consent-status';
import { ConsentLinkService } from '@/services/consent-link.service';
import { OnboardingStatusService } from '@/services/onboarding-status.service';
import { EmailValidator } from '@/validators/email.validator';
import { InjectArguments } from 'good-injector-vue';
import { Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { Component } from 'vue-property-decorator';
import { AdminConsentService } from '@/services/admin-consent.service';
import { EmailService } from '@/services/email.service';
import { BaseWizardStepComponent } from './BaseWizardStepComponent';
import ConsentType from '@/models/consent-type';
import { Route } from 'vue-router';

@Component({
  components: {},
})
export default class Step4 extends BaseWizardStepComponent {
  private adminConsentService!: AdminConsentService;
  private emailService!: EmailService;
  private readonly $route!: Route;

  public stepIndex = 4;
  public isAdmin = true;
  public isSendingEmail = false;
  public email = String();
  public emailSentMessage = String();
  public emailError: string | undefined = String();
  public isEmailValid = false;
  public isEmailSent = false;
  public showIsEmailSentMessage = false;
  public consentStatus: ConsentStatus = ConsentStatus.NotStarted;
  public consentLink = String();

  @InjectArguments()
  public mounted(
      onboardingStatusService: OnboardingStatusService,
      adminConsentService: AdminConsentService,
      emailService: EmailService,
      consentLinkService: ConsentLinkService
  ): void {
    this.onMounted(onboardingStatusService);

    this.adminConsentService = adminConsentService;
    this.emailService = emailService;
    this.consentLink = consentLinkService.getConsentLink();

    this.adminConsentService
        .getAdminConsentSettings()
        .pipe(
            tap((settings) => {
              switch (settings.consentType) {
                case ConsentType.Admin:
                  this.consentStatus = ConsentStatus.Completed;
                  break;
                case ConsentType.Email:
                  this.isAdmin = false;

                  if (
                      !!settings.emailRecipient &&
                      settings.emailRecipient.length > 0
                  ) {
                    this.setEmailSentMessage(settings.emailRecipient);
                    this.isEmailSent = true;
                    this.showIsEmailSentMessage = true;
                    this.toggleNonAdminTab();
                  }
                  break;
              }
            })
        )
        .subscribe();

    const statusValue: string | null = this.$route.query.status as string;

    if (statusValue) {
      this.consentStatus = parseInt(statusValue) as ConsentStatus;
    }
  }

  public get isConsentGranted(): boolean {
    return this.consentStatus === ConsentStatus.Completed;
  }

  public saveAndProceed(): void {
    if (this.isInDisabledStatus()) {
      return;
    }

    if (!this.isAdmin) {
      this.goToNextStep();
      return;
    }

    this.saveConsentSettings()
        .pipe(
            tap((result) => {
              if (result.isSuccess) {
                this.goToNextStep();
              }
            })
        )
        .subscribe();
  }

  public toggleAdminTab(): void {
    this.isAdmin = true;
  }

  public toggleNonAdminTab(): void {
    this.isAdmin = false;
  }

  public onEmailChanged(): void {
    this.isEmailValid = EmailValidator.validateEmail(this.email);
    this.showIsEmailSentMessage = false;
  }

  public sendAdminRequest(): void {
    this.isSendingEmail = true;

    this.emailService
        .sendAdminConsentEmail(this.email)
        .pipe(
            tap((response) => {
              this.emailError = response.errorMessage;
              this.isSendingEmail = false;
              this.isEmailSent = response.isSuccess;
              this.setEmailSentMessage(this.email);
              this.showIsEmailSentMessage = true;
            }),
            filter((response) => response.isSuccess),
            switchMap(() => this.saveConsentSettings())
        )
        .subscribe();
  }

  public isInDisabledStatus(): boolean {
    if (this.isAdmin) {
      return !this.isConsentGranted;
    } else {
      return !this.isEmailSent;
    }
  }

  private saveConsentSettings(): Observable<ApiResponse> {
    const consentType = this.isAdmin ? ConsentType.Admin : ConsentType.Email;
    const email = this.isAdmin ? String() : this.email;

    return this.adminConsentService.saveAdminConsentSettings(
        consentType,
        email
    );
  }

  private setEmailSentMessage(email: string): void {
    this.emailSentMessage = `Email sent (last recipient: ${email})`;
  }
}
