








import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class WizardStep extends Vue {
  @Prop(Number) public readonly index!: number;
  @Prop(String) public readonly label!: string;
  @Prop(Boolean) public readonly isSelected!: boolean;
  @Prop(Boolean) public readonly isFinished!: boolean;
}
