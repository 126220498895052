import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SupportsInjection } from 'good-injector';
import moment from 'moment';

import { SubscriptionContactDto } from '@/models/subscription/subscription-contact-dto';
import { SubscriptionContactsDto } from '@/models/subscription/subscription-contacts-dto';
import { SubscriptionModelDto } from '@/models/subscription/subscription-model-dto';
import { SubscriptionPlanDto } from '@/models/subscription/subscription-plan-dto';
import { PaymentConfigurationDto } from '@/models/payment-configuration-dto';
import { SubscriptionModel } from '@/models/subscription/subscription-model';
import { GetSubscriptionDto } from '@/models/get-subscription-dto';
import { ApiResponse } from '@/models/api-response';
import { IIndustry } from '@/models/industry.model';
import { SubscriptionDto } from '@/models/subscription/subscription-dto';
import { ApiClientService } from './api-client.service';
import { AuthenticationService } from './authentication/authentication.service';
import { NotAVatPayerLabel } from '@/constants/OrganizationDetailsForm.constants';
import { ISubscriptionResignationReason } from '@/models/subscription/subscription-resignation-reason.model';

@SupportsInjection
export class SubscriptionService extends ApiClientService {
  public constructor(authenticationService: AuthenticationService) {
    super(authenticationService, `${process.env.VUE_APP_BOOKADO_API_URI}/`);
  }

  public saveSubscription(model: SubscriptionDto): Observable<ApiResponse> {
    if (
      model.vatId === NotAVatPayerLabel ||
      (model.vatId && model.vatId.trim() === '')
    ) {
      model.vatId = null;
    }
    return this.post('subscription', model);
  }

  public getSubscription(): Observable<GetSubscriptionDto> {
    return this.get<GetSubscriptionDto>('subscription').pipe(
      catchError((error) => throwError(error))
    );
  }

  public addTeamMembers(
    model: SubscriptionContactsDto
  ): Observable<ApiResponse> {
    return this.post('subscription/contact', model).pipe(
      map(() => {
        return { isSuccess: true, errorMessage: String() };
      })
    );
  }

  public updateTeamMember(
    model: SubscriptionContactDto
  ): Observable<ApiResponse> {
    return this.patch('subscription/contact', model).pipe(
      map(() => {
        return { isSuccess: true, errorMessage: String() };
      })
    );
  }

  public removeTeamMember(email: string): Observable<ApiResponse> {
    return this.delete(`subscription/contact/${email}`).pipe(
      map(() => {
        return { isSuccess: true, errorMessage: String() };
      })
    );
  }

  public getSubscriptionPlans(): Observable<SubscriptionPlanDto[]> {
    return this.get<SubscriptionPlanDto[]>('subscription/plans');
  }

  public saveSubscriptionPlan(id: string): Observable<ApiResponse> {
    return this.post('subscription/plan', { paymentPlan: id }).pipe(
      map(() => {
        return { isSuccess: true, errorMessage: String() };
      })
    );
  }

  public getSubscriptionPlan(): Observable<SubscriptionModel | null> {
    return this.get<SubscriptionModelDto>('/api/subscription/paymentPlan').pipe(
      map((model) => {
        if (!model || model.paymentPlan === 'none') {
          return null;
        }
        return this.getSubscriptionModel(model);
      })
    );
  }

  public savePaymentConfiguration(
    paymentMethod: string,
    subscriptionType: string
  ): Observable<ApiResponse> {
    return this.post('subscription/payment', {
      paymentMethod,
      subscriptionType,
    }).pipe(
      map(() => {
        return { isSuccess: true, errorMessage: String() };
      })
    );
  }

  public getPaymentConfiguration(): Observable<PaymentConfigurationDto> {
    return this.get<PaymentConfigurationDto>('subscription/payment');
  }

  public getIndustryOptions(): Observable<IIndustry[]> {
    return this.get('subscription/industries');
  }

  public activateSubscription(): Observable<void> {
    return this.post('subscription/activate', {});
  }

  public deactivateSubscription(
    reason: ISubscriptionResignationReason,
    explanation = ''
  ): Observable<void> {
    return this.post('subscription/deactivate', {
      reason,
      explanation,
    });
  }

  public getResignationReasons(): Observable<ISubscriptionResignationReason[]> {
    return this.get('subscription/resignationReasons');
  }

  private getSubscriptionModel(data: SubscriptionModelDto): SubscriptionModel {
    const parsedEndDate = moment(data.subscriptionEndTime).toDate();
    const currency =
      data.availableResources && data.availableResources.length > 0
        ? data.availableResources[0].currency
        : 'EUR';

    return {
      isAutoRenewalEnabled: true,
      paymentMethod: 'bank_transfer',
      periodType: data.subscriptionType,
      endDate: parsedEndDate,
      planId: data.paymentPlan,
      currency,
    };
  }
}
