import { SupportsInjection } from 'good-injector';
import moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { SubscriptionPlanMapper } from '@/mappers/subscription-plan.mapper';
import { SubscriptionResourcesViewModel } from '@/models/subscription/subsription-resources-view-model';
import { SubscriptionDetails } from '@/models/subscription/subscription-details';
import { SubscriptionPlan } from '@/models/subscription/subscription-plan';
import { SubscriptionService } from './subscription.service';

@SupportsInjection
export class SubscriptionPlanService {
  public constructor(private subscriptionService: SubscriptionService) {}

  public getSubscriptionDetails(): Observable<SubscriptionDetails | null> {
    return forkJoin([
      this.subscriptionService.getSubscriptionPlan(),
      this.subscriptionService.getSubscriptionPlans(),
      this.subscriptionService.getPaymentConfiguration(),
      this.subscriptionService.getSubscription(),
    ]).pipe(
      map(([plan, plans, paymentConfiguration, subscription]) => {
        if (!plan || !plans || !paymentConfiguration || !subscription) {
          return null;
        }

        const subscriptionPlans =
          SubscriptionPlanMapper.getSubscriptionPlans(plans);
        const planDetails = subscriptionPlans.find(
          (subscriptionPlan) => subscriptionPlan.id === plan.planId
        ) as SubscriptionPlan;
        const subscriptionDetails: SubscriptionDetails = {
          planName: planDetails.name,
          paymentMethod: paymentConfiguration.paymentMethod,
          autoRenewalStatus: plan.isAutoRenewalEnabled ? 'On' : 'Off',
          periodType: plan.periodType,
          features: planDetails.features,
          startDate: this.formatDate(
            this.getStartDate(plan.endDate, plan.periodType)
          ),
          endDate: this.formatDate(plan.endDate),
          stripeCustomerId: paymentConfiguration.stripeCustomerId,
          subscriptionStatus: subscription.status,
        };

        return subscriptionDetails;
      })
    );
  }

  public getCurrentSubscriptionPlan(): Observable<
    SubscriptionPlan | undefined
  > {
    return forkJoin([
      this.subscriptionService.getSubscriptionPlan(),
      this.subscriptionService.getSubscriptionPlans(),
    ]).pipe(
      map(([plan, plans]) => {
        if (!plan) {
          return undefined;
        }
        const subscriptionPlans =
          SubscriptionPlanMapper.getSubscriptionPlans(plans);
        return subscriptionPlans.find((p) => p.id === plan.planId);
      })
    );
  }

  public getSubscriptionResources(): Observable<
    SubscriptionResourcesViewModel[]
  > {
    const resources: SubscriptionResourcesViewModel[] = [
      {
        name: 'Rooms',
        quantity: 20,
        pricePerUnit: 9,
        currency: '€',
        totalPrice: 180,
      },
      {
        name: 'Desks',
        quantity: 50,
        pricePerUnit: 3.5,
        currency: '€',
        totalPrice: 175,
      },
    ];
    return of(resources);
  }

  private getStartDate(endDate: Date, periodType: string): Date {
    switch (periodType) {
      case 'montly':
      default:
        return moment(endDate).add(-1, 'months').toDate();
    }
  }

  private formatDate(date: Date): string {
    return moment(String(date)).format('YYYY/MM/DD');
  }
}
