import { SupportsInjection } from 'good-injector-vue';
import { Observable, throwError } from 'rxjs';

import { IStripePaymentMethod } from '@/models/stripe/stripe-payment-method.model';
import { IStripeSession } from '@/models/stripe/stripe-session.model';
import { AuthenticationService } from './authentication/authentication.service';
import { ApiClientService } from './api-client.service';
import { catchError } from 'rxjs/operators';

@SupportsInjection
export class StripePaymentsService extends ApiClientService {
  private readonly authorizationToken = localStorage.getItem('accessToken');

  public constructor(authenticationService: AuthenticationService) {
    super(authenticationService, `${process.env.VUE_APP_BOOKADO_API_URI}`);
  }

  public getPaymentMethod(): Observable<IStripePaymentMethod> {
    return this.get('payment/paymentMethod', this.authorizationToken);
  }

  public createCustomer(): Observable<void> {
    return this.put('payment/customer', {}, undefined, this.authorizationToken);
  }

  public createSession(): Observable<IStripeSession> {
    return this.post('payment/session', {}, undefined, this.authorizationToken);
  }

  public getPaymentConfirmationReceiverEmail(): Observable<{ email: string }> {
    return this.get<{ email: string }>('payment/email').pipe(
      catchError((error) => throwError(error))
    );
  }

  public setPaymentConfirmationReceiverEmail(email: string): Observable<void> {
    return this.put<void>('payment/email', { email }).pipe(
      catchError((error) => throwError(error))
    );
  }
}
