import { SupportsInjection } from 'good-injector-vue';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import ConsentType from '@/models/consent-type';
import { ApiResponse } from '@/models/api-response';
import { ConsentSettings } from '@/models/consent-settings';
import { AuthenticationService } from './authentication/authentication.service';
import { OrganizationSettingsService } from './organization-settings.service';

@SupportsInjection
export class AdminConsentService extends OrganizationSettingsService {
  private consentSettingsKey = 'CONSENT_SETTINGS';

  public constructor(authenticationService: AuthenticationService) {
    super(authenticationService);
  }

  public saveAdminConsentSettings(
    consentType: ConsentType,
    emailRecipient = String()
  ): Observable<ApiResponse> {
    const settings: ConsentSettings = {
      consentType,
      emailRecipient,
    };

    return this.setSettingsByKey(this.consentSettingsKey, settings).pipe(
      map(() => ({ isSuccess: true, errorMessage: String() })),
      catchError((e) => of({ isSuccess: false, errorMessage: e.message }))
    );
  }

  public getAdminConsentSettings(): Observable<ConsentSettings> {
    return this.getSettingsByKey(this.consentSettingsKey);
  }
}
