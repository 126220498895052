import { CropperImageData } from '@/models/resource-details/cropper-data';
import {
  PreviewConfig,
  PreviewDescriptionConfig,
} from '@/models/resource-details/preview-config';
import {
  AlterResourceImageTypes,
  ResourceImageType,
} from '@/models/resource-details/resource-image-type';
import { ResourceImageAspectRatioProvider } from './resource-image-aspect-ratio.provider';

export class ResourceImagePreviewConfigBuilder {
  public static buildCropperConfig(
    data: CropperImageData | undefined
  ): PreviewConfig[] {
    return AlterResourceImageTypes.map((type) => {
      return {
        previewImage: `/preview_${type}.png`,
        type,
        cropImage: data ? data.croppedImages[type] : String(),
        userCoords: data ? data.coordinatesSets[type] : null,
        aspectRatio: ResourceImageAspectRatioProvider.getAspectRatio(type),
        previewConfig: { x: 0, y: 46, w: 174 },
      };
    });
  }

  public static buildDescriptionConfig(
    data: CropperImageData | undefined,
    description: string
  ): PreviewDescriptionConfig[] {
    return [
      {
        previewImage: '/preview_default_mobile.png',
        type: ResourceImageType.Mobile,
        cropImage: data
          ? data.croppedImages[ResourceImageType.Mobile]
          : String(),
        text: String(),
        previewConfig: { x: 0, y: 46, w: 174 },
        textAreaConfig: { x: 0, y: 0, w: 0, h: 0 },
      },
      {
        previewImage: '/preview_description_teams.png',
        type: ResourceImageType.Teams,
        cropImage: data
          ? data.croppedImages[ResourceImageType.Teams]
          : String(),
        text: description ? description : String(),
        previewConfig: { x: 0, y: 46, w: 174 },
        textAreaConfig: { x: 15, y: 200, w: 145, h: 176 },
      },
    ];
  }
}
