







import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { Observer } from 'rxjs';

import { Breadcrumb } from '@/models/breadcrumb';
import { BreadcrumbsRoutesNamesMapping, DefaultBreadcrumbs } from '@/constants/settings.constants';

@Component({
  components: {}
})
export default class Settings extends Vue {
  private _breadcrumbs: ((Observer<Breadcrumb> | Breadcrumb))[] = [...DefaultBreadcrumbs];

  @Watch('$route', { immediate: true, deep: true })
  onRouteChange(newRoute: Route): void {
    this._breadcrumbs = (newRoute.name ? [...DefaultBreadcrumbs, { name: BreadcrumbsRoutesNamesMapping[newRoute.name] }] : [...DefaultBreadcrumbs]);
  }

  // it has to be a function, not getter, because array's mutation doesn't trigger Vue's element rerender
  public getBreadcrumbs(): Breadcrumb[] {
    // JSON.parse(JSON.stringify()) is used to transform Observer type elements from @Watch into objects - solution found in https://github.com/vuejs/Discussion/issues/292
    return this._breadcrumbs.map((breadcrumb) => JSON.parse(JSON.stringify(breadcrumb)));
  }
}
