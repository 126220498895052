































































































import { FileDownloadService } from '@/services/file-download.service';
import { InjectArguments } from 'good-injector-vue';
import { tap } from 'rxjs/operators';
import { Component, Vue } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class Instructions extends Vue {
  public fileDownloadService!: FileDownloadService;

  @InjectArguments()
  public mounted(fileDownloadService: FileDownloadService): void {
    this.fileDownloadService = fileDownloadService;
  }

  public downloadPdf(fileName: string): void {
    let url =  `${process.env.VUE_APP_BASE_URL}/assets/${fileName}`

    this.fileDownloadService
      .getPdf(url)
      .pipe(
        tap(blob => {
          if (blob) {
            saveAs(blob, fileName);
          }
        })
      )
      .subscribe();
  }
}
