
































































import { Component, Vue } from 'vue-property-decorator';
import { InjectArguments } from 'good-injector-vue';
import { RouteResolveService } from '@/services/route-resolve.service';
import { AuthenticationService } from '@/services/authentication/authentication.service';
import { AuthenticationClientType } from '@/services/authentication/authentication-client-type';
import VueRouter from 'vue-router';

@Component({
  components: {}
})
export default class SimpleModeLander extends Vue {
  public adminConsent = false;
  public termsConsent = false;
  public isLoggedIn = false;

  private authenticationService!: AuthenticationService;
  private readonly $router!: VueRouter;

  @InjectArguments()
  public mounted(
    authenticationService: AuthenticationService,
    routeResolveService: RouteResolveService
  ): void {

    this.authenticationService = authenticationService;

    const msUser = this.authenticationService.sessionUser;

    this.isLoggedIn = msUser !== null && msUser !== undefined;

    if (this.isLoggedIn && this.isConsentGranted) {
      routeResolveService.resolveView(this.$router);
    }
  }

  public get isConsentGranted(): boolean {
    return this.adminConsent && this.termsConsent;
  }

  public onContinue(): void {
    // go to simple dashboard page
    this.$router.push('');
  }

  public loginMicrosoft(): void {
    this.authenticationService.login(AuthenticationClientType.Microsoft);
  }

  public get termsAndConditionsLink(): string {
    return process.env.VUE_APP_TERMS_AND_CONDITIONS_LINK;
  }

  public get privacyPolicyLink(): string {
    return process.env.VUE_APP_PRIVACY_POLICY_LINK;
  }
}
