import { User } from './user';

export class GoogleUser implements User {
  constructor(name: string, surname: string, email: string, expiresAt: number) {
    this.name = name;
    this.surname = surname;
    this.email = email;
    this.expiresAt = expiresAt;
  }

  public tenantId?: string;
  public name?: string;
  public surname?: string;
  public email?: string;
  public phoneNumber?: string;
  public expiresAt: number;

  public isExpired(): boolean {
    return new Date().getTime() > this.expiresAt;
  }
}
