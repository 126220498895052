















import { Component, Vue } from 'vue-property-decorator';
import { tap } from 'rxjs/operators';
import { InvoicesService } from '@/services/invoices.service';
import { InjectArguments } from 'good-injector-vue';
import { Route } from 'vue-router';

@Component({
  components: {}
})
export default class Invoices extends Vue {
  private invoicesService!: InvoicesService;
  private readonly $route!: Route;

  public invoices: any[] = [];
  public isLoading = false;
  public currentPageNumber = '1';

  @InjectArguments()
  public mounted(invoicesService: InvoicesService): void {
    this.invoicesService = invoicesService;
    if (this.$route.params.currentPageNumber) {
      this.currentPageNumber = this.$route.params.currentPageNumber;
    }
    this.refreshModel();
  }

  private refreshModel(): void {
    this.isLoading = true;
    this.invoicesService.getInvoiceViews()
      .pipe(
        tap(invoices => {
          this.invoices = invoices;
          this.isLoading = false;
        })
      )
      .subscribe();
  }
}
