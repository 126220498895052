import { capitalizeFirstLetter } from '@/lib/utils';
import { AxiosResponse, ResponseType } from 'axios';
import { SupportsInjection } from 'good-injector';
import { from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ResourceTypeDto } from '@/models/resource-type-dto';
import { MarkersRequest } from '@/models/markers-request';
import { ResourceType } from '@/models/resource-type';
import { ResourceDto } from '@/models/resource-dto';
import { Resource } from '@/models/resource';
import { ApiClientService } from './api-client.service';
import { AuthenticationService } from './authentication/authentication.service';

@SupportsInjection
export class SubscriptionResourcesService extends ApiClientService {
  public constructor(authenticationService: AuthenticationService) {
    super(authenticationService, `${process.env.VUE_APP_BOOKADO_API_URI}/`);
  }

  public getSubscriptionResources(type: ResourceType): Observable<Resource[]> {
    return this.get<ResourceDto[]>(`api/resources?type=${type}`).pipe(
      map((data) => data.map((resource) => this.toResource(resource))),
      catchError(() => of([]))
    );
  }

  public getSubscriptionAvailableResources(
    type: ResourceType
  ): Observable<Resource[]> {
    return this.get<ResourceDto[]>(`api/resources/available?type=${type}`).pipe(
      map((data) => data.map((resource) => this.toResource(resource))),
      catchError(() => of([]))
    );
  }

  public getResourceTypes(): Observable<ResourceTypeDto[]> {
    return this.get<ResourceTypeDto[]>('/subscription/resourceTypeNames').pipe(
      map((items) =>
        items
          .map((i) => ({ ...i, name: capitalizeFirstLetter(i.name) }))
          .filter((i) => i.visible)
      )
    );
  }

  public recalculateSubscriptionResources(): Observable<boolean> {
    return this.post('/api/resources/cache/refresh', null).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public getSubscriptionResource(
    resourceId: string
  ): Observable<Resource | null> {
    return this.get<ResourceDto>(`api/resources/${resourceId}`).pipe(
      map((data) => this.toResource(data)),
      catchError(() => of(null))
    );
  }

  public setSubscriptionResourceVisibility(
    resourceId: string,
    visible: boolean
  ): Observable<boolean> {
    return this.post(
      `/api/resources/${resourceId}/visibility`,
      visible,
      'application/json'
    ).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public getMarkersPdf(request: MarkersRequest): Observable<Blob | null> {
    const responseType: ResponseType = 'blob';
    const options = {
      ...this.getClientOptions(),
      responseType,
    };

    return from(this.client.post<Blob>('/api/markers', request, options)).pipe(
      map((response: AxiosResponse<Blob>) => response.data),
      catchError(() => of(null))
    );
  }

  private toResource(model: ResourceDto): Resource {
    return {
      id: model.id,
      isActive: false,
      isSelected: false,
      name: model.name,
      email: String(),
      location: model.location,
      capacity: model.capacity,
      visible: model.visible,
      type: model.type,
    } as Resource;
  }
}
