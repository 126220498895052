





















import { Component, Prop, Vue } from 'vue-property-decorator';

import { PreviewConfig } from '@/models/resource-details/preview-config';
import { ResourceImageType } from '@/models/resource-details/resource-image-type';

@Component({
  components: {},
})
export default class ResourcePhotoPreviewsList extends Vue {
  @Prop() public readonly previewsConfigs!: PreviewConfig[];
  @Prop() public readonly currentPreviewType?: ResourceImageType;
  @Prop() public readonly isLoadingPreview!: boolean;

  public onPreviewClick(type: ResourceImageType): any {
    this.$emit('previewTypeChange', type);
  }
}
