



































import { Component, Prop, VModel, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class ConfirmationModal extends Vue {
  @VModel() additionalElementVModel!: any;

  @Prop(Boolean) public readonly isVisible!: boolean;
  @Prop(Boolean) public readonly isDiscardButtonShowed!: boolean;
  @Prop(Boolean) public readonly isCancelButtonHidden!: boolean;
  @Prop(Boolean) public readonly isSubmitDisabled!: boolean;
  @Prop(String) public readonly heading?: string;
  @Prop(String) public readonly primaryText?: string;
  @Prop(String) public readonly secondaryText?: string;
  @Prop(String) public readonly customConfirmButtonLabel?: string;
  @Prop(Function) public readonly additionalElement!: Vue;

  created() {
    if (this.additionalElement) {
      Vue.component('AdditionalElement', this.additionalElement);
    }
  }

  @Watch('additionalElement')
  public additionalElementUpdated(): void {
    Vue.component('AdditionalElement', this.additionalElement);
  }

  public onCancel(): void {
    this.$emit('cancel');
  }

  public onDiscard(): void {
    this.$emit('discard');
  }

  public onConfirm(): void {
    this.$emit('confirm');
  }
}
