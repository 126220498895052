








import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class BackButton extends Vue {
  @Prop() public readonly path?: string;
}
