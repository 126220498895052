





















import { Component, Prop, Vue } from 'vue-property-decorator';
import { PreviewDescriptionConfig } from '@/models/resource-details/preview-config';
import { ResourceImageType } from '@/models/resource-details/resource-image-type';

@Component({
  components: {},
})
export default class ResourceDescriptionPreviewsList extends Vue {
  @Prop() public readonly previewsConfigs!: PreviewDescriptionConfig[];
  @Prop() public readonly currentPreviewType?: ResourceImageType;

  public onPreviewClick(type: ResourceImageType) {
    this.$emit('previewTypeChange', type);
  }
}
