























import { Component, Vue, Prop } from 'vue-property-decorator';
import { SubscriptionPlan } from '../../models/subscription/subscription-plan';

@Component({})
export default class WizardStep extends Vue {
    @Prop() public plan!: SubscriptionPlan;

    public onSelectPlan(): void {
        this.$emit('select');
    }
}
