



















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { TeamMember } from '@/models/team-member';
import { KeyValuePair } from '../../models/key-value-pair';
import { EmailValidator } from '@/validators/email.validator';
import { PhoneNumberValidator } from '@/validators/phone-number.validator';

@Component({})
export default class TeamMemberEditForm extends Vue {
  @Prop() public readonly model!: TeamMember;
  @Prop() public readonly editMode!: boolean;
  @Prop() public readonly validate?: (teamMember: TeamMember) => KeyValuePair[];
  @Prop() public readonly isChangingPrimaryUserEnabled!: boolean;

  public isFormValid = true;
  public errors: any = {};
  public emailErrorDetails!: string;
  public setAsPrimaryUser = false;

  private maxNumberOfCharacters = 60;
  private maxNumberOfCharactersInPhoneNumber = 20;

  constructor() {
    super();
  }

  public checkForm(): void {
    const properties = [ 
      { name: 'name', isEmail: false, isPhone: false, charactersLimit: this.maxNumberOfCharacters }, 
      { name: 'surname', isEmail: false, isPhone: false, charactersLimit: this.maxNumberOfCharacters }, 
      { name: 'email', isEmail: true, isPhone: false, charactersLimit: this.maxNumberOfCharacters }, 
      { name: 'phoneNumber', isEmail: false, isPhone: true, charactersLimit: this.maxNumberOfCharactersInPhoneNumber }
    ];

    const errors: any = {};
    const additionalErrors = this.validate ? this.validate(this.model) : [];

    this.isFormValid = true;

    properties.forEach(property => {
      const value: string = (this.model as any)[property.name];
      const validationError = additionalErrors.find(e => e.key === property.name);

      if (value === null || value === undefined || value.length === 0) {
        if (property.name === 'phoneNumber' && !this.model.isPrimaryContact && !this.setAsPrimaryUser) {
        } else {
          errors[property.name] = 'Field is required';
          this.isFormValid = false;
        }
      } else if (value.length > property.charactersLimit) {
        errors[property.name] = 'Maximum number of characters is ' + property.charactersLimit;
        this.isFormValid = false;
      } else if (property.isEmail && !EmailValidator.validateEmail(value)) {
        errors[property.name] = 'Valid email address is required';
        this.isFormValid = false;
      } else if (property.isPhone && (this.model.isPrimaryContact || this.setAsPrimaryUser || (value !== undefined && value !== null  && value.length > 0)) && !PhoneNumberValidator.validatePhoneNumber(value)) {
        errors[property.name] = 'Valid phone number is required';
        this.isFormValid = false;
      }

      if (!errors[property.name] && validationError) {
        errors[property.name] = validationError.value;
        this.isFormValid = false;
      }
    });
    
    this.errors = errors;
    
    if (this.isFormValid) {
      this.setNullForPhoneNumberIfNeeded();
      this.$emit('input', { model: this.model, setAsPrimaryUser: this.model.isPrimaryContact ? this.model.isPrimaryContact : this.setAsPrimaryUser });
      this.setAsPrimaryUser = false;
    }
  }

  public cancelForm(): void {
    this.errors = {};
    this.$emit('cancel');
  }

  public getCounter(currentNumberOfCharacters: string): string {
    return `${currentNumberOfCharacters ? currentNumberOfCharacters.length : 0} / ${this.maxNumberOfCharacters}`
  }

  private setNullForPhoneNumberIfNeeded() {
    const phoneNumberPropertyName = 'phoneNumber';
    const value: string = (this.model as any)[phoneNumberPropertyName];

    if (value !== null && (value === undefined || value.length === 0)) {
      (this.model as any)[phoneNumberPropertyName] = null;
    }
  }
}
