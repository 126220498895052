

































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Subject } from 'rxjs';

import { GetSubscriptionDto } from '@/models/get-subscription-dto';
import { IOrganizationFormValidity } from '@/models/organization-form-validity.model';

@Component({})
export default class OrganizationDetailsEditModal extends Vue {
  @Prop(Boolean) public readonly visible!: boolean;
  @Prop(Subject) public readonly showEditModalChange$!: Subject<boolean>;
  @Prop(Object) public readonly subscriptionData!: GetSubscriptionDto;

  public isSaving = false;
  public organizationFormValidity: IOrganizationFormValidity = {
    isEmpty: false
  };

  public readonly submit$: Subject<boolean> = new Subject<boolean>();

  @Watch('visible')
  updateVisible() {
    if (!this.visible) {
      this.isSaving = false;
    }
  }

  public onSave(): void {
    this.save();
  }

  public onCancel(): void {
    this.$emit('cancel');
  }

  public onFormSubmitted(isSuccess: boolean): void {
    if (isSuccess) {
      this.$emit('accept');
    }
  }

  public onValidationError(): void {
    this.isSaving = false;
  }

  public onLoadingChanged(isLoading: boolean): void {
    this.isSaving = isLoading;
  }

  private save() {
    this.isSaving = true;
    this.submit$.next(true);
  }
}
