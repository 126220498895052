































































import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { InjectArguments } from 'good-injector-vue';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { forkJoin, of, Subject } from 'rxjs';

import { SubscriptionResourceImagesService } from '@/services/subscription-resource-images.service';
import { SubscriptionResourcesService } from '@/services/subscription-resources.service';
import { CachedResourcesService } from '@/services/cached-resources.service';
import { ResourceImagesDto } from '@/models/resource-details/resource-images-dto';
import { ToastMessage, ToastSeverity } from '@/models/toast-message';
import { Resource } from '@/models/resource';
import { ResourcePhotoSaveData } from '@/models/resource-photo-save-data.model';
import { ResourceType } from '@/models/resource-type';

@Component({
  components: {},
})
export default class ResourceDetails extends Vue {
  public isLoading = true;
  public breadcrumbPaths = [
    { name: 'My Organization', path: '/dashboard' },
    { name: 'Resources', path: '/dashboard/resources/' },
  ];
  public backButtonPath = '';
  public tabLabels = ['Photo', 'Description' /*"Custom Attributes"*/];
  public currentTabIndex = 0;
  public resourceImages: ResourceImagesDto = {} as ResourceImagesDto;
  public resource: Resource = {} as Resource;
  public toastMessagesSubject: Subject<ToastMessage> = new Subject<ToastMessage>();
  public isDefaultImageActive: boolean | null = null;
  public resourceType: ResourceType = String() as ResourceType;

  private resourceId = String();
  private subscriptionResourceImagesService: SubscriptionResourceImagesService = {} as SubscriptionResourceImagesService;
  private currentPageNumber = '';
  private previousId  = '';
  private nextId = '';
  private pageSize: number = parseInt(process.env.VUE_APP_GRID_PAGE_SIZE);
  private initialIsDefaultImageActive = false;
  private noCustomImageOnInit = false;
  private readonly $route!: Route;

  @InjectArguments()
  public mounted(
    subscriptionResourcesService: SubscriptionResourcesService,
    subscriptionResourceImagesService: SubscriptionResourceImagesService,
    cachedResourcesService: CachedResourcesService
  ): void {
    this.resourceId = this.$route.params.id;
    if (this.$route.params.currentPageNumber) {
      this.currentPageNumber = this.$route.params.currentPageNumber;
    }
    this.isLoading = true;
    this.subscriptionResourceImagesService = subscriptionResourceImagesService;

    forkJoin([
      subscriptionResourcesService.getSubscriptionResource(this.resourceId),
      this.loadResourceImages(),
    ])
      .pipe(
        tap(([resource]) => {
          this.resource = resource as Resource;
          this.isLoading = false;
          const resourceList: Resource[] = cachedResourcesService.getCachedResources(
            this.resource.type
          );
          this.resourceType = this.resource.type;
          const resourceIndex = resourceList.findIndex(
            ({ id }) => id === this.resourceId
          );
          this.previousId = resourceIndex > 0 ? resourceList[resourceIndex - 1].id : '';
          this.nextId = resourceList.length - 1 >= resourceIndex ? resourceList[resourceIndex + 1].id : '';
          this.currentPageNumber = resourceIndex > 0 ? Math.ceil((resourceIndex + 1) / this.pageSize).toString() : '1';
          
          this.breadcrumbPaths[1].path += `${this.resource.type}/${this.currentPageNumber}`;
          this.breadcrumbPaths.push({
            name: `${this.resource.name} Details`,
            path: '',
          });
          this.backButtonPath = this.breadcrumbPaths[1].path;
        })
      )
      .subscribe();
  }

  private loadResourceImages() {
    return this.subscriptionResourceImagesService
      .getSubscriptionResourceImages(this.resourceId)
      .pipe(tap((resourceImages) => {
        this.resourceImages = resourceImages;
        const firstNonDefaultImage = resourceImages.photos.find((resourceImage) => !resourceImage.default);
        if (firstNonDefaultImage) {
          this.isDefaultImageActive = !firstNonDefaultImage.active;
          this.initialIsDefaultImageActive = this.isDefaultImageActive;
        } else {
          this.isDefaultImageActive = this.initialIsDefaultImageActive = this.noCustomImageOnInit = true;
        }
      }));
  }

  public onTabChanged(index: number): void {
    this.currentTabIndex = index;
  }

  public onSaveImage(saveImageData: ResourcePhotoSaveData): void {
    const { customImageData, setAsDefaultPhoto, setAsActive } = saveImageData;

    if (customImageData) {
      this.isLoading = true;
      let action = setAsDefaultPhoto
        ? this.subscriptionResourceImagesService.saveDefaultImage(this.resource.type, customImageData)
        : this.subscriptionResourceImagesService.saveImage(this.resource.id, customImageData)

      const successMessage = setAsDefaultPhoto ? 'Default image successfully saved.' : 'Images successfully saved.';
      const failMessage = setAsDefaultPhoto ? 'Couldn\'t save default image. Please try again later.' : 'Couldn\'t save images. Please try again later.';

      action.pipe(
        take(1),
        switchMap((result) => {
          return (setAsActive === this.initialIsDefaultImageActive || this.noCustomImageOnInit
            ? this.subscriptionResourceImagesService.setResourcePhotosStatus(this.resource.id, setAsActive)
            : of({}))
            .pipe(
              map(() => result)
            )
        }),
        switchMap((result) => {
          if (result.isSuccess) {
            return this.loadResourceImages()
              .pipe(
                tap(() => {
                  this.toastMessagesSubject.next(
                    new ToastMessage(successMessage)
                  );
                  this.isLoading = false;
                }),
              );
          } else {
            this.toastMessagesSubject.next(
              new ToastMessage(failMessage, ToastSeverity.Error)
            );
            this.isLoading = false;
            return of({});
          }
        })
      )
        .subscribe();
    }
  }

  public onSaveDescription(description: string): void {
    this.isLoading = true;

    this.subscriptionResourceImagesService
      .saveDescription(this.resource.id, description)
      .pipe(
        tap((result) => {
          if (result.isSuccess) {
            this.loadResourceImages()
              .pipe(
                tap(() => {
                  this.toastMessagesSubject.next(
                    new ToastMessage('Description successfully saved.')
                  );
                  this.isLoading = false;
                })
              )
              .subscribe();
          } else {
            this.toastMessagesSubject.next(
              new ToastMessage(
                "Couldn't save description. Please try again later.",
                ToastSeverity.Error
              )
            );
            this.isLoading = false;
          }
        })
      )
      .subscribe();
  }
}
