






































import { CropperData, CropperImageData } from '@/models/resource-details/cropper-data';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { ResourceImagePreviewConfigBuilder } from '@/services/resource-details/resource-image-preview-config-builder';
import { PreviewDescriptionConfig } from '@/models/resource-details/preview-config';
import { ResourceImagesDto } from '@/models/resource-details/resource-images-dto';

@Component({
  components: {},
})
export default class ResourceDescription extends Vue {
  @Prop() public readonly model?: ResourceImagesDto;
  @Prop() public readonly isNoPreviewMode?: boolean;
  @Prop() public readonly isImagePreviewSize?: boolean;
  @Prop(Boolean) public readonly isDefaultImageActive?: boolean;

  private initialDescription = String();

  public description = String();
  public isPristine = true;
  public previewConfigs: PreviewDescriptionConfig[] = [];

  public get descriptionMaxLength(): number {
    return Number(process.env.VUE_APP_RESOURCE_DESCRIPTION_MAX_LENGTH);
  }

  public mounted(): void {
    this.description = this.initialDescription = this.getModelDescription();
    this.buildConfigs();
  }

  @Watch('model', { deep: true })
  onModelChanged(): void {
    this.description = this.initialDescription = this.getModelDescription();
    this.buildConfigs();
  }

  @Watch('isDefaultImageActive')
  public isDefaultImageActiveChange(): void {
    this.buildConfigs();
  }

  public onDescriptionChanged(): void {
    this.buildConfigs();
    this.isPristine = false;
    this.$emit('descriptionChange', this.description);
  }

  public buildConfigs(): void {
    if (this.model) {
      const cropperData = new CropperData(this.model);
      const imageData: CropperImageData | undefined = this.isDefaultImageActive ? cropperData.defaultImage : cropperData.customImage;
      this.previewConfigs = ResourceImagePreviewConfigBuilder.buildDescriptionConfig(imageData, this.description);
    }
  }

  public onSaveDescriptionClicked(): void {
    this.onConfirm();
  }

  public onCancelDescriptionClicked(): void {
    this.description = this.initialDescription;
    this.onDescriptionChanged();
    this.isPristine = true;
  }

  public onConfirm(): void {
    this.$emit('submit', this.description);
    this.initialDescription = this.description;
  }

  private getModelDescription(): string {
    return this.model ? this.model.description : String();
  }
}
