








































import { Component, Vue } from 'vue-property-decorator';
import { InjectArguments } from 'good-injector-vue';
import { tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

import { ResourceTypeDto } from '@/models/resource-type-dto';
import { ResourceType } from '@/models/resource-type';
import { Resource } from '@/models/resource';
import { SubscriptionResourcesService } from '@/services/subscription-resources.service';
import { CachedResourcesService } from '@/services/cached-resources.service';
import { capitalizeFirstLetter } from '@/lib/utils';
import VueRouter, { Route } from 'vue-router';

@Component({
  components: {}
})
export default class Resources extends Vue {
  private subscriptionResourcesService!: SubscriptionResourcesService;
  private cachedResourcesService!: CachedResourcesService;
  private readonly $router!: VueRouter;
  private readonly $route!: Route;

  public resourceTypes: ResourceTypeDto[] = [];
  public resources: ResourcesDictionary = {} as ResourcesDictionary;
  public activeResourceType: ResourceType = ResourceType.Room;
  public isRecalculationModalVisible = false;
  public isLoading = false;
  public currentPageNumber = '1';

  @InjectArguments()
  public mounted(subscriptionResourcesService: SubscriptionResourcesService, cachedResourcesService: CachedResourcesService): void {
    this.activeResourceType = this.$route.params.resourceType ? this.$route.params.resourceType as ResourceType : ResourceType.Room;;
    if (this.$route.params.currentPageNumber) {
      this.currentPageNumber = this.$route.params.currentPageNumber;
    }
    this.subscriptionResourcesService = subscriptionResourcesService;
    this.cachedResourcesService = cachedResourcesService;
    this.isLoading = true;

    this.subscriptionResourcesService
      .getResourceTypes()
      .pipe(
        tap(resourceTypes => {
          this.resourceTypes = resourceTypes;
          if (this.activeResourceType.length === 0) {
            this.activeResourceType = this.resourceTypes[0].type;
          }
          this.refreshModel(false);
        })
      )
      .subscribe();
  }

  public onResourceTypeChanged(activeResourceType: ResourceType) {
    this.$router.push(`/dashboard/resources/${activeResourceType}`);
    this.activeResourceType = activeResourceType;
    this.refreshModel();
  }

  public onDataRefresh(): void {
    for (let i = 0; i < this.resourceTypes.length; i++) {
        this.cachedResourcesService.removeCachedResources(this.resourceTypes[i].type);
    }

    this.isRecalculationModalVisible = true;
    this.subscriptionResourcesService
      .recalculateSubscriptionResources()
      .pipe(
        tap(() => {
          this.isRecalculationModalVisible = false;
          this.refreshModel(false);
        })
      )
      .subscribe();
  }

  private refreshModel(refreshOnlyActiveResourcesLabel = true): void {
    this.isLoading = true;
    let cachedResources: any = null;
    if (refreshOnlyActiveResourcesLabel) {
      cachedResources = this.cachedResourcesService.getCachedResources(this.activeResourceType);
      
      if (cachedResources === null) {
        this.subscriptionResourcesService
        .getSubscriptionResources(this.activeResourceType)
        .pipe(
          tap(resources => {
            this.resources[this.activeResourceType] = resources;
            this.cachedResourcesService.setCachedResources(this.activeResourceType, resources);
            this.finishResourcesLoading();
          })
        )
        .subscribe();
      } else {
        this.resources[this.activeResourceType] = cachedResources;
        this.finishResourcesLoading();
      }
    } else {
      let getSubscriptionResources = [];
      let cachedResourcesToReturn = [];
      
      for (let i = 0; i < this.resourceTypes.length; i++) {
        cachedResources = this.cachedResourcesService.getCachedResources(this.resourceTypes[i].type);

        if (cachedResources === null) {
          getSubscriptionResources.push(this.subscriptionResourcesService.getSubscriptionResources(this.resourceTypes[i].type));
        } else {
          cachedResourcesToReturn.push({ resources: cachedResources, resourceType: this.resourceTypes[i].type });
        }
      }

      for (let i = 0; i < cachedResourcesToReturn.length; i++) {
        this.resources[cachedResourcesToReturn[i].resourceType] = cachedResourcesToReturn[i].resources;
      }

      if (getSubscriptionResources.length > 0) {
        forkJoin(getSubscriptionResources).pipe(
          tap(results => {
            for (let i = 0; i < results.length; i++) {
              this.resources[this.resourceTypes[i].type] = results[i];
              this.cachedResourcesService.setCachedResources(this.resourceTypes[i].type, results[i]);
            }
            this.finishResourcesLoading();
          })  
        ).subscribe();
      } else {
        this.finishResourcesLoading();
      }
    }
  }

  private updateResourceTypeLabels() {
    this.resourceTypes = this.resourceTypes.map(item => {
      const resources = this.resources[item.type] || [];
      const activeCount = resources.filter(r => r.visible).length;
      const resourceTypeLabel = capitalizeFirstLetter(item.type);
      
      return {
        ...item,
        name: `${resourceTypeLabel} (${activeCount}/${resources.length})`
      };
    });
  }

  private finishResourcesLoading(): void {
    this.updateResourceTypeLabels();
    this.isLoading = false;
  }
}

interface ResourcesDictionary {
  [index: string]: Resource[];
}
