



















































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { InjectArguments } from 'good-injector-vue';
import { catchError, finalize, mergeMap, take, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { cloneDeep } from 'lodash';

import * as Features from '@/settings/feature-switch.settings';
import { SubscriptionPlanService } from '@/services/subscription-plan.service';
import { StripePaymentsService } from '@/services/stripe-payments.service';
import { SubscriptionService } from '@/services/subscription.service';
import { SubscriptionDetails } from '@/models/subscription/subscription-details';
import { PaymentProcessingNetworkType } from '@/models/stripe/payment-processing-network.model';
import { StripeSetupIntentStatus } from '@/models/stripe/stripe-setup-intent-status.model';
import { SubscriptionStatus } from '@/models/subscription/subscription-status.model';
import { StripeCheckoutContext } from '@/models/stripe/stripe-checkout-context.model';
import { IStripePaymentMethod } from '@/models/stripe/stripe-payment-method.model';
import { SubscriptionType } from '@/models/subscription/subscription-type';
import { PaymentMethod } from '@/models/payment-method';
import {
  SubscriptionChangeStatusModalLabels
} from '@/constants/subscription/subscription-change-status-modal-labels.constants';
import SubscriptionDeactivationReasonSelector from './SubscriptionDeactivationReasonSelector.vue';
import {
  ActivateSubscriptionModalViewModel,
  DeactivateSubscriptionModalViewModel
} from '@/constants/subscription/subscription-change-status-modal-default-values.constants';
import { SubscriptionStatusLabels } from '@/constants/subscription/subscription-status-labels.constants';
import { ISubscriptionResignationReason } from '@/models/subscription/subscription-resignation-reason.model';
import { GetSubscriptionDto } from '@/models/get-subscription-dto';

@Component({
  components: {}
})
export default class Subscription extends Vue {
  private readonly $route!: Route;

  private subscriptionPlanService!: SubscriptionPlanService;
  private subscriptionService!: SubscriptionService;
  private stripePaymentsService!: StripePaymentsService;
  private savedSubscriptionDetails: SubscriptionDetails | null = null;

  public readonly SubscriptionStatus: typeof SubscriptionStatus = SubscriptionStatus;
  public readonly subscriptionChangeStatusModalLabels = SubscriptionChangeStatusModalLabels;
  public readonly subscriptionDeactivationReasonSelectorComponent = SubscriptionDeactivationReasonSelector;

  public Features = Features;
  public subscriptionDetails: SubscriptionDetails | null = {} as SubscriptionDetails;
  public isSubscriptionPlansModalVisible = false;
  public editPlan = false;
  public setupIntentStatus: StripeSetupIntentStatus | null = null;
  public isLoading = false;
  public stripeCheckoutContext: StripeCheckoutContext = StripeCheckoutContext.Subscription;
  public cardLast4Digits = '';
  public cardExpirationDate = '';
  public cardBillingName = '';
  public cardPaymentProcessingNetwork?: PaymentProcessingNetworkType;
  public isLoadingCardStatus = true;
  public activateSubscriptionModalViewModel = cloneDeep(ActivateSubscriptionModalViewModel);
  public deactivateSubscriptionModalViewModel = cloneDeep(DeactivateSubscriptionModalViewModel);
  public activateIsSuccessfullySubmitted: boolean | null = null;
  public deactivateIsSuccessfullySubmitted: boolean | null = null;
  public SubscriptionStatusLabels: typeof SubscriptionStatusLabels = SubscriptionStatusLabels;
  public tenantId = '';
  public paymentConfirmationReceiverEmail = '';
  public initialPaymentConfirmationReceiverEmail = '';

  @InjectArguments()
  public mounted(subscriptionPlanService: SubscriptionPlanService, subscriptionService: SubscriptionService, stripePaymentsService: StripePaymentsService): void {
    this.openEditPlanIfRouteParamMatch();
    this.subscriptionPlanService = subscriptionPlanService;
    this.subscriptionService = subscriptionService;
    this.stripePaymentsService = stripePaymentsService;
    this.refreshModel();
    this.getOrganizationId();
    this.getPaymentConfirmationReceiverEmail();
  }

  @Watch('deactivateSubscriptionModalViewModel', { deep: true })
  public updateDeactivateSubscriptionModalViewModel() {
    // Check isn't textarea reason empty, for these 2 specific options (MissingCrucialFunctionality & Other) it's required
    const { subscriptionDeactivationReason, deactivationReasonAdditionalInfo } = this.deactivateSubscriptionModalViewModel.additionalElementData;
    this.deactivateSubscriptionModalViewModel.isSubmitDisabled = !subscriptionDeactivationReason
      || subscriptionDeactivationReason && subscriptionDeactivationReason.explanationPossible && !(deactivationReasonAdditionalInfo.trim())
  }

  public getEditCreditCardLabel() {
    return `${this.cardLast4Digits ? 'Change' : 'Add'} Credit Card`;
  }

  public onActivateSubscription() {
    this.activateIsSuccessfullySubmitted = null;
    this.activateSubscriptionModalViewModel = cloneDeep(ActivateSubscriptionModalViewModel);
    this.activateSubscriptionModalViewModel.isVisible = true;
  }

  public onDeactivateSubscription() {
    this.deactivateIsSuccessfullySubmitted = null;
    this.deactivateSubscriptionModalViewModel = cloneDeep(DeactivateSubscriptionModalViewModel);
    this.deactivateSubscriptionModalViewModel.isVisible = true;
  }

  public onSubscriptionPlansModalAccept(): void {
    this.isSubscriptionPlansModalVisible = false;
    this.refreshModel();
  }

  public onSubscriptionPlansModalCancel(): void {
    this.isSubscriptionPlansModalVisible = false;
  }

  public onConfirmActivatePaidService() {
    this.subscriptionService.activateSubscription()
      .pipe(
        take(1),
        catchError(() => {
          this.activateIsSuccessfullySubmitted = false;
          return EMPTY;
        })
      )
      .subscribe(() => this.activateIsSuccessfullySubmitted = true);
  }

  public onConfirmDeactivateSubscription() {
    const { subscriptionDeactivationReason, deactivationReasonAdditionalInfo } = this.deactivateSubscriptionModalViewModel.additionalElementData
    this.subscriptionService.deactivateSubscription(subscriptionDeactivationReason as ISubscriptionResignationReason, deactivationReasonAdditionalInfo)
      .pipe(
        take(1),
        catchError(() => {
          this.deactivateIsSuccessfullySubmitted = false;
          return EMPTY;
        })
      )
      .subscribe(() => this.deactivateIsSuccessfullySubmitted = true);
  }

  private getRouteEditPlanParam(): 'true' | null {
    return (this.$route.params.editPlan || this.$route.query.editPlan) as 'true' | null;
  }

  private openEditPlanIfRouteParamMatch(): void {
    if (this.getRouteEditPlanParam()) {
      this.editPlan = (this.getRouteEditPlanParam() === 'true');
    }
  }

  private getOrganizationId(): void {
    this.subscriptionService.getSubscription()
        .pipe(take(1))
        .subscribe(({ tenantId }: GetSubscriptionDto) => {
          this.tenantId = tenantId;
          this.openEditPlanIfRouteParamMatch();
        });
  }

  private getPaymentConfirmationReceiverEmail(): void {
    this.stripePaymentsService.getPaymentConfirmationReceiverEmail()
        .pipe(take(1))
        .subscribe(({ email }) => {
          this.paymentConfirmationReceiverEmail = this.initialPaymentConfirmationReceiverEmail = email;
        });
  }

  private setPaymentConfirmationReceiverEmail(email: string): void {
    this.stripePaymentsService.setPaymentConfirmationReceiverEmail(email)
        .pipe(take(1), catchError(() => {
          this.paymentConfirmationReceiverEmail = this.initialPaymentConfirmationReceiverEmail;
          return EMPTY;
        }))
        .subscribe(() => {
          this.initialPaymentConfirmationReceiverEmail = this.paymentConfirmationReceiverEmail;
        });
  }

  private refreshModel(): void {
    this.isLoading = true;
    this.subscriptionPlanService
      .getSubscriptionDetails()
      .pipe(
        take(1),
        tap((subscription) => {
          this.savedSubscriptionDetails = cloneDeep(subscription);
          this.subscriptionDetails = subscription;

          if (this.subscriptionDetails) {
            let newPaymentMethod: PaymentMethod | undefined;

            if (subscription) {
              newPaymentMethod = subscription.paymentMethod;
            }

            this.subscriptionDetails.paymentMethod = newPaymentMethod
              ? newPaymentMethod
              : this.editPlan
                ? PaymentMethod.CreditCard
                : PaymentMethod.ManualBankTransfer;
          }
        }),
        mergeMap(() => {
          return this.stripePaymentsService.getPaymentMethod();
        }),
        catchError(() => {
          this.isLoadingCardStatus = false;
          this.setupIntentStatus = null;
          return EMPTY;
        }),
        tap((paymentMethod: IStripePaymentMethod) => {
          if (paymentMethod) {
            const { brand, expMonth, expYear, last4 } = paymentMethod.card;
            const { name } = paymentMethod.billingDetails;
            this.cardLast4Digits = last4;
            this.cardExpirationDate = `${expMonth} / ${expYear}`;
            this.cardPaymentProcessingNetwork = brand;
            this.cardBillingName = name;
          }
          this.isLoadingCardStatus = false;
          this.setupIntentStatus = paymentMethod.status;
        }),
        finalize(() => this.isLoading = false)
      )
      .subscribe();
  }

  public onCancel(): void {
    this.editPlan = false;
    this.subscriptionDetails = cloneDeep(this.savedSubscriptionDetails);
    this.paymentConfirmationReceiverEmail = this.initialPaymentConfirmationReceiverEmail;
  }

  public onSave(): void {
    if (this.subscriptionDetails) {
      this.subscriptionService
        .savePaymentConfiguration(this.subscriptionDetails.paymentMethod, this.subscriptionDetails.periodType)
        .pipe(
          tap(() => this.editPlan = false)
        )
        .subscribe(() => {
          this.savedSubscriptionDetails = cloneDeep(this.subscriptionDetails);
        });
      this.setPaymentConfirmationReceiverEmail(this.paymentConfirmationReceiverEmail);
    } else {
      console.error('Model isn\'t defined, couldn\'t save...');
    }
  }

  public get subscriptionTypeLabel(): string {
    if (this.subscriptionDetails && this.subscriptionDetails.periodType) {
      return this.subscriptionDetails.periodType === SubscriptionType.Monthly ? 'Monthly' : 'Annual';
    }
    return ''; 
  }

  public get paymentMethodLabel(): string {
    if (this.subscriptionDetails && this.subscriptionDetails.paymentMethod) {
      return this.subscriptionDetails.paymentMethod === PaymentMethod.CreditCard ? 'Credit card' : 'Bank transfer';
    }
    return ''; 
  }

  public get showEditCreditCardDetailsSection() {
    return this.subscriptionDetails && this.subscriptionDetails.paymentMethod && this.subscriptionDetails.paymentMethod === PaymentMethod.CreditCard;
  }

  public get stripeUrl(): string {
    return process.env.VUE_APP_STRIPE_LINK;
  }
}
