




import { Component, Vue } from 'vue-property-decorator';
import { InjectArguments } from 'good-injector-vue';
import { AuthenticationService } from '@/services/authentication/authentication.service';
import VueRouter, { Route } from 'vue-router';

@Component({
  components: {}
})
export default class Login extends Vue {
  private authenticationService!: AuthenticationService;
  private readonly $router!: VueRouter;
  private readonly $route!: Route;

  @InjectArguments()
  public mounted(authenticationService: AuthenticationService): void {
    const hash = this.$route.hash;

    if (hash.indexOf('error=') >= 0) {
      this.$router.push({ path: '/' });
    } else {
      localStorage.setItem('adal.login.request', '/');
      authenticationService.sessionClient.acquireToken();
    }
  }
}
