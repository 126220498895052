import { isString } from '@sentry/utils';
import moment, { isDate } from 'moment';

export function dateFormatFilter(value: any): string {
  if (value === null || value === undefined || value === String()) {
    return '-';
  }

  if (!isString(value) && !isDate(value)) {
    value = String(value);
  }

  return moment(value).format('MM/DD/YYYY hh:mm');
}
