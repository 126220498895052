








































































import { Component } from 'vue-property-decorator';
import { InjectArguments } from 'good-injector-vue';
import { forkJoin, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SubscriptionService } from '@/services/subscription.service';
import { AuthenticationService } from '@/services/authentication/authentication.service';
import { OnboardingStatusService } from '@/services/onboarding-status.service';
import { SubscriptionContactsDto } from '@/models/subscription/subscription-contacts-dto';
import { TeamMemberMapper } from '@/mappers/team-member.mapper';
import { ContactType } from '@/models/contact-type.model';
import { KeyValuePair } from '@/models/key-value-pair';
import { ApiResponse } from '@/models/api-response';
import { TeamMember } from '@/models/team-member';
import { BaseWizardStepComponent } from './BaseWizardStepComponent';

@Component({
  components: {}
})
export default class Step3 extends BaseWizardStepComponent {
  private authenticationService!: AuthenticationService;
  private subscriptionService!: SubscriptionService;
  private memberToDelete?: TeamMember;
  public isLoading = false;
  public stepIndex = 3;
  public secondaryTeamMembers: TeamMember[] = [];
  public primaryTeamMember: TeamMember = {} as TeamMember;
  public isAdditionalMemberEditorVisible = false;
  public isDeleteTeamMemberConfirmationModalVisible = false;
  public deleteMemberConfirmationModalTitleConfirmButtonLabel = 'Confirm';
  public deleteMemberConfirmationModalTitle = 'Remove contact information?';
  public newMembers: TeamMember[] = [];

  @InjectArguments()
  public mounted(
    onboardingStatusService: OnboardingStatusService,
    authenticationService: AuthenticationService,
    subscriptionService: SubscriptionService
  ): void {
    this.subscriptionService = subscriptionService;
    this.authenticationService = authenticationService;
    this.isLoading = true;
    this.onMounted(onboardingStatusService);

    this.subscriptionService
      .getSubscription()
      .pipe(
        tap(data => {
          if (data) {
            const teamMembers = TeamMemberMapper.getTeamMembers(data);
            this.primaryTeamMember = teamMembers.find(
              m => m.isPrimaryContact
            ) as TeamMember;
            this.secondaryTeamMembers = teamMembers.filter(
              m => !m.isPrimaryContact
            );
          } else {
            this.primaryTeamMember = {
              ...this.authenticationService.sessionUser,
              isPrimaryContact: true,
              isValid: true
            };
            this.primaryTeamMember.phoneNumber = !this.primaryTeamMember.phoneNumber ? '' : this.primaryTeamMember.phoneNumber;
            this.newMembers.push(this.primaryTeamMember);
          }
          this.isLoading = false;
        })
      )
      .subscribe();
  }

  public get isModelValid(): boolean {
    return this.primaryTeamMember.isValid !== null && this.primaryTeamMember.isValid !== undefined && this.primaryTeamMember.isValid
    && this.secondaryTeamMembers.every(tm => tm.isValid !== null && tm.isValid !== undefined && tm.isValid);
  }

  public saveAndProceed(): void {
    if (this.newMembers.length > 0) {
      this.addTeamMembers(this.newMembers);
      
      let membersToUpdate: TeamMember[] = [];
      this.secondaryTeamMembers.forEach(tm => {
        if (this.newMembers.every(nm => nm.email !== tm.email)) {
          membersToUpdate.push(tm);
        }
      });

      if (this.newMembers.some(nm => nm.isPrimaryContact)) { 
        membersToUpdate.push(this.primaryTeamMember);
      }

      this.updateTeamMembers(membersToUpdate);
    } else {
      let membersToUpdate = [];
      this.secondaryTeamMembers.forEach(tm => membersToUpdate.push(tm));
      membersToUpdate.push(this.primaryTeamMember);

      this.updateTeamMembers(membersToUpdate);
    }
  } 
  
  private addTeamMembers(membersToAdd: TeamMember[] | null = null): void {
    this.secondaryTeamMembers.forEach(contact => {
      this.setNullForPhoneNumberIfNeeded(contact);
    });
    
    const model: SubscriptionContactsDto = {
      phone: this.primaryTeamMember.phoneNumber as string,
      sendInvoices: this.primaryTeamMember.isInvoicesRecipient === true,
      additionalContacts: TeamMemberMapper.getContacts(
        membersToAdd === null ? this.secondaryTeamMembers : membersToAdd
        )
      };
    
    this.subscriptionService
    .addTeamMembers(model)
    .pipe(
      tap(result => {
        if (result.isSuccess) {
          this.goToNextStep();
        }
      })
    )
    .subscribe();
  } 
  
  private updateTeamMembers(contactsToUpdate: TeamMember[]): void {
    this.secondaryTeamMembers.forEach(contact => {
      this.setNullForPhoneNumberIfNeeded(contact);
    });
    let updateTeamMembers: any[] = [];
    
    contactsToUpdate.forEach(c => {
      updateTeamMembers.push(this.subscriptionService
        .updateTeamMember({
          name: c.name as string,
          surname: c.surname as string,
          email: c.email as string,
          phone: c.phoneNumber as string,
          sendInvoices: c.isInvoicesRecipient === true,
          contactType: c.isPrimaryContact ? ContactType.Primary : ContactType.Secondary
        })
      )
    });

    forkJoin(updateTeamMembers)
      .pipe(
        tap(results => {
          if (results.every(r => (r as ApiResponse).isSuccess)) {
            this.goToNextStep();
          }          
        })  
      ).subscribe();
  }

  public addTeamMember(): void {
    this.isAdditionalMemberEditorVisible = true;
  }

  public onTeamMemberAdded(event: any): void {
    let teamMember: TeamMember = event.model;
    this.isAdditionalMemberEditorVisible = false;
    teamMember.isLocal = true;
    if (teamMember.phoneNumber === null) {
      teamMember.phoneNumber = '';
    }
    teamMember.isValid = true;
    this.secondaryTeamMembers.push(teamMember);
    this.newMembers.push(teamMember);
  }

  public onTeamMemberDelete(teamMember: TeamMember): void {
    this.memberToDelete = teamMember;
    this.isDeleteTeamMemberConfirmationModalVisible = true;
  }

  public onDeleteConfirmed(): void {
    if (this.memberToDelete) {
      this.deleteTeamMember(this.memberToDelete);
    }
    this.onDeleteCancelled();
  }

  public onDeleteCancelled(): void {
    this.isDeleteTeamMemberConfirmationModalVisible = false;
  }

  private deleteTeamMember(teamMember: TeamMember): void {
    this.isLoading = true;

    let removeTask = of({ isSuccess: true });

    if (!teamMember.isLocal) {
      removeTask = this.subscriptionService.removeTeamMember(
        teamMember.email as string
      );
    }

    removeTask
      .pipe(
        tap(result => {
          this.isLoading = false;

          if (result.isSuccess) {
            this.secondaryTeamMembers = this.secondaryTeamMembers.filter(
              m => m.email !== teamMember.email
            );
            this.newMembers = this.newMembers.filter(
              m => m.email !== teamMember.email
            );
            this.memberToDelete = undefined;
          }
        })
      )
      .subscribe();
  }

  public validateNewTeamMember(teamMember: TeamMember): KeyValuePair[] {
    const allMembers = [this.primaryTeamMember, ...this.secondaryTeamMembers];
    const isAnyEmailDuplicated = allMembers.some(m =>
      this.isEmailEqual(m.email as string, teamMember.email as string)
    );

    return isAnyEmailDuplicated
      ? [{ key: 'email', value: 'Email must be unique.' }]
      : [];
  }

  private isEmailEqual(email1: string, email2: string): boolean {
    if (!email1 || !email2) {
      return !email1 && !email2;
    }
    return email1.trim().toLowerCase() === email2.trim().toLowerCase();
  }

  public onTeamMemberCancel(): void {
    this.isAdditionalMemberEditorVisible = false;
  }

  private setNullForPhoneNumberIfNeeded(teamMember: TeamMember) {
    if (teamMember.phoneNumber === undefined || (teamMember.phoneNumber !== null && teamMember.phoneNumber.length === 0)) {
      teamMember.phoneNumber = null;
    }
  }
}
