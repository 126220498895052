

















import { Component, Vue } from 'vue-property-decorator';
import { InjectArguments } from 'good-injector-vue';
import VueRouter from 'vue-router';

import { RouteResolveService } from '@/services/route-resolve.service';
import { AuthenticationService } from '@/services/authentication/authentication.service';

@Component({
  components: {}
})
export default class Auth extends Vue {
  private countdownInterval = 0;
  private readonly $router!: VueRouter;

  public countdown = 5;

  public get bookadoUrl(): string {
    return process.env.VUE_APP_EMAIL_REDIRECT_URI;
  }

  @InjectArguments()
  mounted(authenticationService: AuthenticationService, routeResolveService: RouteResolveService): void {
    if (authenticationService.sessionClient.isLoggedIn()) {
      routeResolveService.resolveView(this.$router);
    }

    this.countdownInterval = setInterval(() => {
      if (this.countdown === 0) {
        clearInterval(this.countdownInterval);
        window.location.href = this.bookadoUrl;
      } else {
        this.countdown--;
      }
    }, 1000);
  }
}
