
































import { Component, Vue, Prop } from 'vue-property-decorator';
import { TeamMember } from '@/models/team-member';
import { PhoneNumberValidator } from '@/validators/phone-number.validator';

@Component({})
export default class WizardTeamMemberEditor extends Vue {
  @Prop() public model!: TeamMember;
  @Prop() public canDelete!: boolean;
  
  public isExpanded = false;
  public isPhoneError = false;
  public isPristine = true;
  private maxNumberOfCharactersInPhoneNumber = 20;
  private errorMessage = '';

  public updated(): void {
    if (this.isPristine) {
      this.validate();
      this.isExpanded = this.isPhoneError;
      this.isPristine = false;
    }
  }

  public onModelChanged(): void {
    this.$emit('input', this.model);
  }

  public onPhoneChanged(): void {
    this.isPristine = false;
    this.validate();

    if (!this.isPhoneError) {
      this.onModelChanged();
    }
  }

  public onToggleClick(): void {
    this.isPristine = false;

    this.validate();

    if (this.isPhoneError) {
      return;
    }

    this.isExpanded = !this.isExpanded;
  }

  public onDelete(): void {
    this.$emit('delete', this.model);
  }

  private validate(): void {
    this.isPhoneError = false;
    if (this.model.isPrimaryContact && (this.model === null || this.model === undefined || this.model.phoneNumber === null || this.model.phoneNumber === undefined || this.model.phoneNumber.length === 0)) {
      this.errorMessage = 'Field is required';
      this.isPhoneError = true;
    } else if (this.model && this.model.phoneNumber && this.model.phoneNumber.length > this.maxNumberOfCharactersInPhoneNumber) {
      this.errorMessage = 'Maximum number of characters is ' + this.maxNumberOfCharactersInPhoneNumber;
      this.isPhoneError = true;
    } else if ((this.model.isPrimaryContact || (this.model && this.model.phoneNumber && this.model.phoneNumber.length > 0)) && !PhoneNumberValidator.validatePhoneNumber(this.model.phoneNumber)) {
      this.errorMessage = 'Valid phone number is required';
      this.isPhoneError = true;
    }
    this.model.isValid = !this.isPhoneError;
  }
}
