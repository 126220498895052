






































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CropperData } from '@/models/resource-details/cropper-data';
import { UploadedResourcePhoto } from '@/models/resource-photo.model';

@Component({
  components: {},
})
export default class ResourcePhotoPicker extends Vue {
  @Prop() public readonly model!: CropperData;
  @Prop() public readonly changePhoto$!: Subject<void | boolean>;
  @Prop() public readonly isNoThumbnailMode?: boolean;
  @Prop(Boolean) public readonly isDefaultImageActive?: boolean;

  public defaultImage!: string;
  public uploadedPhoto = String();
  public isInitialized = false;
  public isAddPhotoModalVisible = false;
  public isFileCleared$ = new Subject<void>();

  private isDefaultImageChosen: boolean | undefined = false;

  @Watch('model', { deep: false })
  public modelUpdated(): void {
    this.initializePhotos();
  }

  @Watch('isDefaultImageActive')
  public isDefaultImageActiveChange(): void {
    this.isDefaultImageChosen = this.isDefaultImageActive;
  }

  public mounted(): void {
    this.isDefaultImageChosen = this.isDefaultImageActive;
    this.initializePhotos();

    if (this.changePhoto$) {
      this.changePhoto$.pipe(
        tap((isPhotoCleared) => isPhotoCleared ? this.isFileCleared$.next() : this.onAddPhotoClicked())
      ).subscribe();
    }
  }

  public onAddPhotoClicked(): void {
    this.isAddPhotoModalVisible = true;
  }

  public onAddPhotoModalCancel(): void {
    this.isFileCleared$.next();
    this.isAddPhotoModalVisible = false;
  }

  public onPhotoUploaded(uploadedPhoto: string): void {
    this.uploadedPhoto = uploadedPhoto;
    this.onAddPhotoModalCancel();
    this.onUploadedImageChosen(true, true);
  }

  public onUploadedImageChosen(forceUpdate = false, isNew = false): void {
    if (this.isDefaultImageChosen || forceUpdate) {
      this.emitImageSelected({ image: this.uploadedPhoto, isDefault: false, isNew });
      this.isDefaultImageChosen = false;
    }
  }

  public onDefaultImageChosen(): void {
    if (!this.isDefaultImageChosen) {
      this.emitImageSelected({ image: this.defaultImage, isDefault: true, isNew: false });
      this.isDefaultImageChosen = true;
    }
  }

  private emitImageSelected(data: UploadedResourcePhoto) {
    this.$emit('imageSelected', data);
  }

  private initializePhotos(): void {
    this.isInitialized = false;

    if (!!this.model && !!this.model.defaultImage) {
      this.defaultImage = this.model.defaultImage.originalImage || String();
      this.uploadedPhoto = !!this.model.customImage ? this.model.customImage.originalImage : String();
      this.isInitialized = true;
    }
  }
}
