import { Container } from 'good-injector-vue';
import { AdminConsentService } from './services/admin-consent.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { CachedResourcesService } from './services/cached-resources.service';
import { CachedStripeCheckoutContextService } from './services/cached-stripe-checkout-context.service';
import { ConsentLinkService } from './services/consent-link.service';
import { CountryService } from './services/country.service';
import { DashboardService } from './services/dashboard.service';
import { EmailService } from './services/email.service';
import { FileDownloadService } from './services/file-download.service';
import { InvoicesService } from './services/invoices.service';
import { LocalStorageCacheService } from './services/local-storage-cache.service';
import { OnboardingStatusService } from './services/onboarding-status.service';
import { RouteResolveService } from './services/route-resolve.service';
import { SearchService } from './services/search.service';
import { SettingsService } from './services/settings.service';
import { StripePaymentsService } from './services/stripe-payments.service';
import { SubscriptionPlanService } from './services/subscription-plan.service';
import { SubscriptionResourceImagesService } from './services/subscription-resource-images.service';
import { SubscriptionResourcesService } from './services/subscription-resources.service';
import { SubscriptionService } from './services/subscription.service';
import { FeatureSwitchSettings } from './settings/feature-switch.settings';

const container = new Container();

// services
container.registerSingleton(RouteResolveService);
container.registerSingleton(CountryService);
container.registerSingleton(EmailService);
container.registerSingleton(SubscriptionService);
container.registerSingleton(SubscriptionResourcesService);
container.registerSingleton(SubscriptionResourceImagesService);
container.registerSingleton(DashboardService);
container.registerSingleton(SubscriptionPlanService);
container.registerSingleton(InvoicesService);
container.registerSingleton(OnboardingStatusService);
container.registerSingleton(ConsentLinkService);
container.registerSingleton(AdminConsentService);
container.registerSingleton(AuthenticationService);
container.registerSingleton(FeatureSwitchSettings);
container.registerSingleton(LocalStorageCacheService);
container.registerSingleton(SearchService);
container.registerSingleton(CachedResourcesService);
container.registerSingleton(StripePaymentsService);
container.registerSingleton(CachedStripeCheckoutContextService);
container.registerSingleton(FileDownloadService);
container.registerSingleton(SettingsService);

export default container;
