







































import { Component, Vue } from 'vue-property-decorator';
import { SubscriptionPlanService } from '@/services/subscription-plan.service';
import { SubscriptionResourcesViewModel } from '@/models/subscription/subsription-resources-view-model';
import { map } from 'rxjs/operators';
import { InjectArguments } from 'good-injector-vue';

@Component({})
export default class SubscriptionResourcesGrid extends Vue {
  private subscriptionPlanService!: SubscriptionPlanService;

  public resources: SubscriptionResourcesViewModel[] = [];
  public grandTotalPrice = 0;
  public currency = String();

  @InjectArguments()
  mounted(subscriptionPlanService: SubscriptionPlanService): void {
    subscriptionPlanService
      .getSubscriptionResources()
      .pipe(
        map(resources => {
          this.resources = resources;
          this.grandTotalPrice = this.resources
            .map(r => r.totalPrice)
            .reduce((a, b) => a + b, 0);
          this.currency = this.resources[0].currency;
        })
      )
      .subscribe();
  }
}
