










import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class Checkbox extends Vue {
  @Prop(String) public readonly label!: string;
  @Prop(Boolean) public readonly model!: boolean;
  @Prop(Boolean) public readonly isRequired?: boolean;
  @Prop(Boolean) public readonly isReadonly?: boolean;
  @Prop(String) public readonly autocomplete?: string;

  public onInput(event: any): void {
    if (!this.isReadonly) {
      this.$emit('input', event.target.checked);
    }
  }
}
