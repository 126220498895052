import { ResourceImageType } from '@/models/resource-details/resource-image-type';

export class ResourceImageAspectRatioProvider {
  public static getAspectRatio(type: ResourceImageType): number {
    switch (type) {
      case ResourceImageType.Mobile:
        return 58 / 13;
      default:
        return 3 / 2;
    }
  }
}
