import { ICachedStripeCheckoutContext } from '@/models/stripe/cached-stripe-checkout-context.model';
import { StripeCheckoutContext } from '@/models/stripe/stripe-checkout-context.model';
import { SupportsInjection } from 'good-injector-vue';
import { LocalStorageCacheService } from './local-storage-cache.service';

@SupportsInjection
export class CachedStripeCheckoutContextService {
  private cachedResourcesKey = 'STRIPE_CHECKOUT_CONTEXT';
  public constructor(
    private localStorageCacheService: LocalStorageCacheService
  ) {}

  public getCachedStripeCheckoutContext(): ICachedStripeCheckoutContext {
    const context = this.localStorageCacheService.getItem(
      this.cachedResourcesKey
    ) as ICachedStripeCheckoutContext;

    if (context) {
      return context;
    }
    return { name: StripeCheckoutContext.Subscription, stripeCustomerId: null };
  }

  public setCachedStripeCheckoutContext(
    stripeCheckoutContext: StripeCheckoutContext,
    stripeCustomerId: string
  ): void {
    const context = {} as ICachedStripeCheckoutContext;
    context.name = stripeCheckoutContext;
    context.stripeCustomerId = stripeCustomerId;
    return this.localStorageCacheService.setItem(
      this.cachedResourcesKey,
      context
    );
  }
}
