


























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { InjectArguments } from 'good-injector-vue';
import { SubscriptionResourcesService } from '@/services/subscription-resources.service';
import { tap } from 'rxjs/operators';
import { MarkersRequest } from '@/models/markers-request';
import { MarkerLayout } from '@/models/marker-layout';
import { saveAs } from 'file-saver';
import { ResourceType } from '@/models/resource-type';

@Component({})
export default class ResourcesMarkersModal extends Vue {
  @Prop(Boolean) public readonly visible!: boolean;
  @Prop() public readonly resources!: string[];
  @Prop() public readonly resourceType!: string;

  public isGenerated = false;
  public ResourceType = ResourceType;

  private blob!: Blob;
  private subscriptionResourcesService!: SubscriptionResourcesService;

  @InjectArguments()
  public mounted(
    subscriptionResourcesService: SubscriptionResourcesService
  ): void {
    this.subscriptionResourcesService = subscriptionResourcesService;
  }

  @Watch('visible', { deep: false })
  onVisibilityChanged(): void {
    if (!this.visible) {
      return;
    }

    this.isGenerated = false;
    const request: MarkersRequest = { resourceIds: this.resources, layout: this.resourceType === 'room' ? MarkerLayout.Basic : MarkerLayout.Desk };

    this.subscriptionResourcesService
      .getMarkersPdf(request)
      .pipe(
        tap(blob => {
          if (blob) {
            this.blob = blob;
            this.file.weight = this.bytesToSize(blob.size);
            this.isGenerated = true;
          }
        })
      )
      .subscribe();
  }

  public file = {
    name: 'Markers.pdf',
    weight: '234.00 KB'
  };

  public onDownload(): void {
    this.showMarkers();
    this.$emit('close');
  }

  public onClose(): void {
    this.$emit('close');
  }

  private bytesToSize(bytes: number): string {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';

    let size: any = Math.floor(Math.log(bytes) / Math.log(1024));
    size = parseInt(size);
    return Math.round(bytes / Math.pow(1024, size)) + ' ' + sizes[size];
  }

  private showMarkers(): void {
    saveAs(this.blob, 'Markers.pdf');
  }
}
