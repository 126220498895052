









import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class Breadcrumb extends Vue {
  @Prop() public readonly breadcrumbPaths?: Breadcrumb[];
}
