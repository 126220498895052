















import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseGridComponent from './BaseGridComponent';

@Component({})
export default class OrganizationDetailsEditModal extends Vue {
  @Prop() public readonly parent!: BaseGridComponent;

  public get isResultEmpty(): boolean {
    return !this.parent || this.parent.totalItemsCount === 0;
  }

  public get totalItemsCount(): number {
    return this.parent ? this.parent.totalItemsCount : 0;
  }

  public get maxPageNumber(): number {
    return Math.ceil(this.totalItemsCount / this.parent.pageSize);
  }

  public get currentPageRange(): string {
    const pageNumber = this.parent.currentPageNumber;
    const pageSize = this.parent.currentPageSize;

    if (!pageSize || !pageNumber) {
      return '-';
    }

    const minIndex = (pageNumber - 1) * this.parent.pageSize;
    const maxIndex = minIndex + pageSize;
    return `${minIndex + 1}-${maxIndex}`
  }

  public onPreviousClicked(): void {
    if (this.parent.currentPageNumber > 1) {
      this.parent.currentPageNumber--;
      this.parent.loadPage(this.parent.currentPageNumber, this.parent.searchText);
    }
  }

  public onNextClicked(): void {
    if (this.parent.currentPageNumber < this.maxPageNumber) {
      this.parent.currentPageNumber++;
      this.parent.loadPage(this.parent.currentPageNumber, this.parent.searchText);
    }
  }
}
