import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import useBootstrap from './bootstrap';
import useFontAwesome from './fontAwesome';
import container from './ioc';
import '@/assets/style.scss';

// @ts-ignore
import Paginate from 'vuejs-paginate';
import VueMarkdown from 'vue-markdown';
import DocumentFooter from './components/common/DocumentFooter.vue';
import Checkbox from './components/common/Checkbox.vue';
import SearchInput from './components/common/SearchInput.vue';
import ConsentStatusComponent from './components/common/ConsentStatus.vue';
import CountryPicker from './components/common/CountryPicker.vue';
import LoginButton from './components/common/LoginButton.vue';
import UserCard from './components/common/UserCard.vue';
import UserAccountWidget from './components/common/UserAccountWidget.vue';
import InputTooltip from './components/common/InputTooltip.vue';
import SubscriptionStatus from './components/common/SubscriptionStatus.vue';
import Toast from './components/common/Toast.vue';
import OverlayLoader from './components/common/OverlayLoader.vue';
import CharactersLimitCounter from './components/common/CharactersLimitCounter.vue';
import WizardHeader from './components/wizard/WizardHeader.vue';
import WizardStep from './components/wizard/WizardStep.vue';
import WizardTeamMemberEditor from './components/wizard/WizardTeamMemberEditor.vue';
import WizardSubscriptionPlan from './components/wizard/WizardSubscriptionPlan.vue';
import DeleteMemberConfirmationModal from './components/wizard/DeleteMemberConfirmationModal.vue';
import StripeCreditCardDetailsCompletionStatusComponent from './components/wizard/StripeCreditCardDetailsCompletionStatusComponent.vue';
import StripePaymentProviderCheckout from './components/wizard/StripePaymentProviderCheckout.vue';
import PaymentMethodSelector from './components/wizard/PaymentMethodSelector.vue';
import Breadcrumb from './components/dashboard/Breadcrumb.vue';
import BackButton from './components/dashboard/BackButton.vue';
import PreviousNextResourceDetailsButtons from './components/dashboard/PreviousNextResourceDetailsButtons.vue';
import GridPagerComponent from './components/dashboard/GridPagerComponent.vue';
import OrganizationDetailsEditModal from './components/dashboard/OrganizationDetailsEditModal.vue';
import SubscriptionPlansModal from './components/dashboard/SubscriptionPlansModal.vue';
import ActivateResourcesConfirmationModal from './components/dashboard/ActivateResourcesConfirmationModal.vue';
import DeactivateResourcesConfirmationModal from './components/dashboard/DeactivateResourcesConfirmationModal.vue';
import ChangeSubscriptionPlanConfirmationModal from './components/dashboard/ChangeSubscriptionPlanConfirmationModal.vue';
import SubscriptionResourcesRecalculationModal from './components/dashboard/SubscriptionResourcesRecalculationModal.vue';
import SetAsDefaultPhotoConfirmationModal from './components/dashboard/SetAsDefaultPhotoConfirmationModal.vue';
import SetAsDefaultDescriptionConfirmationModal from './components/dashboard/SetAsDefaultDescriptionConfirmationModal.vue';
import TeamMemberDetailsEditModal from './components/dashboard/TeamMemberDetailsEditModal.vue';
import TeamMemberDetailsAddModal from './components/dashboard/TeamMemberDetailsAddModal.vue';
import SubscriptionResourcesGrid from './components/dashboard/SubscriptionResourcesGrid.vue';
import SubscriptionResourcesDetailsGrid from './components/dashboard/SubscriptionResourcesDetailsGrid.vue';
import InvoicesDetailsGrid from './components/dashboard/InvoicesDetailsGrid.vue';
import InvoicePositionsGrid from './components/dashboard/InvoicePositionsGrid.vue';
import ResourcesMarkersModal from './components/dashboard/ResourcesMarkersModal.vue';
import ResourcePhoto from './components/dashboard/resource-details/ResourcePhoto.vue';
import ResourcePhotoCropper from './components/dashboard/resource-details/ResourcePhotoCropper.vue';
import ResourcePhotoPreview from './components/dashboard/resource-details/ResourcePhotoPreview.vue';
import ResourceDescriptionPreview from './components/dashboard/resource-details/ResourceDescriptionPreview.vue';
import ResourcePhotoPreviewsList from './components/dashboard/resource-details/ResourcePhotoPreviewsList.vue';
import ResourceDescriptionPreviewsList from './components/dashboard/resource-details/ResourceDescriptionPreviewsList.vue';
import ResourcePhotoPicker from './components/dashboard/resource-details/ResourcePhotoPicker.vue';
import ResourcePhotoUploadModal from './components/dashboard/resource-details/ResourcePhotoUploadModal.vue';
import ResourceDescription from './components/dashboard/resource-details/ResourceDescription.vue';
import ResourceCustomAttributes from './components/dashboard/resource-details/ResourceCustomAttributes.vue';
import OrganizationDetailsForm from './views/forms/OrganizationDetailsForm.vue';
import TeamMemberEditForm from './views/forms/TeamMemberEditForm.vue';
import { dateFormatFilter } from './filters/date-format.filter';
import { onOffFilter } from './filters/on-off.filter';
import clickOutsideDirective from './directives/click-outside.directive';
import featureEnabledDirective from './directives/feature-enabled.directive';
import { GoodInjectorPlugin } from 'good-injector-vue';
import VTooltip from 'v-tooltip';
import ConfirmationModal from '@/components/common/ConfirmationModal.vue';
import CardDetails from '@/components/common/CardDetails.vue';
import SubscriptionChangeStatusModal from '@/views/dashboard/SubscriptionChangeStatusModal.vue';
import OrganizationDetailsFormVatId from '@/views/forms/OrganizationDetailsFormVatId.vue';
// import useGAuth from './gauth';

/* plugins */
Vue.use(GoodInjectorPlugin, { container });
Vue.use(VTooltip);

/* common components */
Vue.component('VueMarkdown', VueMarkdown);
Vue.component('Paginate', Paginate);
Vue.component('DocumentFooter', DocumentFooter);
Vue.component('Checkbox', Checkbox);
Vue.component('SearchInput', SearchInput);
Vue.component('ConsentStatus', ConsentStatusComponent);
Vue.component('CountryPicker', CountryPicker);
Vue.component('LoginButton', LoginButton);
Vue.component('UserCard', UserCard);
Vue.component('UserAccountWidget', UserAccountWidget);
Vue.component('InputTooltip', InputTooltip);
Vue.component('SubscriptionStatus', SubscriptionStatus);
Vue.component('Toast', Toast);
Vue.component('OverlayLoader', OverlayLoader);
Vue.component('CharactersLimitCounter', CharactersLimitCounter);
Vue.component('ConfirmationModal', ConfirmationModal);
Vue.component('CardDetails', CardDetails);
Vue.component('LoaderSpinner', LoaderSpinner);

/* forms components */
Vue.component('OrganizationDetailsForm', OrganizationDetailsForm);
Vue.component('OrganizationDetailsFormVatId', OrganizationDetailsFormVatId);

/* wizard components */
Vue.component('WizardHeader', WizardHeader);
Vue.component('WizardStep', WizardStep);
Vue.component('WizardTeamMemberEditor', WizardTeamMemberEditor);
Vue.component('TeamMemberEditForm', TeamMemberEditForm);
Vue.component('WizardSubscriptionPlan', WizardSubscriptionPlan);
Vue.component('DeleteMemberConfirmationModal', DeleteMemberConfirmationModal);
Vue.component(
  'StripeCreditCardDetailsCompletionStatus',
  StripeCreditCardDetailsCompletionStatusComponent
);
Vue.component('StripePaymentProviderCheckout', StripePaymentProviderCheckout);
Vue.component('PaymentMethodSelector', PaymentMethodSelector);

/* dashboard components */
Vue.component('Breadcrumb', Breadcrumb);
Vue.component('BackButton', BackButton);
Vue.component(
  'PreviousNextResourceDetailsButtons',
  PreviousNextResourceDetailsButtons
);
Vue.component('GridPagerComponent', GridPagerComponent);
Vue.component('OrganizationDetailsEditModal', OrganizationDetailsEditModal);
Vue.component('SubscriptionPlansModal', SubscriptionPlansModal);
Vue.component('SubscriptionChangeStatusModal', SubscriptionChangeStatusModal);
Vue.component(
  'ActivateResourcesConfirmationModal',
  ActivateResourcesConfirmationModal
);
Vue.component(
  'DeactivateResourcesConfirmationModal',
  DeactivateResourcesConfirmationModal
);
Vue.component(
  'ChangeSubscriptionPlanConfirmationModal',
  ChangeSubscriptionPlanConfirmationModal
);
Vue.component(
  'SubscriptionResourcesRecalculationModal',
  SubscriptionResourcesRecalculationModal
);
Vue.component('TeamMemberDetailsEditModal', TeamMemberDetailsEditModal);
Vue.component('TeamMemberDetailsAddModal', TeamMemberDetailsAddModal);
Vue.component('SubscriptionResourcesGrid', SubscriptionResourcesGrid);
Vue.component(
  'SubscriptionResourcesDetailsGrid',
  SubscriptionResourcesDetailsGrid
);
Vue.component('InvoicesDetailsGrid', InvoicesDetailsGrid);
Vue.component('InvoicePositionsGrid', InvoicePositionsGrid);
Vue.component('ResourcesMarkersModal', ResourcesMarkersModal);
Vue.component('ResourcePhoto', ResourcePhoto);
Vue.component('ResourcePhotoCropper', ResourcePhotoCropper);
Vue.component('ResourcePhotoPreview', ResourcePhotoPreview);
Vue.component('ResourcePhotoPreviewsList', ResourcePhotoPreviewsList);
Vue.component('ResourceDescriptionPreview', ResourceDescriptionPreview);
Vue.component(
  'ResourceDescriptionPreviewsList',
  ResourceDescriptionPreviewsList
);
Vue.component('ResourcePhotoPicker', ResourcePhotoPicker);
Vue.component('ResourcePhotoUploadModal', ResourcePhotoUploadModal);
Vue.component('ResourceDescription', ResourceDescription);
Vue.component('ResourceCustomAttributes', ResourceCustomAttributes);
Vue.component(
  'SetAsDefaultPhotoConfirmationModal',
  SetAsDefaultPhotoConfirmationModal
);
Vue.component(
  'SetAsDefaultDescriptionConfirmationModal',
  SetAsDefaultDescriptionConfirmationModal
);

/* filters */
Vue.filter('formatDate', dateFormatFilter);
Vue.filter('onOff', onOffFilter);

/* directives */
Vue.directive('click-outside', clickOutsideDirective);
Vue.directive('feature-enabled', featureEnabledDirective);

/* others */
Vue.config.productionTip = false;

useBootstrap();
useFontAwesome();
//useGAuth();

import VueGtm from '@gtm-support/vue2-gtm';
import LoaderSpinner from '@/components/common/LoaderSpinner.vue';

Vue.use(VueGtm, {
  id: 'GTM-5RKT66V', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  // queryParams: {
  //   // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
  //   gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
  //   gtm_preview: 'env-4',
  //   gtm_cookies_win: 'x',
  // },
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  nonce: '2726c7f26c', // Will add `nonce` to the script tag
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether display console logs debugs (optional)
  loadScript: true, // Whether to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: [], // Don't trigger events for specified router names (optional)
  trackOnNextTick: false, // Whether call trackView in Vue.nextTick
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
