import { SupportsInjection } from 'good-injector';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { capitalizeFirstLetter, isDatePast } from '@/lib/utils';
import { SubscriptionResourcesService } from './subscription-resources.service';
import { SubscriptionService } from './subscription.service';
import { DashboardModel } from '@/models/dashboard-model';
import { SubscriptionModel } from '@/models/subscription/subscription-model';
import { ResourceType } from '@/models/resource-type';

@SupportsInjection
export class DashboardService {
  public constructor(
    private subscriptionService: SubscriptionService,
    private resourcesService: SubscriptionResourcesService
  ) {}

  public getDashboardDetails(): Observable<DashboardModel> {
    return forkJoin([
      this.subscriptionService.getSubscriptionPlan(),
      this.resourcesService.getSubscriptionResources(ResourceType.Room),
      this.resourcesService.getSubscriptionResources(ResourceType.Desk),
    ]).pipe(
      map(([plan, rooms, desks]) => {
        const planId = (plan as SubscriptionModel).planId;
        const periodType = (plan as SubscriptionModel).periodType;
        const endDate = (plan as SubscriptionModel).endDate;

        return {
          planName: capitalizeFirstLetter(planId),
          periodType: capitalizeFirstLetter(periodType),
          isPlanActive: isDatePast(endDate),
          dueDate: endDate,
          isAutoRenewalEnabled: true,
          monthlyCost: 0,
          currency: (plan as SubscriptionModel).currency,
          lastInvoiceId: 0,
          lastInvoiceName: String(),
          isLastInvoicePaid: false,
          activeRoomsCount: rooms.filter((r) => r.isActive).length,
          totalRoomsCount: rooms.length,
          activeDesksCount: desks.filter((r) => r.isActive).length,
          totalDesksCount: desks.length,
        };
      })
    );
  }
}
