







































































































import { Component, Vue } from 'vue-property-decorator';
import { InjectArguments } from 'good-injector-vue';
import { EMPTY, forkJoin, Observable, Subject } from 'rxjs';
import { catchError, finalize, switchMap, take, tap } from 'rxjs/operators';
import { cloneDeep, isEqual } from 'lodash';
import VueRouter, { Route } from 'vue-router';

import { ResourceImagePreviewConfigBuilder } from '@/services/resource-details/resource-image-preview-config-builder';
import { ResourceImageType } from '@/models/resource-details/resource-image-type';
import { ResourceDefaultDetailsDto, ResourceImagesDto } from '@/models/resource-details/resource-images-dto';
import { SubscriptionResourceImagesService } from '@/services/subscription-resource-images.service';
import { CropperData, CropperImageData } from '@/models/resource-details/cropper-data';
import { CropperCoordinates } from '@/models/resource-details/cropper-change';
import { PreviewDescriptionConfig } from '@/models/resource-details/preview-config';
import { ToastDuration, ToastMessage, ToastSeverity } from '@/models/toast-message';
import { CroppedImage } from '@/models/resource-details/cropped-image';
import { ResourceType } from '@/models/resource-type';
import {
  DescriptionSidePaddingBreakpoints,
  DescriptionSidePaddingBreakpointsMapping
} from '@/constants/default-image-and-description.constants';
import { UploadedResourcePhoto } from '@/models/resource-photo.model';

@Component({
  components: {}
})
export default class SettingsDefaultImageAndDesc extends Vue {
  private imagesService: SubscriptionResourceImagesService = {} as SubscriptionResourceImagesService;
  private cropperData!: CropperData;
  private originalCropperData!: CropperData;
  private resourceImagesRooms: ResourceDefaultDetailsDto | null = null;
  private resourceImagesDesks: ResourceDefaultDetailsDto | null = null;
  private afterCloseConfirmationModalAction: (...args: unknown[]) => void = () => null;
  private imageAndDescriptionResizeObserver?: ResizeObserver;
  private detailsViewEl?: Element | null;
  private wasTabChanged = false;
  private readonly $router!: VueRouter;
  private readonly $route!: Route;

  public selectedResourceImages: ResourceDefaultDetailsDto | null = null;
  public toastMessage$: Subject<ToastMessage> = new Subject<ToastMessage>();
  public isLoading = false;
  public previewsConfigs: PreviewDescriptionConfig[] = [];
  public currentPreviewType: ResourceImageType = ResourceImageType.Mobile;
  public currentImageCoords: CropperCoordinates | null = {} as CropperCoordinates;
  public currentOriginalImage = String();
  public isImageOrDescriptionChanged = false;
  public isConfirmationModalVisible = false;
  public tabResourceTypeOptions = [ResourceType.Room, ResourceType.Desk];
  public selectedResourceType = ResourceType.Room;
  public tabResourceTypeLabelsMap = { [ResourceType.Room]: 'Rooms', [ResourceType.Desk]: 'Desks' }
  public areButtonsDisabled = false;
  public isSaving = false;
  public isRestoringInitialState = false;

  @InjectArguments()
  public mounted(
    subscriptionResourceImagesService: SubscriptionResourceImagesService
  ): void {
    this.imagesService = subscriptionResourceImagesService;
    this.initializeData().subscribe();
    this.watchRouteLeave();
    this.setResourceTypeFromQueryParam();
  }

  public updated() {
    this.setTabContentHeightBasedOnImage();
  }

  public unmounted() {
    this.unobserveResizeObserver();
  }

  private setResourceTypeFromQueryParam() {
    const queryParamsResourceType = this.$route.query.previewType as ResourceType | undefined;
    if (queryParamsResourceType) {
      this.selectedResourceType = queryParamsResourceType;
      this.$router.replace({ query: {} });
    }
  }

  private unobserveResizeObserver() {
    if (this.imageAndDescriptionResizeObserver && this.detailsViewEl) {
      this.imageAndDescriptionResizeObserver.unobserve(this.detailsViewEl)
    }
  }

  private watchRouteLeave() {
    this.$router.beforeResolve( (to, from, next) => {
      if (this.isImageOrDescriptionChanged) {
        this.afterCloseConfirmationModalAction = () => {
          next();
        }
        this.isConfirmationModalVisible = true;
      } else {
        next();
      }
    });
  }

  // it's not possible to set some CSS values to make cropper fully responsive, so some 'hacks' in this function are needed
  private observeHeight() {
    if (!this.imageAndDescriptionResizeObserver) {
      const photoAndDescriptionSpaceBetween = 12;
      const detailsViewSidePadding = 16;

      this.imageAndDescriptionResizeObserver = new ResizeObserver((observedEntries) => {
        const photoContainerElement = document.querySelector('.tab-content__photo-container');
        const descriptionContainerElement = document.querySelector('.tab-content__description-container') as HTMLElement;
        const descriptionInputElement = document.querySelector('.tab-content__description-container .description-input') as HTMLElement;
        const descriptionFormElement = document.querySelector('.tab-content__description-container .description-form') as HTMLElement;

        if (photoContainerElement && descriptionContainerElement && descriptionInputElement && descriptionFormElement) {
          if (window.innerWidth > 1600) {
            const contentRect = observedEntries[0].contentRect;
            const detailsViewWidth = contentRect.left + contentRect.right - 2 * detailsViewSidePadding;
            const newDescriptionTotalWidth = detailsViewWidth - photoContainerElement.clientWidth - photoAndDescriptionSpaceBetween;
            const { descriptionWidth, descriptionSidePadding } = this.getDescriptionWidthAndSidePadding(newDescriptionTotalWidth);
            this.setElementsWidth([descriptionContainerElement, descriptionFormElement, descriptionInputElement], descriptionWidth);
            descriptionInputElement.style.padding = `${descriptionSidePadding}px`;
          } else {
            // logic for description and image in a column (@media (max-width: 1600px))
            this.setElementsWidth([descriptionContainerElement, descriptionFormElement, descriptionInputElement], photoContainerElement.clientWidth);
            const { descriptionSidePadding } = this.getDescriptionWidthAndSidePadding(photoContainerElement.clientWidth);
            descriptionInputElement.style.padding = `${descriptionSidePadding}px`;
          }
        }
      });

      this.detailsViewEl = document.querySelector('.details-view');
      if (this.detailsViewEl) {
        this.imageAndDescriptionResizeObserver.observe(this.detailsViewEl);
      }
    }
  }

  private setElementsWidth(elements: HTMLElement[], width: number) {
    elements.forEach(element => {
      element.style.width = `${width}px`;
    })
  }

  private getDescriptionWidthAndSidePadding(totalWidth: number) {
    let descriptionWidth: number;
    let descriptionSidePadding: number;

    if (totalWidth < DescriptionSidePaddingBreakpoints.small) {
      descriptionSidePadding = DescriptionSidePaddingBreakpointsMapping[DescriptionSidePaddingBreakpoints.small];
    } else if (totalWidth < DescriptionSidePaddingBreakpoints.medium) {
      descriptionSidePadding = DescriptionSidePaddingBreakpointsMapping[DescriptionSidePaddingBreakpoints.medium];
    } else if (totalWidth < DescriptionSidePaddingBreakpoints.big) {
      descriptionSidePadding = DescriptionSidePaddingBreakpointsMapping[DescriptionSidePaddingBreakpoints.big];
    } else {
      descriptionSidePadding = DescriptionSidePaddingBreakpointsMapping.default;
    }

    descriptionWidth = totalWidth - 2 * descriptionSidePadding;

    return { descriptionWidth, descriptionSidePadding };
  }

  private checkAreCurrentCoordinatesSetsDefined() {
    return !!(this.originalCropperData.customImage
      && (this.originalCropperData.customImage.coordinatesSets[this.currentPreviewType]
        && Object.values(this.originalCropperData.customImage.coordinatesSets[this.currentPreviewType]).filter((coordinatesSet) => coordinatesSet > 0).length > 0));
  }

  private checkIsImageOrDescriptionChanged() {
    this.isImageOrDescriptionChanged = !!(this.cropperData.customImage && this.originalCropperData.customImage)
      && this.checkAreCurrentCoordinatesSetsDefined() && (!isEqual(this.cropperData.customImage.coordinatesSets[this.currentPreviewType], this.originalCropperData.customImage.coordinatesSets[this.currentPreviewType])
        || !isEqual(
          this.selectedResourceImages,
          this.selectedResourceType === ResourceType.Room ? this.resourceImagesRooms : this.resourceImagesDesks
        ));
  }

  private initializeData(): Observable<[ResourceDefaultDetailsDto, ResourceDefaultDetailsDto]> {
    this.isLoading = true;
    return forkJoin([
      this.imagesService.getSubscriptionDefaultResourceDetails(ResourceType.Room),
      this.imagesService.getSubscriptionDefaultResourceDetails(ResourceType.Desk)
    ])
      .pipe(
        take(1),
        tap(([roomsDetails, desksDetails]) => {
          this.resourceImagesRooms = roomsDetails;
          this.resourceImagesDesks = desksDetails;
          this.loadData(this.selectedResourceType);
          this.isLoading = false;
          this.originalCropperData = cloneDeep(this.cropperData);
        })
      )
  }

  // used to have the same height of image and description in tabs contents
  private setTabContentHeightBasedOnImage() {
    // setTimeout is a needed hack in this case to load image height after cropper is initialized
    setTimeout(() => {
      const imageElement: HTMLElement = document.querySelector('.vue-advanced-cropper__image') as HTMLElement;
      if (imageElement) {
        const imageElementHeight = imageElement.offsetHeight;
        if (imageElementHeight > 0) {
          const tabContentElement: HTMLElement = document.querySelector('.tab-content') as HTMLElement;
          if (tabContentElement) {
            const borderHeight = 2;
            tabContentElement.style.height = `${imageElementHeight + borderHeight}px`;
          }
        }
      }
    }, 400);
  }

  private loadData(type?: ResourceType): void {
    this.selectedResourceImages = cloneDeep(type === ResourceType.Room ? this.resourceImagesRooms : this.resourceImagesDesks);
    if (this.selectedResourceImages) {
      this.cropperData = new CropperData(this.selectedResourceImages as ResourceImagesDto, true);
      const hasCrops = this.cropperData.validateCrops();
      if (hasCrops) {
        this.setCurrentImage();
        this.setPreviewsConfigs();
        this.setPreviewType(this.currentPreviewType);
      }
    }
  }

  private setPreviewsConfigs(customDescription?: string): void {
    if (this.selectedResourceImages) {
      const newDescription = customDescription !== undefined
        ? customDescription
        : this.selectedResourceImages.description || '';
      if (this.currentImageCoords && this.cropperData.customImage) {
        this.currentImageCoords = cloneDeep(this.cropperData.customImage.coordinatesSets[this.currentPreviewType]);
      }
      this.previewsConfigs = ResourceImagePreviewConfigBuilder.buildDescriptionConfig(this.cropperData.customImage, newDescription);
    }
  }

  private setCurrentImage(): void {
    if (this.cropperData.customImage) {
      this.currentOriginalImage = this.cropperData.customImage.originalImage;
    }
  }

  private setPreviewType(viewType: ResourceImageType) {
    if (this.currentPreviewType !== viewType && this.cropperData.customImage) {
      this.currentImageCoords = cloneDeep(this.cropperData.customImage.coordinatesSets[viewType]);
      this.currentPreviewType = viewType;
    }
  }

  private cancel(): Observable<[ResourceDefaultDetailsDto, ResourceDefaultDetailsDto]> {
    this.areButtonsDisabled = true;
    this.isRestoringInitialState = true;
    return this.initializeData()
      .pipe(
        finalize(() => {
          this.checkIsImageOrDescriptionChanged();
          this.areButtonsDisabled = false;
          this.isRestoringInitialState = false;
          this.wasTabChanged = false;
        })
      )
  }

  private save(resourceType?: ResourceType): Observable<[ResourceDefaultDetailsDto, ResourceDefaultDetailsDto]> {
    if (this.cropperData.customImage && this.selectedResourceImages) {
      this.areButtonsDisabled = true;
      this.isSaving = true;
      return forkJoin([
        this.imagesService.saveDefaultImage(this.selectedResourceType, this.cropperData.customImage as CropperImageData),
        this.imagesService.saveDefaultDescription(this.selectedResourceType, this.selectedResourceImages.description)
      ])
        .pipe(
          take(1),
          switchMap(() => this.initializeData()),
          catchError(() => {
            this.toastMessage$.next(
              new ToastMessage(
                'Couldn\'t save description. Please try again later.',
                ToastSeverity.Error
              )
            );
            this.isLoading = false;
            return EMPTY;
          }),
          finalize(() => {
            this.isImageOrDescriptionChanged = false;
            this.toastMessage$.next(
              new ToastMessage(
                `Default image and description for ${resourceType || this.selectedResourceType.toLowerCase()}s successfully saved.`,
                ToastSeverity.Success,
                ToastDuration.MEDIUM
              )
            );
            this.areButtonsDisabled = false;
            this.isSaving = false;
            this.wasTabChanged = false;
          }),
        );
    } else {
      return EMPTY;
    }
  }

  public onCropperIsReady() {
    this.observeHeight();
  }

  public onDescriptionChanged(description: string) {
    if (this.selectedResourceImages) {
      this.selectedResourceImages.description = description;
    }
    this.setPreviewsConfigs(description);
    this.checkIsImageOrDescriptionChanged();
  }

  public onTabChanged(tab: ResourceType) {
    const changePreviewTypeAction = () => {
      this.selectedResourceType = tab;
      this.loadData(this.selectedResourceType);
      this.originalCropperData = cloneDeep(this.cropperData);
    }
    if (this.isImageOrDescriptionChanged) {
      this.afterCloseConfirmationModalAction = changePreviewTypeAction;
      this.isConfirmationModalVisible = true;
    } else {
      changePreviewTypeAction();
    }
    this.wasTabChanged = true;
  }

  public onPreviewClick(type: ResourceImageType) {
    this.setPreviewType(type);
    this.$forceUpdate();
  }

  public onImageCropped(model: CroppedImage): void {
    this.cropperData.setCustomCroppedImage(model);

    if (this.cropperData.customImage && !isEqual(this.currentImageCoords, this.cropperData.customImage.coordinatesSets[this.currentPreviewType])) {
      this.currentImageCoords = cloneDeep(this.cropperData.customImage.coordinatesSets[this.currentPreviewType]);
    }

    if (this.selectedResourceImages) {
      this.previewsConfigs = ResourceImagePreviewConfigBuilder.buildDescriptionConfig(this.cropperData.customImage, this.selectedResourceImages.description);
    }

    if (this.originalCropperData.customImage) {
      if (this.cropperData.customImage && !this.checkAreCurrentCoordinatesSetsDefined()) {
        this.originalCropperData.customImage.coordinatesSets = cloneDeep(this.cropperData.customImage.coordinatesSets);
        this.isImageOrDescriptionChanged = !this.wasTabChanged;
      } else {
        this.checkIsImageOrDescriptionChanged();
      }
    }
  }


  public onImageSelected(data: UploadedResourcePhoto) {
    if (this.cropperData.customImage && !isEqual(data.image, this.cropperData.customImage.originalImage)) {
      this.cropperData.setCustomImage(data.image);

      if (this.cropperData.customImage) {
        this.currentOriginalImage = this.cropperData.customImage.originalImage;
        this.currentImageCoords = null;

        if (this.selectedResourceImages) {
          this.previewsConfigs = ResourceImagePreviewConfigBuilder.buildDescriptionConfig(this.cropperData.customImage, this.selectedResourceImages.description);
        }
      }
      this.checkIsImageOrDescriptionChanged();
    }
  }

  public onConfirmationDialogCancel() {
    this.isConfirmationModalVisible = false;
  }

  public onConfirmationDialogDiscard() {
    this.isConfirmationModalVisible = false;
    this.isLoading = true;
    this.cancel().subscribe(() => {
      this.isLoading = false;
      this.afterCloseConfirmationModalAction();
    });
  }

  public onConfirmationDialogSave() {
    this.isConfirmationModalVisible = false;
    this.isLoading = true;
    this.save(this.selectedResourceType).subscribe(() => {
      this.isLoading = false;
      this.afterCloseConfirmationModalAction();
    });
  }

  public onCancel() {
    this.cancel().subscribe();
  }

  public onSave() {
    this.save().subscribe();
  }

}
