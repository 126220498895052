import { render, staticRenderFns } from "./SetAsDefaultPhotoConfirmationModal.vue?vue&type=template&id=00398b7e&scoped=true&"
import script from "./SetAsDefaultPhotoConfirmationModal.vue?vue&type=script&lang=ts&"
export * from "./SetAsDefaultPhotoConfirmationModal.vue?vue&type=script&lang=ts&"
import style0 from "./SetAsDefaultPhotoConfirmationModal.vue?vue&type=style&index=0&id=00398b7e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00398b7e",
  null
  
)

export default component.exports