











import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { InjectArguments } from 'good-injector-vue';

import { StripeSetupIntentStatus } from '@/models/stripe/stripe-setup-intent-status.model';
import { CreditCardDetailsCompletionStatus } from '@/models/stripe/credit-card-details-completion-status';

@Component({
  components: {}
})
export default class StripeCreditCardDetailsCompletionStatusComponent extends Vue {
  @Prop(String) public readonly status!: StripeSetupIntentStatus | null;
  @Prop(Boolean) public readonly doesCustomerHaveAnyCard?: boolean;

  public creditCardDetailsCompletionStatus: CreditCardDetailsCompletionStatus | null = null;

  @InjectArguments()
  mounted(): void {
    this.statusUpdated();
  }
  
  @Watch('status', { deep: false })
  public statusUpdated(): void {
    if (this.status === null || !this.doesCustomerHaveAnyCard) {
      this.creditCardDetailsCompletionStatus = CreditCardDetailsCompletionStatus.NotStarted;
    } else {
      switch(this.status) {
        case StripeSetupIntentStatus.RequiresPaymentMethod:
        case StripeSetupIntentStatus.Canceled:
          this.creditCardDetailsCompletionStatus = CreditCardDetailsCompletionStatus.NotStarted;
          break;
        case StripeSetupIntentStatus.RequiresAction:
        case StripeSetupIntentStatus.RequiresConfirmation:
        case StripeSetupIntentStatus.Processing:
          this.creditCardDetailsCompletionStatus = CreditCardDetailsCompletionStatus.InProgress;
          break;
        case StripeSetupIntentStatus.Succeeded:
          this.creditCardDetailsCompletionStatus = CreditCardDetailsCompletionStatus.Succeeded;
          break;
        default:
          this.creditCardDetailsCompletionStatus = CreditCardDetailsCompletionStatus.Error;
          break;
      }
    }
  }

  public get statusClass() {
    switch(this.creditCardDetailsCompletionStatus) {
      case CreditCardDetailsCompletionStatus.NotStarted:
        return 'notStarted';

      case CreditCardDetailsCompletionStatus.InProgress:
        return 'inProgress';

      case CreditCardDetailsCompletionStatus.Succeeded:
        return 'succeeded';

      case CreditCardDetailsCompletionStatus.Error:
      default:
        return 'error';
    }
  }

  public getInfoMessage() {
    switch(this.creditCardDetailsCompletionStatus) {
      case CreditCardDetailsCompletionStatus.NotStarted:
        return 'No credit card added';

      case CreditCardDetailsCompletionStatus.InProgress:
        return 'Adding card is in progress';

      case CreditCardDetailsCompletionStatus.Succeeded:
        return 'Your credit card has been successfully added';

      case CreditCardDetailsCompletionStatus.Error:
      default:
        return 'There were some errors. Please contact support';
    }
  }
}
