


















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class SubscriptionResourcesRecalculationModal extends Vue {
  @Prop(Boolean) public readonly visible!: boolean;
}
