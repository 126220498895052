import { User } from '@/models/user';
import { AdalConfig, Authentication, AuthenticationContext } from 'adal-ts';
import { AuthenticationClient } from '../authentication-client';

export class MicrosoftAuthenticationClient implements AuthenticationClient {
  public login(): void {
    this.getContext().login();
  }

  public acquireToken(): Promise<boolean> {
    const processor = Authentication.getAadRedirectProcessor();
    return new Promise(() => processor.process());
  }

  public isLoggedIn(): boolean {
    return this.getUser() !== null;
  }

  public getUser(): User | null {
    const context = this.getContext();

    if (!context) {
      return null;
    }

    const user = context.getUser();

    if (!user) {
      return null;
    }

    const exp = user.exp as number;

    if (exp * 1000 < Date.now()) {
      return null;
    }

    return {
      tenantId: user.tid,
      name: user.name,
      email: user.unique_name,
    };
  }

  public getAuthToken(): string {
    return this.getContext().getToken();
  }

  public logout(): void {
    this.getContext().logout();
  }

  private getContext(): AuthenticationContext {
    const clientId = process.env.VUE_APP_MICROSOFT_CLIENTID;
    const tenantId = 'common';
    const redirectUrl = `${location.origin}/login`;
    const logoutUrl = `${window.location.origin}/auth`;
    const config = new AdalConfig(clientId, tenantId, redirectUrl, logoutUrl);
    return Authentication.getContext(config);
  }
}
