






























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Resource } from '@/models/resource';

@Component({
  components: {},
})
export default class ResourceCustomAttributes extends Vue {
  @Prop() public readonly model?: Resource;

  public get hasAttributes(): boolean {
    return !!this.model && !!this.model.additionalParameters && this.model.additionalParameters.length > 0;
  }
}
