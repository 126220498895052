


import { Component, Vue } from 'vue-property-decorator';
import { InjectArguments } from 'good-injector-vue';
import { RouteResolveService } from '@/services/route-resolve.service';
import VueRouter from 'vue-router';

@Component({
  components: {}
})
export default class Home extends Vue {
  private readonly $router!: VueRouter;

  @InjectArguments()
  public mounted(routeResolveService: RouteResolveService): void {
    routeResolveService.resolveView(this.$router);
  }
}
