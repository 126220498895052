import { ValuesValidator } from './values.validator';

export class PhoneNumberValidator {
  public static validatePhoneNumber(value: string | null | undefined): boolean {
    if (!ValuesValidator.validateString(value)) {
      return false;
    }

    return PhoneNumberValidator.isPhoneNumberValid(value as string);
  }

  private static isPhoneNumberValid(phone: string): boolean {
    const expression = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
    return expression.test(phone);
  }
}
