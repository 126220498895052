

































import { Component, Prop, VModel, Vue, Watch } from 'vue-property-decorator';

import { ConfirmationModalCloseCounterSeconds } from '@/models/modals/confirmation-modal-close-counter.model';
import { ISubscriptionChangeStatusModalLabels } from '@/models/subscription/subscription-change-status-modal-labels.model';
import {
  ISubscriptionDeactivationReasonSelector
} from '@/models/subscription/subscription-deactivation-reason-selector.model';
import LoaderSpinner from '@/components/common/LoaderSpinner.vue';

@Component({
  components: {}
})
export default class SubscriptionChangeStatusModal extends Vue {
  @VModel() modalDataVModel!: { isVisible: boolean, isSubmitted: boolean, isSubmitDisabled?: boolean, additionalElementData?: ISubscriptionDeactivationReasonSelector };
  @Prop(Object) public readonly modalLabels!: ISubscriptionChangeStatusModalLabels;
  @Prop(Function) public readonly onConfirmFn!: () => void;
  @Prop(Function) public readonly additionalElement!: Vue;
  @Prop(Boolean) public readonly isSuccessfullySubmitted: boolean | null = null;

  public closeCounterSeconds: ConfirmationModalCloseCounterSeconds = 0;
  public modalCloseCounterInterval!: number;
  public loaderSpinnerComponent = LoaderSpinner;

  @Watch('closeCounterSeconds')
  public updateCloseCounterSeconds() {
    if (this.closeCounterSeconds === 10) {
      this.modalCloseCounterInterval = setInterval(() => {
        this.closeCounterSeconds--;
      }, 1000);
    } else if (this.closeCounterSeconds === 0) {
      clearInterval(this.modalCloseCounterInterval);
      this.modalDataVModel.isVisible = false;
      // reload the page to check is user still having access to all endpoints after activate / deactivate succeeded
      if (this.isSuccessfullySubmitted) {
        location.reload();
      }
    }
  }

  // start counter, when activate / deactivate request succeeded
  @Watch('isSuccessfullySubmitted')
  public updateIsSuccessfullySubmitted() {
    if (this.isSuccessfullySubmitted) {
      this.closeCounterSeconds = 10;
    }
  }

  public onConfirm() {
    this.modalDataVModel.isSubmitted = true;
    this.onConfirmFn();
  }

  public clearInterval(interval: number) {
    clearInterval(interval);
  }

  public getCloseModalButtonLabel(counter: ConfirmationModalCloseCounterSeconds) {
    return `Close (${counter}s)`;
  }

}
