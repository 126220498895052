











































































import { Component, Vue } from 'vue-property-decorator';
import { InjectArguments } from 'good-injector-vue';
import { EMPTY, Observable } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';

import { ConsentStatus } from '@/models/consent-status';
import { ApiResponse } from '@/models/api-response';
import ConsentType from '@/models/consent-type';
import { AdminConsentService } from '@/services/admin-consent.service';
import { ConsentLinkService } from '@/services/consent-link.service';
import { EmailService } from '@/services/email.service';
import { EmailValidator } from '@/validators/email.validator';

@Component({
  components: {}
})
export default class SettingsAdminConsent extends Vue {
  private emailService!: EmailService;
  private adminConsentService!: AdminConsentService;

  public consentStatus: ConsentStatus = ConsentStatus.NotStarted;
  public isLoadingConsentStatus = true;
  public consentLink = String();
  public isSendingEmail = false;
  public email = String();
  public emailSentMessage = String();
  public emailError: string | undefined = String();
  public isEmailValid = false;
  public isEmailSent = false;
  public showIsEmailSentMessage = false;
  public isAdmin = false;

  @InjectArguments()
  public mounted(
      consentLinkService: ConsentLinkService,
      adminConsentService: AdminConsentService,
      emailService: EmailService,
  ) {
    this.consentLink = consentLinkService.getConsentLink();
    this.emailService = emailService;
    this.adminConsentService = adminConsentService;
    this.getConsentStatus();
  }

  private getConsentStatus() {
    this.adminConsentService.getAdminConsentSettings()
        .pipe(
            tap(() => this.isLoadingConsentStatus = true),
            take(1),
            catchError(() => {
              this.consentStatus = ConsentStatus.Error;
              return EMPTY;
            }),
            map(settings => settings.consentType),
            filter(consentType => consentType === ConsentType.Admin),
            finalize(() => this.isLoadingConsentStatus = false)
        )
        .subscribe(() => {
          this.isAdmin = true;
          this.consentStatus = ConsentStatus.Completed;
        });
  }

  private setEmailSentMessage(email: string): void {
    this.emailSentMessage = `Email sent (last recipient: ${email})`;
  }

  private saveConsentSettings(setAdminType = false): Observable<ApiResponse> {
    if (setAdminType) {
      this.consentStatus = ConsentStatus.Completed;
    }
    return this.adminConsentService.saveAdminConsentSettings(this.isAdmin || setAdminType ? ConsentType.Admin : ConsentType.Email, this.email);
  }

  public onEmailChanged(): void {
    this.isEmailValid = EmailValidator.validateEmail(this.email);
    this.showIsEmailSentMessage = false;
  }

  public sendAdminRequest() {
    this.isSendingEmail = true;

    this.emailService
        .sendAdminConsentEmail(this.email)
        .pipe(
            take(1),
            tap((response) => {
              const { errorMessage, isSuccess } = response;
              [this.emailError, this.isEmailSent] = [errorMessage, isSuccess];
              this.setEmailSentMessage(this.email);
            }),
            filter((response) => response.isSuccess),
            switchMap(() => this.saveConsentSettings().pipe(take(1)))
        )
        .subscribe(() => {
          this.showIsEmailSentMessage = true;
          this.isSendingEmail = false;
        });
  }

}
