import { User } from '@/models/user';
import { AuthenticationClient } from '../authentication-client';

export class UnauthorizedAuthenticationClient implements AuthenticationClient {
  public login(): void {}

  public acquireToken(): Promise<boolean> {
    return new Promise(() => false);
  }

  public isLoggedIn(): boolean {
    return false;
  }

  public getUser(): User | null {
    return null;
  }

  public getAuthToken(): string {
    return String();
  }

  public logout(): void {}
}
