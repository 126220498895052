



import { StripeCheckoutContext } from '@/models/stripe/stripe-checkout-context.model';
import { CachedStripeCheckoutContextService } from '@/services/cached-stripe-checkout-context.service';
import { InjectArguments } from 'good-injector-vue';
import { Component, Vue } from 'vue-property-decorator';
import VueRouter from 'vue-router';

@Component({
  components: {},
})
export default class StripeCheckoutCancel extends Vue {
  private cachedStripeCheckoutContextService!: CachedStripeCheckoutContextService;
  private readonly $router!: VueRouter;

  @InjectArguments()
  public mounted(cachedStripeCheckoutContextService: CachedStripeCheckoutContextService): void {
    this.cachedStripeCheckoutContextService = cachedStripeCheckoutContextService;

    let context = this.cachedStripeCheckoutContextService.getCachedStripeCheckoutContext();
    
    if (context.name === StripeCheckoutContext.Subscription) {
      this.$router.push({ name: `${context.name}WithEditPlan`, params: { editPlan: 'true' } });
    } else {
      this.$router.push({ name: context.name });
    }
  }
}
