

























import { Component, Vue, Prop } from 'vue-property-decorator';
import { WizardStep } from '@/models/wizard-step';

@Component({})
export default class WizardHeader extends Vue {
  @Prop(Number) public readonly selectedStep!: number;
  @Prop(Boolean) public readonly hideSteps!: boolean;
  @Prop(Boolean) public readonly hideAccount!: boolean;

  public steps: WizardStep[] = [
    { index: 1, label: 'Welcome to bookado' },
    { index: 2, label: 'Organization details' },
    { index: 3, label: 'Administration team' },
    { index: 4, label: 'Your authorization' },
    { index: 5, label: 'Choose your plan' },
    { index: 6, label: 'Payment information' },
  ];
}
