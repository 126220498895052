















































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { InjectArguments } from 'good-injector-vue';
import VueRouter from 'vue-router';
import { tap } from 'rxjs/operators';

import { SubscriptionResourcesService } from '@/services/subscription-resources.service';
import { CachedResourcesService } from '@/services/cached-resources.service';
import { SearchService } from '@/services/search.service';
import { ResourceType } from '@/models/resource-type';
import { Resource } from '@/models/resource';
import { AlterResourcesRequest } from '@/models/alter-resources-request';
import BaseGridComponent from './BaseGridComponent';

@Component({})
export default class SubscriptionResourcesDetailsGrid extends BaseGridComponent {
  @Prop() public readonly model!: Resource[];
  @Prop() public readonly resourceType!: ResourceType;
  @Prop() public readonly currentPage!: string;
  @Prop() public updateLabels!: () => void;

  private readonly $router!: VueRouter;

  public alterRequest: AlterResourcesRequest = {} as AlterResourcesRequest;
  public isActivateResourcesModalVisible = false;
  public isDeactivateResourcesModalVisible = false;
  public isMarkersModalVisible = false;
  public markersResources: string[] = [];
  public searchText = '';
  public thisRef: SubscriptionResourcesDetailsGrid | null = null;

  @InjectArguments()
  public mounted(searchService: SearchService, subscriptionResourcesService: SubscriptionResourcesService, cachedResourcesService: CachedResourcesService): void {
    this.onMounted(searchService, subscriptionResourcesService, cachedResourcesService);
    this.onDataRefresh();
    this.thisRef = this;
  }

  @Watch('model', { deep: false })
  onModelChanged(): void {
    this.onDataRefresh();
  }

  public get isAnyRowSelected(): boolean {
    return this.getDataSource().some(r => r.isSelected);
  }

  public get isAnyVisibleRowSelected(): boolean {
    return this.getDataSource().some(r => r.isSelected && r.visible);
  }

  public get isAnyHiddenRowSelected(): boolean {
    return this.getDataSource().some(r => r.isSelected && !r.visible);
  }

  public get isAllRowsSelected(): boolean {
    return this.pageData.length > 0 && !this.pageData.some(r => !r.isSelected);
  }

  public get isAnyDataSourceSelected(): boolean {
    return this.getDataSource().some(r => r.isSelected);
  }

  public onResourceDetailsClicked(resource: Resource): void {
    this.$router.push({ name: 'resourceDetails', params: { id: resource.id, currentPageNumber: this.currentPageNumber.toString() } });
  }

  public toggleSelectAll(selectAll: boolean): void {
    this.pageData.forEach(r => (r.isSelected = selectAll));
  }

  public onActivateResources(): void {
    if (this.isAnyRowSelected) {
      const resourceIds = this.getDataSource()
          .filter(r => r.isSelected && !r.visible)
          .map(r => r.id);
      
      resourceIds.forEach(resourceId => {
        this.onVisibilityChange(resourceId, true);
      });
    }
  }

  public onDeactivateResources(): void {
    if (this.isAnyRowSelected) {
      const resourceIds = this.getDataSource()
          .filter(r => r.isSelected && r.visible)
          .map(r => r.id);
      
      resourceIds.forEach(resourceId => {
        this.onVisibilityChange(resourceId, false);
      });
    }
  }

  public onGenerateResourcesMarkers(): void {
    if (this.isAnyDataSourceSelected) {
      this.markersResources = this.getDataSource().filter(r => r.isSelected).map(r => r.id);
      this.getDataSource().forEach(resource => {
        resource.isSelected = false;
      });
      this.isMarkersModalVisible = true;
    }
  }

  public onResourcesMarkersModalClosed(): void {
    this.isMarkersModalVisible = false;
  }

  public onAlterResourcesModalAccept(): void {
    this.onResourcesModalClosed();
    // todo: save changes & reload grid!
  }

  public onResourcesModalClosed(): void {
    this.isActivateResourcesModalVisible = false;
    this.isDeactivateResourcesModalVisible = false;
  }

  public onGridSearch(searchText: string): void {
    this.searchText = searchText;
    this.loadPage(parseInt(this.currentPage), searchText);
  }

  public onVisibilityChange(resourceId: string, isVisible: boolean): void {
    this.subscriptionResourcesService.setSubscriptionResourceVisibility(resourceId, isVisible)
    .pipe(
        tap((isSuccessful) => {
          if (isSuccessful) {
            this.getDataSource().forEach(resource => {
              if (resource.id === resourceId) {
                resource.visible = isVisible;
                this.cachedResourcesService.setCachedResources(this.resourceType, this.getDataSource());
              }
            });
                this.updateLabels();
          }
        })
      )
      .subscribe();
  }

  private onDataRefresh(): void {
    if (!this.model) {
      return;
    }
    this.setDataSource(this.model);
    this.loadPage(parseInt(this.currentPage), this.searchText);
  }
}
