





































import { Component, Prop, Watch } from 'vue-property-decorator';
import { AlterResourcesRequest } from '@/models/alter-resources-request';
import { SubscriptionPlanService } from '../../services/subscription-plan.service';
import { SubscriptionPlan } from '../../models/subscription/subscription-plan';
import { tap, map } from 'rxjs/operators';
import BaseAlterResourcesConfirmationModal from './BaseAlterResourcesConfirmationModal';
import { InjectArguments } from 'good-injector-vue';

@Component({})
export default class ActivateResourcesConfirmationModal extends BaseAlterResourcesConfirmationModal {
  @Prop(Boolean) public readonly visible!: boolean;
  @Prop() public readonly model!: AlterResourcesRequest;

  private subscriptionPlanService!: SubscriptionPlanService;

  public currency = 'EUR';

  @InjectArguments()
  public mounted(subscriptionPlanService: SubscriptionPlanService) {
    this.subscriptionPlanService = subscriptionPlanService;
  }

  @Watch('model', { deep: false })
  public modelUpdated(): void {
    if (!this.model) {
      return;
    }

    this.subscriptionPlanService
      .getCurrentSubscriptionPlan()
      .pipe(
        map(plan => plan as SubscriptionPlan),
        tap((plan: SubscriptionPlan) => {
          this.currency = plan.currency;
          this.calculateCosts(this.model, plan);
        })
      )
      .subscribe();
  }

  public onConfirm(): void {
    this.$emit('confirm');
  }

  public onCancel(): void {
    this.$emit('cancel');
  }
}
