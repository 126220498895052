import { FeatureSwitchSettings } from '@/settings/feature-switch.settings';
import { DirectiveOptions } from 'vue';

const featureEnabledDirective: DirectiveOptions = {
  bind: (el: any, binding: any, vnode: any) => {
    if (!binding.value) {
      return;
    }

    const featureToggleSettings = new FeatureSwitchSettings();

    if (!featureToggleSettings.isFeatureEnabled(binding.value)) {
      const comment = document.createComment(' ');

      Object.defineProperty(comment, 'setAttribute', {
        value: () => undefined,
      });

      vnode.elm = comment;
      vnode.text = ' ';
      vnode.isComment = true;
      vnode.context = undefined;
      vnode.tag = undefined;
      vnode.data.directives = undefined;

      if (vnode.componentInstance) {
        vnode.$el = comment;
      }

      if (el.parentNode) {
        el.parentNode.replaceChild(comment, el);
      }
    }
  },
};

export default featureEnabledDirective;
