








































import { Component } from 'vue-property-decorator';
import { SubscriptionService } from '@/services/subscription.service';
import { tap } from 'rxjs/operators';
import { BaseWizardStepComponent } from './BaseWizardStepComponent';
import { SubscriptionPlan } from '@/models/subscription/subscription-plan';
import { InjectArguments } from 'good-injector-vue';
import { OnboardingStatusService } from '@/services/onboarding-status.service';
import { SubscriptionPlanMapper } from '@/mappers/subscription-plan.mapper';
import { SubscriptionModel } from '@/models/subscription/subscription-model';

@Component({
  components: {}
})
export default class Step5 extends BaseWizardStepComponent {
  private subscriptionService!: SubscriptionService;
  public isLoadingPlans = false;
  public isLoadingSelectedPlan = false;
  public stepIndex = 5;
  public plans: SubscriptionPlan[] = [];
  public selectedPlanId = String();
  public isPlanSelected = false;

  @InjectArguments()
  public mounted(
    onboardingStatusService: OnboardingStatusService,
    subscriptionService: SubscriptionService
  ): void {
    this.onMounted(onboardingStatusService);
    this.subscriptionService = subscriptionService;
    this.isLoadingPlans = true;
    this.isLoadingSelectedPlan = true;

    this.subscriptionService
      .getSubscriptionPlans()
      .pipe(
        tap(plans => {
          this.plans = SubscriptionPlanMapper.getSubscriptionPlans(plans);
          this.isLoadingPlans = false;
        })
      )
      .subscribe();

    this.subscriptionService
      .getSubscriptionPlan()
      .pipe(
        tap(plan => {
          if (plan) {
            this.selectedPlanId = (plan as SubscriptionModel).planId;
          } else {
            const recommendedPlan = this.plans.find(p => p.isRecommended);

            if (recommendedPlan) {
              this.selectedPlanId = recommendedPlan.id;
            }
          }

          this.isLoadingSelectedPlan = false;
        })
      )
      .subscribe();
  }

  public onPlanSelected(plan: SubscriptionPlan): void {
    this.selectedPlanId = plan.id;
  }

  public onAccept(): void {
    this.subscriptionService
      .saveSubscriptionPlan(this.selectedPlanId)
      .pipe(tap(() => this.goToNextStep()))
      .subscribe();
  }
}
