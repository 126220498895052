




import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class SubscriptionStatus extends Vue {
  @Prop(Boolean) public readonly model!: boolean;
}
