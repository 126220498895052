














































































































import { Component, Vue } from 'vue-property-decorator';
import VueRouter, { Route } from 'vue-router';

import { DashboardMenuItems } from '@/constants/dashboard.constants';
import DashboardMenuItem from '@/models/dashboard/menu-item';
import DashboardSubmenuItem from '@/models/dashboard/submenu-item';
import { LocalStorageCacheService } from '@/services/local-storage-cache.service';
import { InjectArguments } from 'good-injector-vue';
import { wasCardPaymentInfoShowed } from '@/constants/common.constants';
import { SubscriptionService } from '@/services/subscription.service';

@Component({
  components: {}
})
export default class Dashboard extends Vue {
  public expandedMenuItemIndex: number | null = null;
  public activeMenuItemIndex: number | null = null;
  public menuItems = DashboardMenuItems;
  public isCardPaymentConfirmationModalVisible = false;
  public tenantId = '';

  private readonly $route!: Route;
  private readonly $router!: VueRouter;
  private localStorageCacheService!: LocalStorageCacheService;
  private subscriptionService!: SubscriptionService;

  @InjectArguments()
  public mounted(
      localStorageCacheService: LocalStorageCacheService,
      subscriptionService: SubscriptionService
  ) {
    this.localStorageCacheService = localStorageCacheService;
    this.subscriptionService = subscriptionService;
    this.checkIfCardPaymentInfoDialogShouldBeShowed();
  }
  
  public created(): void {
    const initialActiveMenuItemIndex = this.getActiveRouteNavItemIndex();
    this.expandedMenuItemIndex = initialActiveMenuItemIndex;
    this.activeMenuItemIndex = initialActiveMenuItemIndex;
  }

  public onMenuItemClick(e: Event, menuItem: DashboardMenuItem): void {
    if (menuItem.submenuItems.length > 0) {
      this.expandedMenuItemIndex = this.expandedMenuItemIndex === menuItem.index ? null : menuItem.index;
    } else {
      this.activeMenuItemIndex = menuItem.index;
    }
    e.stopPropagation();
    e.preventDefault();
  }

  public onSubmenuItemClick(menuItemIndex: number): void {
    this.activeMenuItemIndex = menuItemIndex;
  }

  public onSupportClick(): void {
    const supportAddress = process.env.VUE_APP_SUPPORT_EMAIL;
    window.location.assign(`mailto:${supportAddress}`);
  }

  public onCardPaymentConfirmationDialogCancel(): void {
    this.onCardPaymentConfirmationDialogClose();
  }

  public onCardPaymentConfirmationDialogSave(): void {
    this.$router.push({ path: '/dashboard/subscription', query: { editPlan: 'true' } });
    this.onCardPaymentConfirmationDialogClose();
  }

  private checkIfCardPaymentInfoDialogShouldBeShowed(): void {
    this.isCardPaymentConfirmationModalVisible = !this.localStorageCacheService.getItem(wasCardPaymentInfoShowed);
  }

  private onCardPaymentConfirmationDialogClose(): void {
    this.isCardPaymentConfirmationModalVisible = false;
    this.localStorageCacheService.setItem(wasCardPaymentInfoShowed, true, false);
  }

  private getActiveRouteNavItemIndex(): number | null {
    const activeRouteName = this.$route.name;
    const activeRoutePath = this.$route.path;
    const activeMenuItem = this.menuItems.find(menuItem => menuItem.link === activeRouteName
      || menuItem.submenuItems.some((submenuItem: DashboardSubmenuItem) => submenuItem.link === activeRouteName
        || (submenuItem.exactActiveToCheck ? activeRoutePath.includes(submenuItem.exactActiveToCheck) : false))
    );
    return activeMenuItem ? JSON.parse(JSON.stringify(activeMenuItem.index)) : null;
  }
}
