export class CurrencyMapper {
  public static getCurrencySign(isoCode: string): string {
    switch (isoCode.toLowerCase()) {
      case 'eur':
        return '€';
      default:
        return '€';
    }
  }
}
