


































import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class EmailConsentLink extends Vue {
  public remainingSeconds = 10;

  public get supportEmail(): string {
    return process.env.VUE_APP_SUPPORT_EMAIL;
  }

  public get bookadoUrl(): string {
    return process.env.VUE_APP_EMAIL_REDIRECT_URI;
  }

  public mounted(): void {
    const timeInterval = setInterval(() => {
      this.remainingSeconds = this.remainingSeconds - 1;
      if (this.remainingSeconds <= 0) {
        clearInterval(timeInterval);
        window.location.href = this.bookadoUrl;
      }
    }, 1000);
  }
}
