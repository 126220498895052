export class StencilPropertiesBuilder {
  public static getStencilLinesConfig(readonly: boolean): any {
    return {
      north: !readonly,
      west: !readonly,
      south: !readonly,
      east: !readonly,
    };
  }
  public static getStencilHandlersConfig(readonly: boolean): any {
    return {
      north: !readonly,
      west: !readonly,
      south: !readonly,
      east: !readonly,
      eastNorth: !readonly,
      westNorth: !readonly,
      westSouth: !readonly,
      eastSouth: !readonly,
    };
  }
}
