





























import { Component } from 'vue-property-decorator';
import { Subject } from 'rxjs';
import { InjectArguments } from 'good-injector-vue';

import { OnboardingStatusService } from '@/services/onboarding-status.service';
import { BaseWizardStepComponent } from './BaseWizardStepComponent';
import { IOrganizationFormValidity } from '@/models/organization-form-validity.model';

@Component({
  components: {}
})
export default class Step2 extends BaseWizardStepComponent {
  public isLoading = false;
  public stepIndex = 2;
  public onSubmit$: Subject<boolean> = new Subject<boolean>();
  public organizationFormValidity: IOrganizationFormValidity = {
    isEmpty: false
  };

  @InjectArguments()
  public mounted(onboardingStatusService: OnboardingStatusService): void {
    this.onMounted(onboardingStatusService);
  }

  public onContinue(): void {
    this.onSubmit$.next(true);
  }

  public onFormSubmitted(isSuccess: boolean): void {
    if (isSuccess) {
      this.goToNextStep();
    }
  }

  public onLoadingChanged(isLoading: boolean): void {
    this.isLoading = isLoading;
  }
}
