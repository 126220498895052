import Vue from 'vue';
import Router from 'vue-router';
import authMiddleware from './middlewares/auth';
import dashboardGuard from './middlewares/dashboard-guard';
import onboardingGuard from './middlewares/onboarding-guard';
import simpleModeGuard from './middlewares/simple-mode-guard';
import Auth from './views/Auth.vue';
import AuthAdminConsent from './views/AuthAdminConsent.vue';
import Dashboard from './views/dashboard/Dashboard.vue';
import InvoiceDetails from './views/dashboard/InvoiceDetails.vue';
import Invoices from './views/dashboard/Invoices.vue';
import Organization from './views/dashboard/Organization.vue';
import Instructions from './views/dashboard/Instructions.vue';
import Settings from './views/dashboard/settings/Settings.vue';
import Resources from './views/dashboard/Resources.vue';
import ResourceDetails from './views/dashboard/ResourceDetails.vue';
import Subscription from './views/dashboard/Subscription.vue';
import Team from './views/dashboard/Team.vue';
import EmailConsentLink from './views/EmailConsentLink.vue';
import Home from './views/Home.vue';
import Login from './views/Login.vue';
import PageNotFound from './views/PageNotFound.vue';
import SimpleModeLander from './views/simpleMode/SimpleModeLander.vue';
import SimpleModeDashboard from './views/simpleMode/SimpleModeDashboard.vue';
import Unauthenticated from './views/Unauthenticated.vue';
import Unauthorized from './views/Unauthorized.vue';
import Step1 from './views/wizard/Step1.vue';
import Step2 from './views/wizard/Step2.vue';
import Step3 from './views/wizard/Step3.vue';
import Step4 from './views/wizard/Step4.vue';
import Step5 from './views/wizard/Step5.vue';
import StripeCheckoutCancel from './views/StripeCheckoutCancel.vue';
import StripeCheckoutSuccess from './views/StripeCheckoutSuccess.vue';
import onboardingStatusGuard from './middlewares/onboarding-status-guard';
import SettingsInvoice from '@/views/dashboard/settings/SettingsInvoice.vue';
import SettingsAdminConsent from '@/views/dashboard/settings/SettingsAdminConsent.vue';
import { RoutesSettings } from '@/router.constants';
import SettingsDefaultImageAndDesc from '@/views/dashboard/settings/SettingsDefaultImageAndDesc.vue';
import OnboardingAlreadyStarted from '@/views/OnboardingAlreadyStarted.vue';

let fromRoute: any;

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/auth', name: 'auth', component: Auth },
    {
      path: '/auth/admin-consent-callback',
      name: 'admin-consent',
      component: AuthAdminConsent,
    },
    { path: '/login', name: 'login', component: Login },
    { path: '/unauthorized', name: 'unauthorized', component: Unauthorized },
    {
      path: '/onboarding-already-started',
      name: 'onboarding-already-started',
      component: OnboardingAlreadyStarted,
    },
    {
      path: '/unauthenticated',
      name: 'unauthenticated',
      component: Unauthenticated,
    },
    {
      path: '/link/admin-consent-callback',
      name: 'emailConsentLink',
      component: EmailConsentLink,
    },
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/step1',
      name: 'step1',
      component: Step1,
      meta: { middleware: [onboardingGuard, onboardingStatusGuard] },
    },
    {
      path: '/step2',
      name: 'step2',
      component: Step2,
      meta: {
        middleware: [authMiddleware, onboardingGuard, onboardingStatusGuard],
      },
    },
    {
      path: '/step3',
      name: 'step3',
      component: Step3,
      meta: {
        middleware: [authMiddleware, onboardingGuard, onboardingStatusGuard],
      },
    },
    {
      path: '/step4',
      name: 'step4',
      component: Step4,
      meta: {
        middleware: [authMiddleware, onboardingGuard, onboardingStatusGuard],
      },
    },
    {
      path: '/step5',
      name: 'step5',
      component: Step5,
      meta: {
        middleware: [authMiddleware, onboardingGuard, onboardingStatusGuard],
      },
    },
    { path: '/step6', redirect: '/' },
    {
      path: '/stripe-checkout-cancel',
      name: 'stripe-checkout-cancel',
      component: StripeCheckoutCancel,
      meta: {
        middleware: [authMiddleware, onboardingGuard, onboardingStatusGuard],
      },
    },
    {
      path: '/stripe-checkout-success',
      name: 'stripe-checkout-success',
      component: StripeCheckoutSuccess,
      meta: {
        middleware: [authMiddleware],
      },
    },
    {
      path: '/dashboard',
      component: Dashboard,
      meta: {
        middleware: [authMiddleware, onboardingGuard, onboardingStatusGuard],
      },
      children: [
        // todo: replace in future - start
        { path: '/', redirect: 'resources/room' },
        // { path: '/', name: 'dashboard', component: DashboardHome, meta: { middleware: [authMiddleware, dashboardGuard, onboardingStatusGuard] } },
        // todo: replace in future - end
        {
          path: 'organization',
          name: 'organization',
          component: Organization,
          meta: {
            middleware: [authMiddleware, dashboardGuard, onboardingStatusGuard],
          },
        },
        {
          path: 'instructions',
          name: 'instructions',
          component: Instructions,
          meta: {
            middleware: [authMiddleware, dashboardGuard, onboardingStatusGuard],
          },
        },
        {
          path: RoutesSettings.Root,
          name: RoutesSettings.Root,
          component: Settings,
          meta: {
            middleware: [authMiddleware, dashboardGuard, onboardingStatusGuard],
          },
          redirect: `${RoutesSettings.Root}/${RoutesSettings.Invoice}`,
          children: [
            {
              path: RoutesSettings.Invoice,
              name: RoutesSettings.Invoice,
              component: SettingsInvoice,
              meta: { middleware: [authMiddleware] },
            },
            {
              path: RoutesSettings.AdministrationConsent,
              name: RoutesSettings.AdministrationConsent,
              component: SettingsAdminConsent,
              meta: { middleware: [authMiddleware] },
            },
            {
              path: RoutesSettings.DefaultImageAndDescription,
              name: RoutesSettings.DefaultImageAndDescription,
              component: SettingsDefaultImageAndDesc,
              meta: { middleware: [authMiddleware] },
            },
            { path: '*', redirect: RoutesSettings.Invoice },
          ],
        },
        {
          path: 'subscription',
          name: 'subscription',
          component: Subscription,
          meta: {
            middleware: [authMiddleware, dashboardGuard, onboardingStatusGuard],
          },
        },
        {
          path: 'subscription/:editPlan',
          name: 'subscriptionWithEditPlan',
          component: Subscription,
          meta: {
            middleware: [authMiddleware, dashboardGuard, onboardingStatusGuard],
          },
        },
        {
          path: 'resources',
          name: 'resources',
          component: Resources,
          meta: {
            middleware: [authMiddleware, dashboardGuard, onboardingStatusGuard],
          },
        },
        {
          path: 'resources/:resourceType',
          name: 'resourcesWithResourceType',
          component: Resources,
          meta: {
            middleware: [authMiddleware, dashboardGuard, onboardingStatusGuard],
          },
        },
        {
          path: 'resources/:resourceType/:currentPageNumber',
          name: 'resourcesWithResourceTypeAndCurrentPageNumber',
          component: Resources,
          meta: {
            middleware: [authMiddleware, dashboardGuard, onboardingStatusGuard],
          },
        },
        {
          path: 'resource/:id',
          name: 'resourceDetails',
          component: ResourceDetails,
          meta: {
            middleware: [authMiddleware, dashboardGuard, onboardingStatusGuard],
          },
        },
        {
          path: 'resource/:id/:currentPageNumber',
          name: 'resourceDetailsWithIdAndCurrentPageNumber',
          component: ResourceDetails,
          meta: {
            middleware: [authMiddleware, dashboardGuard, onboardingStatusGuard],
          },
        },
        {
          path: 'team',
          name: 'team',
          component: Team,
          meta: {
            middleware: [authMiddleware, dashboardGuard, onboardingStatusGuard],
          },
        },
        {
          path: 'invoices',
          name: 'invoices',
          component: Invoices,
          meta: { middleware: [authMiddleware] },
        },
        {
          path: 'invoices/:currentPageNumber',
          name: 'invoiceDetailsWithCurrentPageNumber',
          component: Invoices,
          meta: { middleware: [authMiddleware] },
        },
        {
          path: 'invoices/:id',
          name: 'invoiceDetails',
          component: InvoiceDetails,
          meta: { middleware: [authMiddleware] },
        },
        {
          path: 'invoices/:id/:currentPageNumber',
          name: 'invoiceDetailsWithIdAndCurrentPageNumber',
          component: InvoiceDetails,
          meta: { middleware: [authMiddleware] },
        },
      ],
    },
    {
      path: '/simple-landing',
      name: 'simple-landing',
      component: SimpleModeLander,
      meta: { middleware: [simpleModeGuard] },
    },
    {
      path: '/simple-dashboard',
      name: 'simple-dashboard',
      component: SimpleModeDashboard,
      meta: { middleware: [authMiddleware, simpleModeGuard] },
    },
    {
      path: '*',
      component: PageNotFound,
    },
  ],
});

// @ts-ignore
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) {
    return context.next;
  }

  // @ts-ignore
  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

// https://markus.oberlehner.net/blog/implementing-a-simple-middleware-with-vue-router/
router.beforeEach((to, from, next) => {
  fromRoute = from;

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };

    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;

export function getFromRoute(): any {
  return fromRoute;
}
