import { AxiosResponse, ResponseType } from 'axios';
import { SupportsInjection } from 'good-injector';
import { from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiClientService } from './api-client.service';
import { AuthenticationService } from './authentication/authentication.service';

@SupportsInjection
export class FileDownloadService extends ApiClientService {
  public constructor(authenticationService: AuthenticationService) {
    super(authenticationService, `${process.env.VUE_APP_BOOKADO_API_URI}/`);
  }

  public getPdf(url: string): Observable<Blob | null> {
    const responseType: ResponseType = 'blob';
    const options = {
      ...this.getClientOptions(),
      responseType,
    };

    return from(this.client.get<Blob>(url, options)).pipe(
      map((response: AxiosResponse<Blob>) => response.data),
      catchError(() => of(null))
    );
  }
}
