


import { Component, Vue } from 'vue-property-decorator';
import { ConsentStatus } from '@/models/consent-status';
import VueRouter, { Route } from 'vue-router';

@Component({
  components: {}
})
export default class AuthAdminConsent extends Vue {
  private readonly $route!: Route;
  private readonly $router!: VueRouter;

  public mounted() {
    const isAdminConsent = this.$route.query['admin_consent'] && this.$route.query['admin_consent'].toString().toLowerCase() === 'true';
    const hasError = !!this.$route.query['error'];
    const status = isAdminConsent && !hasError ? ConsentStatus.Completed : ConsentStatus.Error;

    this.goToConsentStepWithStatus(status);
  }

  private goToConsentStepWithStatus(consentStatus: ConsentStatus): void {
    this.$router.push(`/step4?status=${consentStatus}`);
  }
}
