import { FeatureSwitchSettings } from '@/settings/feature-switch.settings';
import { DashboardFeature } from '@/settings/feature-switch.settings';

export default function dashboardGuard({
  next,
  router,
}: {
  next: any;
  router: any;
}) {
  const featureSwitchSettings = new FeatureSwitchSettings();

  if (!featureSwitchSettings.isFeatureEnabled(DashboardFeature)) {
    router.push({ path: '/' });
  }

  return next();
}
