



















import { Component, Vue, Prop } from 'vue-property-decorator';
import { TeamMember } from '@/models/team-member';

@Component({})
export default class TeamMemberDetailsAddModal extends Vue {
  @Prop(Boolean) public readonly visible!: boolean;

  public model: TeamMember = { isPrimaryContact: false };

  public onCancel(): void {
    this.$emit('cancel');
  }

  public onTeamMemberAdded(): void {
    this.$emit('added', this.model);
    this.model = { isPrimaryContact: false };
  }
}
