import { CroppedImage } from './cropped-image';
import { CropperCoordinates } from './cropper-change';
import {
  AlterResourceImageTypes,
  ResourceImageType,
} from './resource-image-type';
import {
  ResourceCrop,
  ResourceImageDto,
  ResourceImagesDto,
} from './resource-images-dto';
import { cloneDeep } from 'lodash';

export class CropperData {
  constructor(dto: ResourceImagesDto, ignoreIsDefaultSetting = false) {
    this.defaultImage = this.getCropperPreviewImageData(dto);
    this.customImage = this.getCropperImageData(
      dto,
      false,
      ignoreIsDefaultSetting
    );
  }

  public defaultImage?: CropperImageData;
  public customImage?: CropperImageData;
  public isPristine = true;

  public get hasCustomImage(): boolean {
    return !!this.customImage;
  }

  public setCustomImage(image: string): void {
    this.customImage = {
      originalImage: image,
      coordinatesSets: {},
      croppedImages: {},
      isDefault: false,
    };
    this.isPristine = false;
  }

  public setCustomCroppedImage(
    model: CroppedImage,
    ignorePristineCheck = false
  ): void {
    if (this.customImage) {
      this.customImage.croppedImages[model.type] = model.image;

      const isCoordsChanged = this.isCoordsChanged(model);

      this.trySetCoords(model);

      if (this.isPristine && isCoordsChanged && !ignorePristineCheck) {
        this.isPristine = false;
      }
    }
  }

  public validateCrops(): boolean {
    let isValid = true;

    AlterResourceImageTypes.forEach((type) => {
      if (!this.customImage) {
        isValid = false;
      } else {
        isValid =
          isValid &&
          this.customImage.coordinatesSets.hasOwnProperty(type) &&
          this.customImage.croppedImages.hasOwnProperty(type);
      }
    });

    return isValid;
  }

  private trySetCoords(model: CroppedImage): void {
    if (!this.customImage || !model.coordinates) {
      return;
    }

    const areCoordsValid = this.validateCoordinates(model.coordinates);

    if (areCoordsValid) {
      this.customImage.coordinatesSets[model.type] = model.coordinates;
    } else {
      delete this.customImage.coordinatesSets[model.type];
    }
  }

  private validateCoordinates(coordinates: CropperCoordinates): boolean {
    return (
      coordinates.top >= 0 &&
      coordinates.left >= 0 &&
      coordinates.width > 0 &&
      coordinates.height > 0
    );
  }

  private isCoordsChanged(model: CroppedImage): boolean {
    let currentCoords = {};

    if (this.customImage) {
      currentCoords = (this.customImage.coordinatesSets || [])[model.type];
    }

    return JSON.stringify(currentCoords) !== JSON.stringify(model.coordinates);
  }

  private getCropperImageData(
    dto: ResourceImagesDto,
    takeDefault: boolean,
    ignoreIsDefaultSetting = false
  ): CropperImageData | undefined {
    const photos = ignoreIsDefaultSetting
      ? cloneDeep(dto.photos)
      : (dto.photos || []).filter((photo) => photo.default === takeDefault);
    const originalPhoto = photos.find(
      (photo) => photo.type === ResourceImageType.Original
    );

    if (photos.length === 0 || !originalPhoto) {
      return undefined;
    }

    const alteredPhotos = photos.filter((photo) =>
      AlterResourceImageTypes.some((type) => type === photo.type)
    );

    return {
      originalImage: originalPhoto.image
        ? originalPhoto.image
        : originalPhoto.url,
      coordinatesSets: this.getCropperImageCoordinates(alteredPhotos),
      croppedImages: this.getCropperImages(alteredPhotos),
      isDefault: takeDefault,
    };
  }

  private getCropperPreviewImageData(
    dto: ResourceImagesDto
  ): CropperImageData | undefined {
    const data = this.getCropperImageData(dto, true);

    if (data) {
      AlterResourceImageTypes.forEach((type) => {
        const coords = data.coordinatesSets[type];

        if (!coords || !this.validateCoordinates(coords)) {
          data.coordinatesSets[type] = this.getDefaultImageCoordinates(type);
        }
      });
    }

    return data;
  }

  private getDefaultImageCoordinates(
    type: ResourceImageType
  ): CropperCoordinates {
    switch (type) {
      case ResourceImageType.Mobile:
        return this.parseXywh(
          process.env.VUE_APP_DEFAULT_COORDINATES_XYWH_MOBILE
        );
      case ResourceImageType.Teams:
        return this.parseXywh(
          process.env.VUE_APP_DEFAULT_COORDINATES_XYWH_TEAMS
        );
      default:
        return {} as CropperCoordinates;
    }
  }

  private parseXywh(value: string): CropperCoordinates {
    if (!value || value.length === 0 || value.split(';').length !== 4) {
      return {} as CropperCoordinates;
    }

    const values = value.split(';').map((coord) => Number(coord));

    return {
      left: values[0],
      top: values[1],
      width: values[2],
      height: values[3],
    };
  }

  private getCropperImageCoordinates(photos: ResourceImageDto[]): {
    [type: string]: CropperCoordinates;
  } {
    const result: { [type: string]: CropperCoordinates } = {};

    (photos || []).forEach((photo) => {
      result[photo.type] = this.getCropperCoordinates(photo.crop);
    });

    return result;
  }

  private getCropperImages(photos: ResourceImageDto[]): {
    [type: string]: string;
  } {
    const result: { [type: string]: string } = {};

    (photos || []).forEach((photo) => {
      result[photo.type] = photo.image ? photo.image : photo.url;
    });

    return result;
  }

  private getCropperCoordinates(
    crop: ResourceCrop | undefined
  ): CropperCoordinates {
    if (!crop) {
      return {} as CropperCoordinates;
    }

    return {
      width: crop.w,
      height: crop.h,
      left: crop.x,
      top: crop.y,
    };
  }
}

export interface CropperImageData {
  originalImage: string;
  coordinatesSets: {
    [type: string]: CropperCoordinates;
  };
  croppedImages: { [type: string]: string };
  isDefault: boolean;
}
