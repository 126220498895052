import { SupportsInjection } from 'good-injector-vue';

import { AuthenticationClientType } from './authentication/authentication-client-type';
import { AuthenticationService } from './authentication/authentication.service';

@SupportsInjection
export class ConsentLinkService {
  constructor(private authenticationService: AuthenticationService) {}

  public getConsentLink(): string {
    switch (this.authenticationService.clientType) {
      case AuthenticationClientType.Microsoft:
        return ConsentLinkService.getMicrosoftConsentLink();
      case AuthenticationClientType.Google:
        return ConsentLinkService.getGoogleConsentLink();
      default:
        return String();
    }
  }

  private static getMicrosoftConsentLink(): string {
    const clientId = process.env.VUE_APP_MICROSOFT_CLIENTID;
    const redirectUrl = `${location.origin}/auth/admin-consent-callback`;
    return `https://login.microsoftonline.com/common/adminconsent?client_id=${clientId}&state=12345&redirect_uri=${redirectUrl}`;
  }

  private static getGoogleConsentLink(): string {
    return String();
  }
}
