import { SubscriptionStatus } from '@/models/subscription/subscription-status.model';

export const SubscriptionStatusLabels = {
  [SubscriptionStatus.FreeTrial]: 'Free Trial',
  [SubscriptionStatus.FreeTrialFinished]: 'Free Trial Finished',
  [SubscriptionStatus.FreeTrialStop]: 'Free Trial Stopped',
  [SubscriptionStatus.Renew]: 'Renew',
  [SubscriptionStatus.Active]: 'Active',
  [SubscriptionStatus.Deactivated]: 'Deactivated',
  [SubscriptionStatus.Closed]: 'Closed',
};
