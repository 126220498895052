












































































import { Component, Vue } from 'vue-property-decorator';
import { tap } from 'rxjs/operators';
import { InjectArguments } from 'good-injector-vue';

import { SubscriptionService } from '@/services/subscription.service';
import { SubscriptionContactsDto } from '@/models/subscription/subscription-contacts-dto';
import { SubscriptionContactDto } from '@/models/subscription/subscription-contact-dto';
import { TeamMemberEditModel } from '@/models/team-member-edit-model';
import { ContactType } from '@/models/contact-type.model';
import { TeamMemberMapper } from '@/mappers/team-member.mapper';
import { TeamMember } from '@/models/team-member';

@Component({
  components: {}
})
export default class Team extends Vue {
  private subscriptionService!: SubscriptionService;
  public editedTeamMember: TeamMemberEditModel = {} as TeamMemberEditModel;
  public teamMembers: TeamMember[] = [];
  public isTeamMemberAddModalVisible = false;
  public isTeamMemberEditModalVisible = false;
  public isDeleteTeamMemberConfirmationModalVisible = false;
  public deleteMemberConfirmationModalTitleConfirmButtonLabel = 'Delete';
  public deleteMemberConfirmationModalTitle = 'Remove team member?';
  public isLoading = false;
  private memberToDelete?: TeamMember;

  @InjectArguments()
  public mounted(subscriptionService: SubscriptionService): void {
    this.subscriptionService = subscriptionService;
    this.refreshModel();
  }

  public addMember(): void {
    this.isTeamMemberAddModalVisible = true;
  }

  public onTeamMemberAddModalCancel(): void {
    this.isTeamMemberAddModalVisible = false;
  }

  public onTeamMemberAddModalAdded(model: TeamMember): void {
    this.isTeamMemberAddModalVisible = false;

    const teamMembers = [...this.teamMembers, model];

    const primaryTeamMember = teamMembers.find(m => m.isPrimaryContact) as TeamMember;
      const contactsToAdd: SubscriptionContactsDto = {
        phone: primaryTeamMember.phoneNumber as string,
        sendInvoices: primaryTeamMember.isInvoicesRecipient === true,
        additionalContacts: TeamMemberMapper.getContacts(teamMembers.filter(m => !m.isPrimaryContact))
    };

    this.subscriptionService
      .addTeamMembers(contactsToAdd)
      .pipe(tap(() => this.refreshModel()))
      .subscribe();
  }

  public editMember(teamMember: TeamMember, e: any) {
    this.editedTeamMember = { teamMember: { ...teamMember }, identifier: teamMember.email as string };
    this.isTeamMemberEditModalVisible = true;
    e.stopPropagation();
    e.preventDefault();
  }

  public onTeamMemberEditModalCancel(): void {
    this.isTeamMemberEditModalVisible = false;
  }

  public onTeamMemberEditModalEdited(event: any): void {
    const model: TeamMemberEditModel = event.model;
    const setAsPrimaryUser: boolean = event.setAsPrimaryUser; 

    this.isTeamMemberEditModalVisible = false;
    
    const teamMembers = [...this.teamMembers];
    const index = teamMembers.findIndex(member => member.email === model.identifier);

    teamMembers[index] = model.teamMember;
    const contactToUpdate = this.mapTeamMemberToSubscriptionContactDto(this.editedTeamMember.teamMember, setAsPrimaryUser);

    this.subscriptionService.updateTeamMember(contactToUpdate)
      .pipe(tap(() => this.refreshModel()))
      .subscribe();
  }

  public deleteTeamMember(teamMemberToDelete: TeamMember): void {
    this.isTeamMemberEditModalVisible = false;

    if (!teamMemberToDelete.isPrimaryContact) {
      this.subscriptionService.removeTeamMember(teamMemberToDelete.email as string).pipe(
        tap(() => this.refreshModel())
      ).subscribe();
    }
  }

  public onTeamMemberDelete(teamMember: TeamMember): void {
    this.memberToDelete = teamMember;
    this.isDeleteTeamMemberConfirmationModalVisible = true;
  }

  public onDeleteConfirmed(): void {
    if (this.memberToDelete) {
      this.deleteTeamMember(this.memberToDelete);
    }
    this.onDeleteCancelled();
  }

  public onDeleteCancelled(): void {
    this.isDeleteTeamMemberConfirmationModalVisible = false;
  }

  private refreshModel(): void {
    this.isLoading = true;

    this.subscriptionService
      .getSubscription()
      .pipe(
        tap(data => {
          this.teamMembers = TeamMemberMapper.getTeamMembers(data)
            .sort((a, b) => a.isPrimaryContact && !b.isPrimaryContact ? -1 : 1);
          this.isLoading = false;
        })
      )
      .subscribe();
  }

  private mapTeamMemberToSubscriptionContactDto(teamMember: TeamMember, isPrimaryContact: boolean): SubscriptionContactDto {
    return {
        name: teamMember.name as string,
        surname: teamMember.surname as string,
        email: teamMember.email as string,
        phone: teamMember.phoneNumber as string,
        sendInvoices: teamMember.isInvoicesRecipient === true,
        contactType: isPrimaryContact ? ContactType.Primary : ContactType.Secondary
      };
  }
}
