import { CurrencyMapper } from './currency.mapper';
import { capitalizeFirstLetter } from '@/lib/utils';
import { SubscriptionPlan } from '@/models/subscription/subscription-plan';
import { SubscriptionPlanDto } from '@/models/subscription/subscription-plan-dto';
import { ResourceType } from '@/models/resource-type';

export class SubscriptionPlanMapper {
  public static getSubscriptionPlans(
    plans: SubscriptionPlanDto[]
  ): SubscriptionPlan[] {
    return plans.map((member, index) =>
      SubscriptionPlanMapper.getSubscriptionPlan(member, index)
    );
  }

  public static getSubscriptionPlan(
    planDto: SubscriptionPlanDto,
    tier: number
  ): SubscriptionPlan {
    const currency = planDto.resourceTypes[0].currency;
    const currencySign = CurrencyMapper.getCurrencySign(currency);
    const roomResource = planDto.resourceTypes.find(
      (rt) => rt.type === ResourceType.Room
    );
    const deskResource = planDto.resourceTypes.find(
      (rt) => rt.type === ResourceType.Desk
    );
    return {
      id: planDto.paymentPlan,
      name: SubscriptionPlanMapper.getPlanName(planDto.paymentPlan),
      currency: currencySign,
      tier,
      pricePerRoom: roomResource ? roomResource.price : 0,
      pricePerDesk: deskResource ? deskResource.price : undefined,
      features: SubscriptionPlanMapper.getFeatures(planDto),
      isAvailable: planDto.available,
      isRecommended: planDto.recommended,
    };
  }

  private static getPlanName(planId: string): string {
    if (!planId || planId.length === 0) {
      return String();
    }
    return capitalizeFirstLetter(planId);
  }

  private static getFeatures(planDto: SubscriptionPlanDto): string[] {
    switch (planDto.paymentPlan) {
      case 'standard':
        return [
          'Search and book rooms on mobile phones (IOS and Android)',
          'Bookado for MS Teams',
        ];
      case 'enterprise':
        return [
          'Search and book rooms on mobile phones (IOS and Android)',
          'Desk reservation',
          'Bookado for MS Teams',
          'Office navigation',
        ];
      default:
        return ['Search and book rooms on mobile phones (IOS and Android)'];
    }
  }
}
