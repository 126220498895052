import { render, staticRenderFns } from "./OnboardingAlreadyStarted.vue?vue&type=template&id=f4f8dd42&scoped=true&"
import script from "./OnboardingAlreadyStarted.vue?vue&type=script&lang=ts&"
export * from "./OnboardingAlreadyStarted.vue?vue&type=script&lang=ts&"
import style0 from "./OnboardingAlreadyStarted.vue?vue&type=style&index=0&id=f4f8dd42&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4f8dd42",
  null
  
)

export default component.exports