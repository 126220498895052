











import { Component, Vue, Prop } from 'vue-property-decorator';

import { PaymentProcessingNetworkType } from '@/models/stripe/payment-processing-network.model';

@Component({
  components: {}
})
export default class CardDetails extends Vue {
  @Prop(String) public readonly last4Digits?: string;
  @Prop(String) public readonly expirationDate?: string;
  @Prop(String) public readonly billingName?: string;
  @Prop(String) public readonly paymentProcessingNetwork!: PaymentProcessingNetworkType;

  public paymentProcessingNetworkLogoPath = '';

  public mounted(): void {
    if (this.paymentProcessingNetwork) {
      this.paymentProcessingNetworkLogoPath = require(`../../assets/svg/payment-processing-networks-logos/${this.paymentProcessingNetwork}.svg`);
    }
  }
}
