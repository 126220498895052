










import { Component, Prop, Vue } from 'vue-property-decorator';
import { InjectArguments } from 'good-injector-vue';
import { filter, map, catchError, switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import { StripeCheckoutContext } from '@/models/stripe/stripe-checkout-context.model';
import { CachedStripeCheckoutContextService } from '@/services/cached-stripe-checkout-context.service';
import { AuthenticationService } from '@/services/authentication/authentication.service';
import { StripePaymentsService } from '@/services/stripe-payments.service';
import { SubscriptionService } from '@/services/subscription.service';

@Component({
  components: {}
})

export default class StripePaymentProviderCheckout extends Vue {
  @Prop(String) public readonly stripeCustomerId!: string;
  @Prop(String) public readonly stripeCheckoutContext!: StripeCheckoutContext;
  @Prop(String) public readonly label!: string;

  private stripePaymentsService!: StripePaymentsService;
  private authenticationService!: AuthenticationService;
  private subscriptionService!: SubscriptionService;
  private cachedStripeCheckoutContextService!: CachedStripeCheckoutContextService;

  public isLoading = false;
  public isStripeErrorShowed = false;
  public loaderSpinnerData = {
    text: 'Opening Stripe checkout session...'
  };

  @InjectArguments()
  public mounted(
      stripePaymentsService: StripePaymentsService,
      authenticationService: AuthenticationService,
      cachedStripeCheckoutContextService: CachedStripeCheckoutContextService,
      subscriptionService: SubscriptionService
  ): void {
    this.stripePaymentsService = stripePaymentsService;
    this.authenticationService = authenticationService;
    this.cachedStripeCheckoutContextService = cachedStripeCheckoutContextService;
    this.subscriptionService = subscriptionService;
  }

  public get labelToShow() {
    return this.label && this.label.length > 0 ? this.label : 'Provide Credit Card details';
  }

  public submit(): void {
    this.isLoading = true;
    this.cachedStripeCheckoutContextService.setCachedStripeCheckoutContext(this.stripeCheckoutContext, this.stripeCustomerId);

    this.stripePaymentsService.createCustomer()
        .pipe(
            switchMap(() => this.stripePaymentsService.createSession()),
            filter((session) => !!session),
            map((session) => session.url),
            catchError(() => {
              this.isLoading = false;
              this.isStripeErrorShowed = true;
              return EMPTY;
            })
        )
        .subscribe((sessionUrl) => {
              if (sessionUrl) {
                this.isStripeErrorShowed = false;
                window.open(sessionUrl, '_self');
              } else {
                console.error('Couldn\'t get new session URL');
              }
            }
        );
  }
};
