

















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class PreviousNextResourceDetailsButtons extends Vue {
  @Prop() public readonly previousId?: string;
  @Prop() public readonly nextId?: string;
}
