import { SupportsInjection } from 'good-injector';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  AlterResourceImageTypes,
  ResourceImageType,
} from '@/models/resource-details/resource-image-type';
import {
  SaveCropConfiguration,
  SaveResourceCrop,
} from '@/models/resource-details/save-crop-configuration';
import {
  ResourceDefaultDetailsDto,
  ResourceImagesDto,
} from '@/models/resource-details/resource-images-dto';
import { ResourceImageDto } from '@/models/resource-details/resource-images-dto';
import { CropperImageData } from '@/models/resource-details/cropper-data';
import { ResourceType } from '@/models/resource-type';
import { ApiResponse } from '@/models/api-response';
import { ApiClientService } from './api-client.service';
import { AuthenticationService } from './authentication/authentication.service';

@SupportsInjection
export class SubscriptionResourceImagesService extends ApiClientService {
  public constructor(authenticationService: AuthenticationService) {
    super(authenticationService, `${process.env.VUE_APP_BOOKADO_API_URI}/`);
  }

  public getSubscriptionResourceImages(
    resourceId: string,
    onlyActivePhotos = false
  ): Observable<ResourceImagesDto> {
    return this.get<ResourceImagesDto>(
      `api/resources/${resourceId}/details?onlyActivePhotos=${onlyActivePhotos}`
    ).pipe(
      switchMap((data) => {
        const requests = data.photos.map((photo) =>
          this.fetchPhotoImage(photo)
        );

        return forkJoin(requests).pipe(
          map((photos: ResourceImageDto[]) => {
            return { ...data, photos };
          })
        );
      })
    );
  }

  public getSubscriptionDefaultResourceDetails(
    resourceType: ResourceType
  ): Observable<ResourceDefaultDetailsDto> {
    return this.get<ResourceImagesDto>(
      `api/resources/defaultResourceDetails?resourceType=${resourceType}`
    ).pipe(
      switchMap((data) => {
        const requests = data.photos.map((photo) =>
          this.fetchPhotoImage(photo)
        );

        return forkJoin(requests).pipe(
          map((photos: ResourceImageDto[]) => {
            return { ...data, photos };
          })
        );
      })
    );
  }

  public setResourcePhotosStatus(
    resourceId: string,
    isActive: boolean
  ): Observable<ApiResponse> {
    return this.post(
      `api/resources/${resourceId}/photosStatus?active=${isActive}`,
      {}
    );
  }

  public saveDescription(
    resourceId: string,
    description: string
  ): Observable<ApiResponse> {
    return this.post(
      `api/resources/${resourceId}/description`,
      description,
      'application/json'
    ).pipe(
      map(() => ({ isSuccess: true })),
      catchError(() => {
        return of({ isSuccess: false });
      })
    );
  }

  private fetchPhotoImage(
    photo: ResourceImageDto
  ): Observable<ResourceImageDto> {
    const regex = new RegExp(
      '(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})'
    );
    const imageRequest: Observable<string> = photo.url.match(regex)
      ? this.getImage(photo.url)
      : of(photo.url);

    return imageRequest.pipe(map((image) => ({ ...photo, image })));
  }

  public saveImage(
    resourceId: string,
    model: CropperImageData
  ): Observable<ApiResponse> {
    return this.post(
      `api/resources/${resourceId}/savePhotos`,
      this.extractFormData(model),
      'multipart/form-data'
    ).pipe(
      map(() => ({ isSuccess: true })),
      catchError(() => {
        return of({ isSuccess: false });
      })
    );
  }

  public saveDefaultImage(
    resourceType: ResourceType,
    model: CropperImageData
  ): Observable<ApiResponse> {
    return this.post(
      'api/resources/defaultPhotos',
      this.extractFormData(model, resourceType),
      'multipart/form-data'
    ).pipe(
      map(() => ({ isSuccess: true })),
      catchError(() => {
        return of({ isSuccess: false });
      })
    );
  }
  public saveDefaultDescription(
    resourceType: ResourceType,
    description: string
  ): Observable<ApiResponse> {
    return this.post(
      `api/resources/defaultDescription?resourceType=${resourceType}`,
      description,
      'application/json'
    ).pipe(
      map(() => ({ isSuccess: true })),
      catchError(() => {
        return of({ isSuccess: false });
      })
    );
  }

  private extractFormData(
    model: CropperImageData,
    resourceType: ResourceType | null = null
  ): FormData {
    const formData = new FormData();
    const configuration = this.getCropperConfiguration(model);

    const mobilePhoto = model.croppedImages[ResourceImageType.Mobile];
    const teamsPhoto = model.croppedImages[ResourceImageType.Teams];

    const mimeType = this.getMimeType(model.originalImage);

    formData.append(
      'originalPhoto',
      this.getPhotoFile(
        model.originalImage,
        ResourceImageType.Original,
        mimeType
      )
    );
    formData.append(
      'mobilePhoto',
      this.getPhotoFile(mobilePhoto, ResourceImageType.Mobile, mimeType)
    );
    formData.append(
      'teamsPhoto',
      this.getPhotoFile(teamsPhoto, ResourceImageType.Teams, mimeType)
    );
    formData.append('configuration', JSON.stringify(configuration));
    if (resourceType) {
      formData.append('resourceType', resourceType);
    }

    return formData;
  }

  private getPhotoFile(image: string, type: string, mimeType: string): File {
    const blob = this.getImageBlob(image, mimeType);
    return new File(
      [blob],
      `image-${type}.${this.getImageExtension(mimeType)}`
    );
  }

  private getImageExtension(mimeType: string): string {
    switch (mimeType) {
      case 'image/png':
        return 'png';
      default:
        return 'jpg';
    }
  }

  private getImageBlob(image: string, mimeType: string): Blob {
    const byteString = atob(image.split(',')[1]);

    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeType });
  }

  private getMimeType(image: string): string {
    return image.split(',')[0].split(':')[1].split(';')[0];
  }

  private getCropperConfiguration(
    model: CropperImageData
  ): SaveCropConfiguration {
    return {
      crops: this.getSaveResourceCrops(model),
    };
  }

  private getSaveResourceCrops(model: CropperImageData): SaveResourceCrop[] {
    return AlterResourceImageTypes.map((type) =>
      this.getSaveResourceCrop(type, model)
    );
  }

  private getSaveResourceCrop(
    type: ResourceImageType,
    model: CropperImageData
  ): SaveResourceCrop {
    const coords = model.coordinatesSets[type];
    return {
      type,
      x: coords.left,
      y: coords.top,
      w: coords.width,
      h: coords.height,
    };
  }
}
