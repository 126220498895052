



















import { Component, Vue, Prop } from 'vue-property-decorator';
import { TeamMemberEditModel } from '@/models/team-member-edit-model';

@Component({})
export default class TeamMemberDetailsEditModal extends Vue {
  @Prop(Boolean) public readonly visible!: boolean;
  @Prop() public readonly model?: TeamMemberEditModel;

  public onCancel(): void {
    this.$emit('cancel');
  }

  public onTeamMemberEdited(model: any): void {
    this.$emit('edited', model);
  }
}
