










































import { Component, Vue, Prop } from 'vue-property-decorator';
import { SubscriptionPlan } from '@/models/subscription/subscription-plan';

@Component({})
export default class ChangeSubscriptionPlanConfirmationModal extends Vue {
  @Prop(Boolean) public readonly visible!: boolean;
  @Prop() public readonly targetPlan!: SubscriptionPlan;
  @Prop() public readonly sourcePlan!: SubscriptionPlan;
  // todo: implement cost calculation model to display price change
  
  public get isUpgraded(): boolean {
    return this.targetPlan && this.sourcePlan && this.sourcePlan.tier < this.targetPlan.tier;
  }

  public get features(): string[] {
    if (this.isUpgraded) {
      return this.getFeaturesDiff(this.targetPlan, this.sourcePlan);
    }
    return this.getFeaturesDiff(this.sourcePlan, this.targetPlan);
  }

  public onConfirm(): void {
    this.$emit('confirm');
  }

  public onCancel(): void {
    this.$emit('cancel');
  }

  private getFeaturesDiff(planA: SubscriptionPlan, planB: SubscriptionPlan): string[] {
    const featuresA = planA && planA.features ? planA.features : [];
    const featuresB = planB && planB.features ? planB.features : [];
    return featuresA.filter(feature => featuresB.indexOf(feature) < 0);
  }
}
