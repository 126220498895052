import { SupportsInjection } from 'good-injector-vue';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { OnboardingStatus } from '@/models/onboarding-status';
import { AuthenticationService } from './authentication/authentication.service';
import { LocalStorageCacheService } from './local-storage-cache.service';
import { OrganizationSettingsService } from './organization-settings.service';

@SupportsInjection
export class OnboardingStatusService extends OrganizationSettingsService {
  private static stepsCount = 5;

  public static onboardingStatusKey = 'ONBOARDING_STATUS';

  public constructor(
    authenticationService: AuthenticationService,
    protected localStorageCacheService: LocalStorageCacheService
  ) {
    super(authenticationService);
  }

  public markStepCompleted(
    stepNumber: number
  ): Observable<OnboardingStatus | unknown> {
    return this.getOnboardingStatus().pipe(
      map((status: OnboardingStatus) => {
        if (status.isCompleted) {
          return of();
        } else if (stepNumber === OnboardingStatusService.stepsCount) {
          return this.saveOnboardingStatus(stepNumber, true);
        } else if (stepNumber >= status.stepIndex && stepNumber > 1) {
          return this.saveOnboardingStatus(stepNumber + 1, false);
        }
        return of();
      })
    );
  }

  public getOnboardingStatus(): Observable<OnboardingStatus> {
    const defaultStatus = { stepIndex: 2, isCompleted: false };
    const cachedValue = this.localStorageCacheService.getItem(
      OnboardingStatusService.onboardingStatusKey
    );

    if (cachedValue !== null) {
      return of(cachedValue);
    } else {
      return this.getSettingsByKey(
        OnboardingStatusService.onboardingStatusKey
      ).pipe(
        map((data) => {
          const result = !!data ? data : defaultStatus;
          this.localStorageCacheService.setItem(
            OnboardingStatusService.onboardingStatusKey,
            result
          );
          return result;
        })
      );
    }
  }

  public saveOnboardingStatus(
    stepIndex: number,
    isCompleted: boolean
  ): Observable<OnboardingStatus> {
    const saveStatus = { stepIndex, isCompleted };
    this.localStorageCacheService.setItem(
      OnboardingStatusService.onboardingStatusKey,
      saveStatus
    );
    return this.setSettingsByKey(
      OnboardingStatusService.onboardingStatusKey,
      saveStatus
    );
  }
}
