import { ApiResponse } from '@/models/api-response';
import { SettingsInvoiceEmailAddress } from '@/models/settings/settings-invoice-email-address';
import { SettingsInvoiceNoteRequest } from '@/models/settings/settings-invoice-note-request';
import { SettingsInvoiceNoteResponse } from '@/models/settings/settings-invoice-note-response';
import { SupportsInjection } from 'good-injector-vue';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiClientService } from './api-client.service';
import { AuthenticationService } from './authentication/authentication.service';

@SupportsInjection
export class SettingsService extends ApiClientService {
  public constructor(authenticationService: AuthenticationService) {
    super(authenticationService, `${process.env.VUE_APP_BOOKADO_API_URI}/`);
  }

  public updateNotes(
    notes: SettingsInvoiceNoteRequest
  ): Observable<ApiResponse> {
    return this.post('/settings/invoice/note', notes, 'application/json').pipe(
      map(() => ({ isSuccess: true })),
      catchError(() => {
        return of({ isSuccess: false });
      })
    );
  }

  public getNotes(): Observable<SettingsInvoiceNoteResponse> {
    return this.get<SettingsInvoiceNoteResponse>('settings/invoice/note').pipe(
      catchError(() => of({ text: '' }))
    );
  }

  public updateEmailAddresses(
    emailAddresses: SettingsInvoiceEmailAddress[]
  ): Observable<ApiResponse> {
    return this.post(
      '/settings/invoice/emails',
      emailAddresses,
      'application/json'
    ).pipe(
      map(() => ({ isSuccess: true })),
      catchError(() => {
        return of({ isSuccess: false });
      })
    );
  }

  public getEmailAddresses(): Observable<SettingsInvoiceEmailAddress[]> {
    return this.get<SettingsInvoiceEmailAddress[]>(
      'settings/invoice/emails'
    ).pipe(catchError(() => of([])));
  }
}
